import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => {
    return {
        inputWrapper: {
            position: 'relative',
        },
        inputActions: {
            position: 'absolute',
            top: 0,
            right: theme.spacing(0.5),
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        input: {
            ...{
                width: '100%',
                background: theme.palette.common.white,
                border: 'none',
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(11),
                height: 36,
                lineHeight: 36,
                [theme.breakpoints.up('md')]: {
                    height: theme.spacing(5),
                    lineHeight: theme.spacing(5),
                },

                '&:focus': {
                    border: 'none',
                    outline: 'none',
                },
                borderRadius: theme.shape.borderRadius,
            },
            ...theme.typography.body2,
        },
        loader: {
            marginRight: theme.spacing(9),
            pointerEvents: 'none',
        },
        submitButton: {
            position: 'absolute',
            right: 0,
            top: '50%',
            transform: 'translateY(-50%)',
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(4),
            top: '50%',
            transform: 'translateY(-50%)',
            transition: 'all 0.2s ease-out',

            '& .MuiIconButton-label': {
                background: theme.palette.primary.main,
            },
        },
    };
});

export default useStyles;
