import { Fragment, useEffect } from 'react';
import clsx from 'clsx';
import { Frame } from '@prowise/react-mui';
import useStyles from './DefaultStyle';

import DrawerContent from '../../Organisms/DrawerContent/DrawerContent';
import Toolbar from '../../Organisms/Toolbar/Toolbar';
import Header from '../../Organisms/Header/Header';
import CookieWall from '../../Organisms/CookieWall/CookieWall';

import { useSelector } from 'react-redux';
import { selectGeneral } from '../../../redux/dataSlice';

const MainFrame = ({ children, rightDrawerIsOpen }) => {
    const classes = useStyles();
    const data = useSelector(selectGeneral);

    // scroll to top on mount
    useEffect(() => window.scrollTo(0, 0), []);

    return (
        <Fragment>
            <Header />
            <Frame drawerContent={<DrawerContent />} toolbar={<Toolbar />} appbar drawerFloatingButton>
                <div className={clsx(classes.wrapper, { [classes.wrapperShifted]: rightDrawerIsOpen })}>{children}</div>
            </Frame>
            {data.cookieConfig && Object.keys(data.cookieConfig).length > 0 && (
                <CookieWall config={data.cookieConfig} />
            )}
        </Fragment>
    );
};
export default MainFrame;
