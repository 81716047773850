import Box from '@mui/material/Box';
import Title from '../../Atoms/Title/Title';
import useStyles from './FilterCategoryCardStyle';

import Img from '../../Atoms/Img/Img';

const FilterCategoryCard = ({ onClick = () => {}, image = '', label = '' }) => {
    const classes = useStyles();
    return (
        <Box component="button" className={classes.card} onClick={onClick}>
            <Box className={classes.imgWrapper}>
                <Img src={image} alt={label} aspectRatio={59.3103448276} lazyload={true} className={classes.img} />
            </Box>
            <Title title={label} size="h6" className={classes.title} />
        </Box>
    );
};
export default FilterCategoryCard;
