import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => {
    return {
        card: {
            width: '100%',
            padding: theme.spacing(2),
            borderRadius: '12px',
            background: theme.palette.common.white,
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            boxShadow: theme.shadows[2],
            [theme.breakpoints.up('sm')]: {
                padding: theme.spacing(4),
            },
            [theme.breakpoints.up('md')]: {
                alignItems: 'flex-start',
                flexDirection: 'row',
            },
        },
        imgWrapper: {
            position: 'relative',
            width: '100%',
            borderRadius: theme.shape.borderRadius * 2,
            overflow: 'hidden',
            marginBottom: theme.spacing(2),
            [theme.breakpoints.up('md')]: {
                maxWidth: '200px',
                marginBottom: '0',
                marginRight: theme.spacing(2),
            },
        },
        content: { width: '100%' },
        variants: {
            display: 'flex',
            flexDirection: 'column',
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(-1),
        },
        variantItem: {
            borderRadius: '5px',
            padding: theme.spacing(1),
            border: '1px solid transparent',
            borderColor: theme.palette.gray.light,
            display: 'flex',
            flexDirection: 'column-reverse',
            marginBottom: theme.spacing(1),
            [theme.breakpoints.up('lg')]: {
                alignItems: 'flex-start',
                flexDirection: 'row',
                justifyContent: 'space-between',
            },
        },
        price: {
            fontWeight: 'bold',
            color: theme.palette.primary.main,
            margin: theme.spacing(2),
            marginLeft: '0',
            marginRight: '0',
            display: 'flex',

            [theme.breakpoints.up('lg')]: {
                margin: '0',
                marginTop: '8px',
            },
        },
        description: {
            [theme.breakpoints.up('lg')]: {
                width: '30%',
                marginTop: '8px',
            },
        },
    };
});

export default useStyles;
