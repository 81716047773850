import { DrawerMenuItem, DrawerMenuAppItem } from '@prowise/react-mui';
import { NavLink } from 'react-router-dom';
import { Launch } from '@mui/icons-material';
import { useSelector } from 'react-redux';

import routes from '../../../routes/routes';
import { selectGeneral } from '../../../redux/dataSlice';

import useStyles from './DrawerContentStyle';

const DrawerContent = () => {
    const classes = useStyles();
    const data = useSelector(selectGeneral);
    const { menuLabels, extraPrimaryLinks } = data;
    return (
        <div className={`${classes.root} drawer-content`}>
            {routes
                .filter((item) => !item.notInMenu) // remove items from menu
                .map((route, i) => (
                    <DrawerMenuItem
                        key={`nav-route-${i}`}
                        id={route.id}
                        icon={route.icon}
                        to={route.path}
                        title={menuLabels[route.label] || route.label}
                        navComponent={NavLink}
                    />
                ))}
            {extraPrimaryLinks.map((route, i) => (
                <DrawerMenuAppItem
                    key={`nav-extra-${i}`}
                    id={`nav-extra-${i}`}
                    icon={<Launch />}
                    href={route.href}
                    name={route.label}
                />
            ))}
        </div>
    );
};

export default DrawerContent;
