import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@mui/material';
import { Body } from '@prowise/react-mui';

import useStyles from './ShopPopUpStyle';

import Title from '../../Atoms/Title/Title';
import Button from '../../Atoms/Button/Button';
import Text from '../../Atoms/Text/Text';
import { formatPrice } from '../../../utilities/utilities';
import Img from '../../Atoms/Img/Img';

const ShopPopUp = ({ id, img, variants, variantText, title, linkLabel, handleAddToCart, handleModalClose }) => {
    const classes = useStyles();
    return (
        <div className={classes.card}>
            <div className={classes.imgWrapper}>
                <Img {...img} />
            </div>
            <div className={classes.content}>
                <Title title={title} size="h4" />
                <Text text={variantText} />
                <div className={classes.variants}>
                    {variants.map((item, i) => (
                        <div className={classes.variantItem} key={`variant-${item.id}${i}`}>
                            <Body className={classes.description}>{item.description !== '' && item.description}</Body>
                            <span className={classes.price}>{formatPrice(item.price)}</span>
                            <Button
                                label={linkLabel}
                                startIcon={<Icon className={'material-icons-outlined'}>add_shopping_cart</Icon>}
                                onClick={() => {
                                    handleAddToCart(id, item.id, title, item.description, img);
                                    handleModalClose();
                                }}
                                component="button"
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

ShopPopUp.propTypes = {
    id: PropTypes.string.isRequired,
    image: PropTypes.object,
    handleAddToCart: PropTypes.func,
    handleModalClose: PropTypes.func,
};

ShopPopUp.defaultProps = {
    image: {
        src: '',
        alt: '',
    },
    handleAddToCart: () => {},
    handleModalClose: () => {},
};

export default ShopPopUp;
