import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => {
    return {
        checkbox: {
            cursor: 'pointer',
            width: '100%',
            display: 'flex',
            position: 'relative',

            '& .MuiCheckbox-root': {
                color: theme.palette.secondary.main,
            },
            '& .MuiCheckbox-indeterminate': {
                color: theme.palette.primary.main,
            },
        },
        input: {
            position: 'absolute',
            top: '-13px',
            left: '-13px',
            zIndex: '2',
        },
        inputbackground: {
            position: 'absolute',
            width: '100%',
            height: '100%',
            maxWidth: '16px',
            maxHeight: '16px',
            background: theme.palette.common.white,
            zIndex: '0',
        },
        label: {
            opacity: '0.6',
            marginTop: '-2px',
            marginLeft: theme.spacing(4),
        },
        wrapper: {
            display: 'flex',
            flexDirection: 'row',
        },
    };
});

export default useStyles;
