import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Body } from '@prowise/react-mui';

import useStyles from './NewsCardStyle';
import Title from '../../Atoms/Title/Title';
import IconWithTag from '../../Molecules/IconWithTag/IconWithTag';
import Img from '../../Atoms/Img/Img';

const NewsCard = ({ title, introText, img, date, id, simpleId }) => {
    const classes = useStyles();

    return (
        <Link to={`/news/${simpleId}/`} className={classes.link}>
            <div className={classes.card}>
                <div className={classes.newsImgWrapper}>
                    <Img {...img} className={classes.img} />
                </div>
                <div className={classes.titleWrapper}>
                    <Title title={title} size="h4" />
                </div>
                <div className={classes.info}>
                    <IconWithTag tag={date} icon="schedule" variant="light" />
                </div>
                <div className={classes.content}>
                    <Body>{introText}</Body>
                </div>
            </div>
        </Link>
    );
};

NewsCard.propTypes = {
    date: PropTypes.string,
    title: PropTypes.string,
    text: PropTypes.string,
    img: PropTypes.object,
    id: PropTypes.string,
    simpleId: PropTypes.string,
};

NewsCard.defaultProps = {
    date: '',
    img: {},
    title: '',
    id: '0',
    simpleId: '',
    text: '',
};

export default NewsCard;
