import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import Box from '@mui/material/Box';
import Icon from '@mui/material/Icon';
import { Body } from '@prowise/react-mui';

import useStyles from './EventsOverviewCardStyle';
import Title from '../../Atoms/Title/Title';
import Button from '../../Atoms/Button/Button';
import Img from '../../Atoms/Img/Img';
import IconWithTag from '../../Molecules/IconWithTag/IconWithTag';

const EventsOverviewCard = ({ simpleId, title, img, date, introText, time, tags, logo, buttonLabel }) => {
    const classes = useStyles();
    const history = useHistory();

    const handleClick = () => {
        history.push(`/events/${simpleId}`);
    };

    return (
        <div className={classes.link} onClick={handleClick}>
            <div className={classes.card}>
                <div className={classes.content}>
                    <div className={classes.contentItem}>{title !== '' && <Title title={title} size="h4" />}</div>
                    <div className={classes.contentItem}>
                        <IconWithTag icon="calendar_today" tag={date} variant="light" />
                        {time !== '' && <IconWithTag icon="schedule" tag={time} variant="light" />}
                        <IconWithTag icon="folder" tags={tags} variant="light" />
                    </div>
                    <div className={classes.contentItem}>
                        <Body>{introText}</Body>
                    </div>
                    <Box sx={{ display: { lg: 'none' } }}>
                        <Box className={`${classes.button} ${classes.contentItem}`}>
                            <Button
                                label={buttonLabel}
                                color="primary"
                                endIcon={<Icon>east</Icon>}
                                to={`/events/${simpleId}/`}
                            />
                        </Box>
                    </Box>
                </div>
                <div className={classes.sideWrapper}>
                    <div className={classes.imgWrapper}>
                        <Img
                            src={img.src}
                            alt={img.alt !== '' ? img.alt : title}
                            aspectRatio={img.aspectRatio}
                            className={classes.img}
                        />
                    </div>
                    <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
                        <Box className={`${classes.button}`}>
                            <Button
                                label={buttonLabel}
                                color="primary"
                                endIcon={<Icon>east</Icon>}
                                to={`/events/${simpleId}/`}
                            />
                        </Box>
                    </Box>
                </div>
                <div className={classes.logo}>
                    <Img {...logo} alt={title} />
                </div>
            </div>
        </div>
    );
};

EventsOverviewCard.propTypes = {
    simpleId: PropTypes.string,
    title: PropTypes.string,
    img: PropTypes.object,
    alt: PropTypes.string,
    date: PropTypes.string,
    text: PropTypes.string,
    buttonLabel: PropTypes.string,
};

EventsOverviewCard.defaultProps = {
    simpleId: '',
    title: '',
    img: {},
    alt: '',
    date: '',
    text: '',
    buttonLabel: '',
};

export default EventsOverviewCard;
