import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => {
    return {
        ActionButton: {
            position: 'fixed',
            zIndex: '900',
            right: '16px',
            bottom: '16px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            [theme.breakpoints.up('sm')]: {
                right: '24px',
                bottom: '24px',
            },
            [theme.breakpoints.up('lg')]: {
                right: '40px',
                bottom: '40px',
            },
        },
        button: {
            position: 'relative',
            maxWidth: 'max-content',
            fontSize: '24px',
        },
        counter: {
            position: 'absolute',
            background: theme.palette.primary.main,
            color: theme.palette.common.white,
            width: '20px',
            height: '20px',
            borderRadius: '100%',
            top: '0',
            right: '0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: theme.typography.fontSizeSmaller,
            zIndex: 1051,
        },
    };
});

export default useStyles;
