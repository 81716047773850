import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => {
    return {
        filterButton: {
            [theme.breakpoints.down('md')]: {
                display: 'none',
            },
        },
        title: {
            marginBottom: theme.spacing(1),
            [theme.breakpoints.up('lg')]: {
                marginBottom: theme.spacing(2),
            },
            '& h2': {
                marginBottom: '0',
            },
        },
        filterstitle: {
            marginBottom: theme.spacing(3),
            '& h4': {
                marginBottom: '-3px',
            },
            [theme.breakpoints.up('lg')]: {
                height: '40px',
                display: 'flex',
                alignItems: 'flex-end',
            },
        },
        options: {
            marginBottom: theme.spacing(0),

            [theme.breakpoints.up('sm')]: {
                marginBottom: theme.spacing(2.5),
            },
            [theme.breakpoints.up('lg')]: {
                marginBottom: theme.spacing(2),
            },
        },
        optionsleft: {
            display: 'none',

            [theme.breakpoints.up('sm')]: {
                display: 'flex',
            },
        },
        optionsright: {
            display: 'flex',
            justifyContent: 'space-between',
            [theme.breakpoints.up('lg')]: {
                justifyContent: 'flex-end',
            },
        },
        searchbutton: {
            display: 'flex',
            [theme.breakpoints.up('sm')]: {
                display: 'none',
            },
        },
    };
});

export default useStyles;
