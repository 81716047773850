import { Fragment, useEffect } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { useDrawerContext } from '@prowise/react-mui';
import useMediaQuery from '@mui/material/useMediaQuery';
import Alert from '@mui/material/Alert';
import { useMatomo } from '@datapunt/matomo-tracker-react';

import { useSelector, useDispatch } from 'react-redux';
import { selectFilteredNews, selectActiveNewsFilters, toggleFilter } from '../../redux/dataSlice';

import LayoutDefault from '../../components/Layouts/Default/Default';
import useStyles from './NewsStyle';
import Title from '../../components/Atoms/Title/Title';
import Filters from '../../components/Molecules/Filters/Filters';
import NewsOverviewCard from '../../components/Molecules/NewsOverviewCard/NewsOverviewCard';

const News = () => {
    const dispatch = useDispatch();
    const data = useSelector(selectFilteredNews);

    document.title = data.title;
    const { trackPageView } = useMatomo();
    useEffect(() => {
        trackPageView();
        // eslint-disable-next-line
    }, []);

    const activeFilters = useSelector(selectActiveNewsFilters);
    const drawerState = useDrawerContext();
    const classes = useStyles();
    const matchesWeirdBreakpoint = useMediaQuery('(max-width: 830px)');
    const matchesSmBreakpoint = useMediaQuery((theme) => theme.breakpoints.up('sm'));
    const horizontalFilters = useMediaQuery((theme) => theme.breakpoints.between('sm', 'lg'));
    const betweenMdLg = useMediaQuery((theme) => theme.breakpoints.between('md', 'xl'));

    const getColClasses = () => {
        const defaultClasses = {
            xs: 12,
            sm: 6,
            lg: 12,
        };
        if (drawerState.opened && matchesWeirdBreakpoint) {
            return {
                xs: 12,
                sm: 12,
                md: 12,
            };
        }
        if (!drawerState.opened && betweenMdLg)
            return {
                md: 4,
                lg: 12,
            };
        return defaultClasses;
    };

    const updateFilter = (data) => {
        dispatch(
            toggleFilter({
                ...data,
                ...{
                    type: 'news',
                },
            }),
        );
    };

    return (
        <LayoutDefault>
            <Container>
                <Grid container direction="column" className={classes.root}>
                    <Grid container item xs>
                        <div className={classes.title}>
                            <Title title={data.title} size="h2" />
                        </div>
                    </Grid>
                    <Grid container item xs>
                        <Grid container item spacing={4} direction="row">
                            <Grid xs={12} lg={3} item container direction="column">
                                <Title title={data.filterLabel} size="h4" />
                                <div className={classes.filtersWrapper}>
                                    <Filters
                                        filterData={data.filters}
                                        activeFilters={activeFilters}
                                        updateFilter={updateFilter}
                                        horizontal={horizontalFilters}
                                    />
                                </div>
                            </Grid>
                            <Grid xs={12} lg={9} container item>
                                <Grid container item spacing={(!matchesSmBreakpoint && 2) || 3} direction="row">
                                    {!data.items.length ? (
                                        <Grid xs={12} item container direction="column">
                                            <Alert severity="info">{data.noFilterResults}</Alert>
                                        </Grid>
                                    ) : (
                                        <Fragment>
                                            {data.items.map((item, i) => (
                                                <Grid key={`news-item-${item.id}-${i}`} item {...getColClasses(i)}>
                                                    <NewsOverviewCard {...item} buttonLabel={data.buttonLabel} />
                                                </Grid>
                                            ))}
                                        </Fragment>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </LayoutDefault>
    );
};

export default News;
