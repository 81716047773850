import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => {
    return {
        card: {
            width: '100%',
            padding: theme.spacing(2),
            borderRadius: '12px',
            display: 'flex',
            flexDirection: 'row',
            background: theme.palette.common.white,
            position: 'relative',

            [theme.breakpoints.up('lg')]: {
                padding: theme.spacing(4),
            },
        },
        imgWrapper: {
            width: '100%',
            maxWidth: '50px',
            position: 'relative',
            borderRadius: theme.shape.borderRadius * 2,
            overflow: 'hidden',
            [theme.breakpoints.up('sm')]: {
                maxWidth: '130px',
            },
        },
        content: {
            marginLeft: theme.spacing(3),
            width: '100%',
            display: 'flex',
            flexDirection: 'column',

            [theme.breakpoints.up('sm')]: {
                marginLeft: theme.spacing(5),
            },
            '& h6': {
                marginBottom: theme.spacing(1),
            },
        },
        inputAndPrice: {
            display: 'flex',
            flexDirection: 'column-reverse',
            marginTop: theme.spacing(2),

            [theme.breakpoints.up('sm')]: {
                justifyContent: 'space-between',
                justifySelf: 'flex-end',
                marginTop: 'auto',
                flexDirection: 'row',
                alignItems: 'flex-end',
            },
        },
        input: {
            width: '80%',
            marginTop: theme.spacing(2),
            [theme.breakpoints.up('sm')]: {
                width: '50%',
                marginTop: '0',
            },
            '& .MuiSelect-outlined': {
                paddingTop: theme.spacing(1.5),
                paddingBottom: theme.spacing(1.5),
            },
            '& .MuiSelect-icon': {
                color: theme.palette.primary.main,
            },
            '&__option': {
                padding: theme.spacing(1),
            },
        },
        price: { color: theme.palette.primary.main, fontWeight: 'bold' },
        delete: {
            position: 'absolute',
            right: '10px',
            top: '10px',
            width: '24px',
            height: '24px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '100%',
            color: theme.palette.primary.main,
            cursor: 'pointer',
            transition: 'all 0.2s ease-out',
            fontSize: '18px',
            border: 'none',
            background: 'transparent',

            '&:hover': {
                color: theme.palette.primary.main,
                background: theme.palette.tertiary.colors[200],
            },
        },
    };
});

export default useStyles;
