import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import { Icon } from '@mui/material';

import useStyles from './ButtonRoundStyle';

const ButtonRound = ({ icon, onClick, disabled, className, variant = 'secondary' }) => {
    const classes = useStyles();
    return (
        <IconButton className={`${classes.root} ${className}`} onClick={onClick} disabled={disabled} size="large">
            <span className={`${classes.span} ${classes[variant]}`}>
                <Icon className={classes.icon}>{icon}</Icon>
            </span>
        </IconButton>
    );
};

ButtonRound.propTypes = {
    onClick: PropTypes.func,
    icon: PropTypes.string,
    disabled: PropTypes.bool,
    className: PropTypes.string,
};

ButtonRound.defaultProps = {
    onClick: () => {},
    icon: '',
    disabled: false,
    className: '',
};

export default ButtonRound;
