import { Fragment } from 'react';
import { AppBar, HelpMenu, HelpMenuItem, Intro, useIntroductionSystem } from '@prowise/react-mui';
import { useAuth } from '@prowise/react-auth';
import { useHistory } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { selectGeneral } from '../../../redux/dataSlice';

import Logo from './logo.svg';
import Jim from './jim.svg';
import Liesbeth from './liesbeth.svg';

const Header = () => {
    const auth = useAuth();
    const history = useHistory();
    const data = useSelector(selectGeneral);
    const { extraSecondaryLinks, translations, introductionsteps } = data;
    const introductionSystem = useIntroductionSystem();

    const onLogoClick = () => {
        history.push('/');
    };
    let isGerman = false;
    try {
        isGerman = auth.profile.locale.split('-')[0] === 'de';
    } catch (error) {}

    const steps = [
        {
            ...introductionsteps[0],
            ...{
                placement: 'center',
                target: 'body',
                name: isGerman ? 'Liesbeth Ahrens' : 'Chris Wise',
                role: 'Sales Manager',
                image: isGerman ? Liesbeth : Jim,
            },
        },
        {
            ...introductionsteps[1],
            ...{
                placement: 'bottom',
                target: '.drawer-content a[href="/news/"]',
            },
        },
        {
            ...introductionsteps[2],
            ...{
                placement: 'bottom',
                target: '.drawer-content a[href="/downloads/"]',
            },
        },
        {
            ...introductionsteps[3],
            ...{
                placement: 'bottom',
                target: '.drawer-content a[href="/shop/"]',
            },
        },
        // {
        //     ...introductionsteps[4],
        //     ...{
        //         placement: 'center',
        //         target: 'body',
        //     },
        // },
    ];

    const startIntroduction = () => {
        introductionSystem.initiateGeneralIntro();
    };

    return (
        <Fragment>
            <AppBar
                appbar
                toolbar
                auth={auth}
                applicationLogoURL={Logo}
                translations={translations}
                onLogoClick={onLogoClick}
            >
                <HelpMenu name="Help">
                    <HelpMenuItem content="Help" onClick={startIntroduction} divider={extraSecondaryLinks.length > 0} />
                    {extraSecondaryLinks.map((item, i) => (
                        <HelpMenuItem key={`secondary-link-${i}`} content={item.label} href={item.href} button />
                    ))}
                </HelpMenu>
            </AppBar>
            <Intro
                isRunning={introductionSystem.generalIntroRunning}
                callback={introductionSystem.generalIntroCallback}
                steps={steps.map((s) => ({ ...s, ...{ disableBeacon: true, exitTranslation: 'Ok!' } }))}
            />
        </Fragment>
    );
};
export default Header;
