import PropTypes from 'prop-types';
import { Header } from '@prowise/react-mui';

const Title = ({ title, size, color, className, inverted }) => {
    return (
        <Header variant={size} color={color} size={size} className={className} inverted={inverted}>
            {title}
        </Header>
    );
};

Title.propTypes = {
    title: PropTypes.string,
    size: PropTypes.string,
    color: PropTypes.oneOf(['primary', 'secondary', 'white']),
    inverted: PropTypes.bool,
    className: PropTypes.string,
};

Title.defaultProps = {
    title: '',
    size: 'h1',
    color: 'primary',
    inverted: false,
    className: '',
};

export default Title;
