import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { NavLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Toolbar, BreadcrumbsContainer, Breadcrumbs, Breadcrumb } from '@prowise/react-mui';

import routes from '../../../routes/routes';
import { truncate } from '../../../utilities/utilities';
import { selectGeneral, selectNewsItemBySimpleId, selectEventItemBySimpleId } from '../../../redux/dataSlice';
import useAppHistory from '../../../hooks/appHistory';

const _MAX_LABEL_LENGTH = 30;

const truncatedLabel = (label) => truncate(label, _MAX_LABEL_LENGTH);

const DynamicBreadCrumb = ({ match, route }) => {
    // general menu labels
    const data = useSelector(selectGeneral);
    const { menuLabels } = data;

    // newsItem
    const selectNewsItemBySimpleIdFilter = useSelector(selectNewsItemBySimpleId);
    const newsItem = selectNewsItemBySimpleIdFilter(match.params.simpleId);

    // event
    const selectEventItemBySimpleIdFilter = useSelector(selectEventItemBySimpleId);
    const eventItem = selectEventItemBySimpleIdFilter(match.params.simpleId);

    if (match.path === '/news/:simpleId/') return <span>{truncatedLabel(newsItem.title || '')}</span>;
    if (match.path === '/events/:simpleId/') return <span>{truncatedLabel(eventItem.title || '')}</span>;
    return <span>{truncatedLabel(menuLabels[route.label] || route.label)}</span>;
};

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    background: `${theme.palette.gradients.toolbar} !important`,
}));

const ToolbarCustom = () => {
    const history = useHistory();
    const applicationHistory = useAppHistory();

    const goBack = () => {
        applicationHistory.saveForNext();
        history.goBack();
    };
    const goForward = () => {
        if (applicationHistory.nextItems.length > 0) {
            history.goForward();
            applicationHistory.deleteLastNext();
        }
    };

    const breadcrumbs = useBreadcrumbs(
        routes
            // each route requires a path.. 404 paege does not have a path since it needs to catch all unknown routes
            .filter((r) => r.path)
            .map((route) => ({
                ...route,
                ...{
                    breadcrumb: (props) => <DynamicBreadCrumb {...props} route={route} />,
                },
            })),
    );

    return (
        <StyledToolbar>
            <BreadcrumbsContainer
                forwardButtonDisabled={applicationHistory.nextItems.length <= 0}
                backButtonDisabled={applicationHistory.items.length <= 0}
                onBackButtonClick={goBack}
                onForwardButtonClick={goForward}
            >
                <Breadcrumbs>
                    {breadcrumbs.map((crumb, i) => (
                        <Breadcrumb
                            key={`breadcrumbs-item-${i}-${crumb.match.path}`}
                            navComponent={NavLink}
                            to={crumb.match.path}
                            last={breadcrumbs.length === i + 1}
                        >
                            {crumb.breadcrumb}
                        </Breadcrumb>
                    ))}
                </Breadcrumbs>
            </BreadcrumbsContainer>
        </StyledToolbar>
    );
};

export default ToolbarCustom;
