import Drawer from '@mui/material/Drawer';
import PropTypes from 'prop-types';
import { Icon } from '@mui/material';
import Box from '@mui/material/Box';
import Title from '../../Atoms/Title/Title';
import useStyles from './CustomDrawerStyle';

const CustomDrawer = ({ children, toggleDrawer, anchor, open, title, mobileFooter }) => {
    const classes = useStyles();

    return (
        <Drawer
            variant="persistent"
            anchor={anchor}
            open={open}
            classes={{
                root: classes.drawerRoot,
            }}
        >
            <div className={classes.innerWrapper}>
                <div className={classes.header}>
                    <Title title={title} size="h4" color="secondary" className={classes.title} />
                    <Box
                        className={classes.closeButton}
                        onClick={toggleDrawer}
                        sx={{ display: { xs: 'none', sm: 'flex' } }}
                    >
                        <Icon className={classes.toggleIcon} style={{ fontWeight: 'bold' }}>
                            arrow_forward_ios
                        </Icon>
                    </Box>
                    <Box
                        className={classes.closeButton}
                        onClick={toggleDrawer}
                        sx={{ display: { xs: 'flex', sm: 'none' } }}
                    >
                        <Icon className={classes.toggleIcon}>close</Icon>
                    </Box>
                </div>
                <div className={classes.content}>{children}</div>
                {mobileFooter && <div className={classes.footer}>{mobileFooter}</div>}
            </div>
        </Drawer>
    );
};

CustomDrawer.propTypes = {
    anchor: PropTypes.string,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    toggleDrawer: PropTypes.func,
    title: PropTypes.string,
    mobileFooter: PropTypes.node,
};
CustomDrawer.defaultProps = {
    anchor: 'right',
    open: false,
    onClose: () => {},
    toggleDrawer: () => {},
    title: '',
    mobileFooter: false,
};

export default CustomDrawer;
