import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => {
    return {
        link: {
            textDecoration: 'none',
            color: 'unset',
            height: '100%',
        },
        card: {
            width: '100%',
            height: '100%',
            borderRadius: '12px',
            padding: theme.spacing(2),
            background: theme.palette.common.white,
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'column-reverse',
            cursor: 'pointer',
            [theme.breakpoints.up('lg')]: {
                height: 'auto',
                flexDirection: 'row',
                padding: theme.spacing(3),
            },
            '&:hover': {
                '& $img': {
                    transform: 'scale(1.05)',
                },
            },
        },
        content: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            flex: 1,
            [theme.breakpoints.up('lg')]: {
                marginRight: theme.spacing(2),
                height: '100%',
            },
        },
        contentItem: {
            marginBottom: theme.spacing(2),
            [theme.breakpoints.up('lg')]: {
                marginBottom: theme.spacing(3),
            },
            '&:last-child': {
                marginBottom: '0',
            },
        },
        title: { marginBottom: '0' },
        button: {
            display: 'flex',
            marginTop: 'auto',
            justifyContent: 'flex-end',
            position: 'relative',
            '& .MuiTouchRipple-root': {
                color: 'rgba(255,255,255,0.87)',
            },
            [theme.breakpoints.up('lg')]: {
                justifyContent: 'flex-start',
            },
        },
        imgWrapper: {
            position: 'relative',
            borderRadius: theme.shape.borderRadius * 2,
            overflow: 'hidden',
            marginBottom: theme.spacing(2),
            width: '100%',
            [theme.breakpoints.up('lg')]: {
                marginLeft: theme.spacing(2),
                marginBottom: '0',
                maxWidth: '368px',
                flex: 1,
            },
        },
        img: {
            transition: 'transform 0.2s ease-out',
        },
    };
});

export default useStyles;
