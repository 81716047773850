import { Icon } from '@mui/material';
import { Body } from '@prowise/react-mui';

import Button from '../../Atoms/Button/Button';
import Img from '../../Atoms/Img/Img';
import Title from '../../Atoms/Title/Title';

import useStyles from './ShopPopOverStyle';

const ShopPopOver = (props) => {
    const classes = useStyles();

    if (Object.keys(props).length <= 0) return null;
    return (
        <div className={classes.card}>
            <Title title={props.title} color="primary" className={classes.title} />
            <div className={classes.wrapper}>
                <div className={classes.imgWrapper}>
                    <Img {...props.img} />
                </div>
                <div className={classes.content}>
                    <Title title={props.variantTitle} color="primary" size="h6" />
                    <Body>{props.description}</Body>
                </div>
            </div>
            <Button to={props.to} label={props.linkLabel} color="secondary" endIcon={<Icon>east</Icon>} />
        </div>
    );
};

export default ShopPopOver;
