import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => {
    return {
        Button: {
            '& .MuiButton-endIcon': {
                fontSize: '18px !important',
            },
            '& .MuiButton-startIcon': {
                fontSize: '18px !important',
            },
            '&.MuiButton-containedSecondary': {
                background: theme.palette.secondary.colors[900],
                '&:hover': {
                    background: `${theme.palette.secondary.main} !important`,
                },
            },
        },
    };
});

export default useStyles;
