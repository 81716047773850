import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import manager from './manager';
import './assets/fonts/material-icons/material-icons.js';
import Matomo from './utilities/matomo';
// import reportWebVitals from './reportWebVitals';

manager.init();

ReactDOM.render(
    <React.StrictMode>
        <Matomo>
            <App />
        </Matomo>
    </React.StrictMode>,
    document.getElementById('root'),
);

// ReactDOM.render(<App />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
