import { useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';
import { DrawerContextProvider, ThemeProvider, useDrawerContext } from '@prowise/react-mui';
import { AuthProvider } from '@prowise/react-auth';
import { Provider } from 'react-redux';
import { BrowserRouter, Switch, Route, useLocation } from 'react-router-dom';
import { matchPath } from 'react-router';

import store from './redux/store';
import themeOverrides from './theme/themeOverrides';
import routes, { authRoutes } from './routes/routes';
import ProtectedRoute from './routes/ProtectedRoute';
import { AppHistoryProvider } from './hooks/appHistory';

import { globalStyles } from '../src/theme/themeOverrides';

// import prowise styles (fonts)
import '@prowise/react-mui/dist/style.css';

const StoreProvider = ({ children }) => <Provider store={store}>{children}</Provider>;

// this listens to location changes and updates the current drawer item state correctly
const ActivePageStateUpdater = () => {
    const drawerState = useDrawerContext();
    let location = useLocation();
    useEffect(() => {
        const currentRoute = routes.find((route) => matchPath(location.pathname, route));
        if (currentRoute) {
            let id = currentRoute.id;
            // looks like current route has a parent.
            if (currentRoute.parentId) {
                id = routes.filter((route) => route.id === currentRoute.parentId)[0].id;
            }
            drawerState.setSelectedRoute(id);
        }
        // eslint-disable-next-line
    }, [location]);
    return null;
};

const AppRouter = () => (
    <BrowserRouter>
        <AppHistoryProvider>
            <ActivePageStateUpdater />
            <Switch>
                {authRoutes.map((route, i) => (
                    <Route key={`auth-route-${i}`} {...route} />
                ))}
                {routes.map((route, i) => (
                    <ProtectedRoute key={`app-route-${i}`} {...route} />
                ))}
            </Switch>
        </AppHistoryProvider>
    </BrowserRouter>
);

const AppProvider = ({ children }) => (
    <StoreProvider>
        <ThemeProvider override={themeOverrides}>
            <CssBaseline />
            <GlobalStyles styles={globalStyles} />
            <DrawerContextProvider preserve>{children}</DrawerContextProvider>
        </ThemeProvider>
    </StoreProvider>
);

const App = () => (
    <AuthProvider signinSilent={true}>
        <AppProvider>
            <AppRouter />
        </AppProvider>
    </AuthProvider>
);

export default App;
