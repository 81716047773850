import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => {
    return {
        card: {
            width: '100%',
            background: theme.palette.common.white,
            padding: theme.spacing(2),
            borderRadius: '12px',
            display: 'flex',
            flexDirection: 'column-reverse',
            justifyContent: 'flex-end',
            position: 'relative',
            height: '100%',
            cursor: 'pointer',
            [theme.breakpoints.up('lg')]: {
                flexDirection: 'row',
                padding: theme.spacing(4),
            },

            '&:hover': {
                '& $img': {
                    transform: 'scale(1.05)',
                },
            },
        },
        link: {
            textDecoration: 'none',
            color: 'unset',
            height: '100%',
        },
        content: {
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            [theme.breakpoints.up('lg')]: {
                width: '50%',
                marginRight: theme.spacing(1.5),
                marginLeft: '100px',
            },
        },
        contentItem: {
            marginBottom: theme.spacing(2),
            [theme.breakpoints.up('lg')]: {
                marginBottom: theme.spacing(3),
            },
            '&:last-child': {
                marginBottom: '0',
            },
        },
        logo: {
            width: '124px',
            height: '124px',
            background: theme.palette.common.white,
            position: 'absolute',
            left: '-50px',
            top: '50%',
            transform: 'translateY(-50%)',
            borderRadius: '100%',
            boxShadow: theme.shadows[1],
            display: 'none',
            overflow: 'hidden',
            [theme.breakpoints.up('lg')]: {
                display: 'flex',
            },
        },
        button: {
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: 'auto',
            position: 'relative',

            '& .MuiTouchRipple-root': {
                color: 'rgba(255,255,255,0.87)',
            },
        },
        sideWrapper: {
            width: '100%',
            marginBottom: theme.spacing(3),
            display: 'flex',
            flexDirection: 'column',

            [theme.breakpoints.up('lg')]: {
                width: '50%',
                marginLeft: theme.spacing(1.5),
                marginBottom: '0',
                maxWidth: 368,
            },
        },
        imgWrapper: {
            borderRadius: theme.spacing(1),
            overflow: 'hidden',
            [theme.breakpoints.up('lg')]: {
                marginBottom: theme.spacing(3),
            },
        },
        img: {
            transition: 'transform 0.2s ease-out',
        },
    };
});

export default useStyles;
