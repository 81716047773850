import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => {
    return {
        ActionBlock: {
            width: '100%',
            padding: theme.spacing(4),
            borderRadius: '12px',
            display: 'flex',
            flexDirection: 'column',
            background: theme.palette.primary.main,
        },
        content: {
            '& h4': {
                marginBottom: theme.spacing(2),
            },
        },
        button: { marginTop: theme.spacing(2) },
    };
});

export default useStyles;
