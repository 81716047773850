import React, { useEffect, useState, memo } from 'react';
import Cookies from 'js-cookie';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import useHash from '../../../hooks/useHash';
import { IconCheckBox, IconButtonArrow, IconCircleButtonCross } from './Icons';

import parseHTML from '../../../utilities/htmlParser';

import useStyles from './CookieWallStyle';

const componentClass = 'c_cookiewall';

const Rule = (props) => {
    const { checked, cookie, defaultDisabled, label, text, onChange } = props;
    const handleOnChange = (e) => {
        e.persist();
        onChange(e, cookie);
    };
    const id = `cookie_rule_${cookie}`;
    return (
        <div className={`${componentClass}__modal-rule`}>
            <fieldset className={`c_checkbox c_checkbox--no-margin ${componentClass}__modal-checkbox`}>
                <label htmlFor={id} className="c_checkbox__label c_checkbox__label--checkbox">
                    <input
                        type="checkbox"
                        className="c_checkbox__check-custom"
                        name={id}
                        id={id}
                        value=""
                        checked={checked}
                        disabled={defaultDisabled}
                        onChange={handleOnChange}
                    />
                    <span className="c_checkbox__check-toggle">
                        <IconCheckBox color="white" />
                    </span>
                    <span className="c_checkbox__check-inner-label">{label}</span>
                </label>
            </fieldset>
            <div className={`${componentClass}__modal-info-text`}>{parseHTML(text)}</div>
        </div>
    );
};

const Button = ({ type, label, className, onClick = () => {} }) => {
    const handleClick = (e) => {
        e.persist();
        onClick(e, type);
    };
    return (
        <button
            className={`${componentClass}__button ${componentClass}__button--${type} ${componentClass}__button--${className} h_noselect c_button`}
            aria-label={label}
            data-cookie-action={type}
            onClick={handleClick}
        >
            {label}
            <IconButtonArrow color="white" />
        </button>
    );
};

const Bar = ({ title = '', text = '', buttons = [], active = false, handleButtonClick = () => {} }) => {
    return (
        <div className={`${componentClass}__bar-wrapper ${active ? '' : 's_hidden'}`}>
            <div className={`${componentClass}__bar`}>
                <span className={`${componentClass}__title`}>{title}</span>
                <div className={`${componentClass}__bar-text`}>{parseHTML(text)}</div>
                <div className={`${componentClass}__bar-buttons`}>
                    {buttons.map((btn, index) => (
                        <Button {...btn} key={`bar-button-${index}-${btn.type}`} onClick={handleButtonClick} />
                    ))}
                </div>
            </div>
        </div>
    );
};

const Modal = ({
    title = '',
    dateCookie = '',
    dateLabel = '',
    text = '',
    buttons = [],
    rules = [],
    rulesState,
    active = false,
    handleButtonClick = () => {},
    handleCheckboxChange = () => {},
}) => {
    return (
        <div className={`${componentClass}__modal-wrapper ${active ? '' : 's_hidden'}`}>
            <div className={`${componentClass}__modal`}>
                <button
                    className={`c_circle-button ${componentClass}__modal-close`}
                    onClick={(e) => {
                        handleButtonClick(e, 'close-settings');
                    }}
                >
                    <span className="c_icon c_circle-button__icon">
                        <IconCircleButtonCross />
                    </span>
                </button>
                <span className={`${componentClass}__title`}>{title}</span>
                <div className={`${componentClass}__modal-rules`}>
                    {rules.map((rule, index) => (
                        <Rule
                            {...rule}
                            key={`rule-${index}`}
                            onChange={handleCheckboxChange}
                            checked={rulesState[rule.cookie]}
                        />
                    ))}
                </div>
                <div className={`${componentClass}__modal-text`}>{parseHTML(text)}</div>
                {dateCookie !== '' && (
                    <div className={`${componentClass}__modal-text`}>
                        <b>
                            {dateLabel}&nbsp;
                            {new Date(parseInt(dateCookie, 10)).toLocaleDateString('nl')} (
                            {new Date(parseInt(dateCookie, 10)).toLocaleTimeString('nl')})
                        </b>
                    </div>
                )}
                <div className={`${componentClass}__modal-buttons`}>
                    {buttons.map((btn, index) => (
                        <Button {...btn} key={`bar-button-${index}-${btn.type}`} onClick={handleButtonClick} />
                    ))}
                </div>
            </div>
        </div>
    );
};

const CookieWall = ({ config }) => {
    const { trackEvent } = useMatomo();
    const classes = useStyles();

    // Cookie utils
    // ************************************************************************************************
    const setCookie = (name, value) => {
        const settings = {
            domain: config.settings.cookie.domain,
            path: config.settings.cookie.path,
            expires: parseInt(config.settings.cookie.expires, 10),
            secure: config.settings.cookie.secure,
        };
        return Cookies.set(`${config.settings.cookie.nameprefix}${name}`, value, settings);
    };
    const getCookie = (name) => Cookies.get(`${config.settings.cookie.nameprefix}${name}`);

    // State
    // ************************************************************************************************
    const initRuleState = {};
    let allCookiesPresent = true;
    let needsRenewal = false;
    const lastChangeDate = config.lastChangeDate;
    const dateCookie = getCookie(config.settings.cookie.name);

    config.rules.forEach((rule) => {
        const cookieValue = getCookie(rule.cookie);
        let checked = false;
        // if default checked: do not alter the checked state
        if (rule.defaultChecked) {
            checked = true;
        }
        // get value from cookie
        else {
            checked = cookieValue === 'true' ? true : false;
        }
        initRuleState[rule.cookie] = checked;

        if (cookieValue === undefined) {
            allCookiesPresent = false;
        }
    });

    if (dateCookie === undefined) allCookiesPresent = false;

    // Check if the date of the last change is later (bigger) than the date the cookie was set.
    if (lastChangeDate > parseInt(dateCookie, 10) / 1000) needsRenewal = true;

    // If the cookies need renewal, remove the current cookies so we don't get things twisted.
    if (needsRenewal) {
        config.rules.forEach((rule) => {
            Cookies.remove(config.settings.cookie.nameprefix + rule.cookie, { path: '' });
        });
        Cookies.remove(config.settings.cookie.nameprefix + config.settings.cookie.name, { path: '' });
    }

    // If some cookie is missing, delete the rest, they need to be set again
    if (!allCookiesPresent) {
        config.rules.forEach((rule) => {
            Cookies.remove(config.settings.cookie.nameprefix + rule.cookie, { path: '' });
        });
        Cookies.remove(config.settings.cookie.nameprefix + config.settings.cookie.name, { path: '' });
    }

    const initShowBarState = dateCookie === '' || dateCookie === undefined || !allCookiesPresent || needsRenewal;

    const [rulesState, setRulesState] = useState(initRuleState);
    const [showBar, setShowBar] = useState(initShowBarState);
    const [showModal, setShowModal] = useState(false);
    const [hash, setHash] = useHash();

    // listen for hash changes and show the settings modal if hashes match
    useEffect(() => {
        setShowModal(hash === `#${config.settings.hash}`);
    }, [config.settings.hash, hash]);

    // Remove cookies that need to actively be removed
    config.cookiesToDelete.forEach((cookie) => {
        Cookies.remove(config.settings.cookie.nameprefix + cookie, { path: '' });
    });

    // Change/click handlers
    // ************************************************************************************************
    const handleButtonClick = (_, type) => {
        switch (type) {
            case 'open-settings': {
                setShowModal(true);
                setHash(`#${config.settings.hash}`);
                break;
            }
            case 'close-settings': {
                setShowModal(false);
                setHash('');
                break;
            }
            case 'accept-all': {
                acceptAll();
                setHash('');
                break;
            }
            case 'functional-only': {
                acceptFunctional();
                setHash('');
                break;
            }
            case 'save-settings':
            case 'accept-selection': {
                acceptSelection();
                setHash('');
                break;
            }
            default: {
                console.error('no handler conifgured yet!');
            }
        }
    };
    const handleCheckboxChange = (event, cookieType) => {
        setRulesState((prevState) => ({
            ...prevState,
            ...{
                [cookieType]: event.target.checked,
            },
        }));
    };

    // Rules accept functions
    // ************************************************************************************************
    const acceptAll = () => {
        const currentDateAndTime = Date.now();
        setCookie(config.settings.cookie.name, currentDateAndTime);
        config.rules.forEach((rule) => {
            const isActivated = true;
            setCookie(rule.cookie, isActivated);
            if (rule.matomoEvent) trackEvent(rule.matomoEvent);
        });
        setShowBar(false);
        setShowModal(false);
    };

    const acceptFunctional = () => {
        const currentDateAndTime = Date.now();
        setCookie(config.settings.cookie.name, currentDateAndTime);
        config.rules.forEach((rule) => {
            const isActivated = rule.matomoEvent.action === 'functional' ? true : false;
            setCookie(rule.cookie, isActivated);
            if (rule.matomoEvent) trackEvent(rule.matomoEvent);
        });
        setShowBar(false);
        setShowModal(false);
    };

    const acceptSelection = () => {
        const currentDateAndTime = Date.now();
        setCookie(config.settings.cookie.name, currentDateAndTime);
        config.rules.forEach((rule) => {
            const isActivated = rulesState[rule.cookie];
            setCookie(rule.cookie, isActivated);
            if (rule.matomoEvent) trackEvent(rule.matomoEvent);
        });
        setShowBar(false);
        setShowModal(false);
    };

    // Render view
    // ************************************************************************************************
    return (
        <div className={`${classes.root} ${componentClass}`}>
            <Bar {...config.bar} active={showBar} handleButtonClick={handleButtonClick} />
            <Modal
                {...config.modal}
                dateLabel={config.modal.change}
                dateCookie={dateCookie}
                rules={config.rules}
                rulesState={rulesState}
                active={showModal}
                handleButtonClick={handleButtonClick}
                handleCheckboxChange={handleCheckboxChange}
            />
        </div>
    );
};

const CookieWallMemo = memo(CookieWall);
export default CookieWallMemo;
