import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => {
    return {
        text: {
            '& * + h6': {
                marginTop: theme.spacing(3),
            },
        },
        textWhite: {
            '& * ': {
                color: theme.palette.common.white,
            },
        },
    };
});

export default useStyles;
