import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => {
    return {
        wrapper: {
            padding: theme.spacing(2),
            background: theme.palette.common.white,
            borderRadius: '12px',

            [theme.breakpoints.up('lg')]: {
                padding: theme.spacing(4),
            },
        },
        titleWrapper: {
            marginBottom: theme.spacing(2),
        },
        info: {
            marginBottom: theme.spacing(3),
        },
        box: {
            width: '100%',
            position: 'relative',
            borderRadius: '12px',
            overflow: 'hidden',
        },
        videobox: {
            cursor: 'pointer',
        },
        sidebar: {},
        sidebarItem: {
            width: '100%',
            marginBottom: theme.spacing(2),
            position: 'relative',
            [theme.breakpoints.up('lg')]: {
                marginBottom: theme.spacing(3),
            },
            '&:last-child': {
                marginBottom: '0!important',
            },
        },
        play: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translateY(-50%) translateX(-50%)',
            width: '64px',
            height: '64px',
            background: theme.palette.common.white,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '100%',
            transition: 'background 0.2s ease-out, color 0.2s ease-out',
            cursor: 'pointer',
            fontSize: '24px',
            color: theme.palette.primary.main,

            '&:hover': {
                background: theme.palette.primary.main,
                color: theme.palette.common.white,
            },
        },
        buttons: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(-1),
        },
        button: {
            marginBottom: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        content: {
            background: 'red',
        },
        text: {
            color: theme.palette.gray.dark,
        },
    };
});

export default useStyles;
