import React, { useState, useRef, useEffect } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { useDrawerContext } from '@prowise/react-mui';
import useMediaQuery from '@mui/material/useMediaQuery';
import Popover from '@mui/material/Popover';
import { Icon } from '@mui/material';
import { useMatomo } from '@datapunt/matomo-tracker-react';

import LayoutDefault from '../../components/Layouts/Default/Default';
import Title from '../../components/Atoms/Title/Title';
import ActionButton from '../../components/Molecules/ActionButton/ActionButton';
import ShopOverviewCard from '../../components/Molecules/ShopOverviewCard/ShopOverviewCard';
import ShopPopOver from '../../components/Organisms/ShopPopOver/ShopPopOver';
import Text from '../../components/Atoms/Text/Text';

import useStyles from './ShopStyle';
import { useSelector, useDispatch } from 'react-redux';
import { selectShop, addToCart, selectTotalCartCount } from '../../redux/dataSlice';

const Shop = () => {
    const dispatch = useDispatch();
    const data = useSelector(selectShop).overview;

    document.title = data.title;
    const { trackPageView } = useMatomo();
    useEffect(() => {
        trackPageView();
        // eslint-disable-next-line
    }, []);

    const classes = useStyles();
    const count = useSelector(selectTotalCartCount);

    // state for popover
    const shoppingCartButtonRef = useRef(null);
    const [popover, setPopover] = useState({});
    const open = Object.keys(popover).length > 0;
    const id = open ? 'simple-popover' : undefined;
    const handleAddToCart = (id, variantId, title, description, img) => {
        dispatch(
            addToCart({
                id,
                variantId,
                quantity: 1,
            }),
        );
        setPopover({
            title: data.addedToCart.title,
            img: img,
            variantTitle: title,
            description: description,
            to: '/shop/cart/',
            linkLabel: data.addedToCart.linkLabel,
        });
    };
    const closePopOver = () => setPopover({});

    // columns and drawer stuff
    const drawerState = useDrawerContext();
    const matchesWeirdBreakpoint = useMediaQuery('(max-width: 830px)');
    const matchesWeirdSecBreakpoint = useMediaQuery('(max-width: 960px)');
    const matchesWeirdSmallBreakpoint = useMediaQuery('(max-width: 680px)');
    const matchesSmBreakpoint = useMediaQuery((theme) => theme.breakpoints.up('sm'));
    const matchesMdBreakpoint = useMediaQuery((theme) => theme.breakpoints.up('md'));
    const matchesLgBreakpoint = useMediaQuery((theme) => theme.breakpoints.up('lg'));
    const getColClasses = (index) => {
        const defaultClasses = {
            xs: 12,
            sm: 4,
            md: 4,
            lg: 3,
        };
        if (drawerState.opened && matchesWeirdBreakpoint)
            return {
                xs: 12,
                sm: 12,
                md: 12,
            };
        if (drawerState.opened && matchesWeirdSecBreakpoint)
            return {
                xs: 12,
                sm: 6,
            };
        if (drawerState.opened && matchesMdBreakpoint)
            return {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 3,
            };
        if (!drawerState.opened && matchesWeirdSmallBreakpoint)
            return {
                xs: 12,
                sm: 6,
            };
        return defaultClasses;
    };

    return (
        <LayoutDefault>
            <Container>
                <Grid container direction="column" className={classes.root}>
                    <Grid container item direction="column" className={classes.top}>
                        <Grid item xs={12} className={classes.title}>
                            <Title title={data.title} size="h2" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Text text={data.text} className={classes.text} />
                        </Grid>
                    </Grid>
                    <Grid container item xs={12}>
                        <Grid
                            container
                            item
                            direction="row"
                            spacing={
                                (matchesLgBreakpoint && 4) || (matchesSmBreakpoint && 3) || (!matchesSmBreakpoint && 2)
                            }
                        >
                            {data.items
                                .filter((item) => item.variants && item.variants.length > 0)
                                .map((item, i) => (
                                    <Grid item key={`shop-item-${item.id}-${i}`} {...getColClasses(i)}>
                                        <ShopOverviewCard
                                            {...item}
                                            handleAddToCart={handleAddToCart}
                                            linkLabel={data.linkLabel}
                                            variantText={data.variantText}
                                        />
                                    </Grid>
                                ))}
                        </Grid>
                    </Grid>
                </Grid>
                <div ref={shoppingCartButtonRef}>
                    <ActionButton count={count} to="/shop/cart/" />
                </div>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={shoppingCartButtonRef.current}
                    onClose={closePopOver}
                    className={classes.popover}
                    disableScrollLock={true}
                >
                    <ShopPopOver {...popover} />
                    {open && (
                        <div className={classes.popoverClose} onClick={closePopOver}>
                            <Icon className="material-icons-round">close</Icon>
                        </div>
                    )}
                </Popover>
            </Container>
        </LayoutDefault>
    );
};

export default Shop;
