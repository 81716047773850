import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => {
    return {
        SummaryBlock: {
            width: '100%',
            padding: theme.spacing(3),
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '12px',
            border: '2px solid transparent',
            borderColor: theme.palette.secondary.main,
            [theme.breakpoints.up('lg')]: {
                padding: theme.spacing(4),
            },
            '& h4': {
                marginBottom: theme.spacing(4),
            },
            '& div': {
                fontWeight: 'normal',
            },
            '&__item + &__item': {
                marginTop: theme.spacing(2),
            },
            '&__children': {
                marginTop: theme.spacing(2),
            },
        },
    };
});

export default useStyles;
