import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import StarOutlinedIcon from '@mui/icons-material/StarOutlined';

import DownloadButton from '../../Atoms/DownloadButton/DownloadButton';
import DownloadIcon from '../../Atoms/DownloadIcon/DownloadIcon';
import Img from '../../Atoms/Img/Img';
import Text from '../../Atoms/Text/Text';
import useStyles from './DownloadCardStyle';

const DownloadCard = ({ id, title, img, onClick, icon, type, featured, className }) => {
    const classes = useStyles();
    const handleOnClick = () => {
        onClick({
            featured,
            id,
        });
    };
    return (
        <Box className={`${classes.wrapper} ${className}`}>
            <Box className={classes.card} onClick={handleOnClick}>
                <Box className={classes.apect}>
                    <Box className={classes.imgIconWrapper}>
                        {img.src === '' ? <DownloadIcon icon={icon} /> : <Img {...img} objectFit={true} />}
                    </Box>
                </Box>
                {featured && (
                    <Box className={classes.favorite}>
                        <StarOutlinedIcon />
                    </Box>
                )}
                {type === 'collection' && (
                    <Box className={classes.collection}>
                        <FolderOutlinedIcon />
                    </Box>
                )}
                <Box className={classes.overlay} />
                <DownloadButton className={classes.button} />
            </Box>
            <Text text={title} className={classes.title} />
        </Box>
    );
};

DownloadCard.propTypes = {
    id: PropTypes.string,
    title: PropTypes.string,
    img: PropTypes.object,
    variants: PropTypes.array,
    featured: PropTypes.bool,
    type: PropTypes.oneOf(['download', 'collection']),
    onClick: PropTypes.func,
    className: PropTypes.string,
    icon: PropTypes.string,
};

DownloadCard.defaultProps = {
    id: '',
    title: '',
    img: {},
    variants: [],
    featured: false,
    type: 'download',
    onClick: () => {},
    className: '',
    icon: '',
};

export default DownloadCard;
