import PropTypes from 'prop-types';
import { Fab as ProwiseFab } from '@prowise/react-mui';

import useStyles from './FabStyle';

const Fab = ({ color, children, className, onClick }) => {
    const classes = useStyles();
    return (
        <ProwiseFab color={color} className={`${classes.Fab} ${className}`} onClick={onClick}>
            {children}
        </ProwiseFab>
    );
};

Fab.propTypes = {
    color: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
};

Fab.defaultProps = {
    color: 'secondary',
    className: '',
    onClick: () => {},
};

export default Fab;
