import { useEffect } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useParams, Redirect } from 'react-router-dom';
import { useDrawerContext } from '@prowise/react-mui';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useMatomo } from '@datapunt/matomo-tracker-react';

import LayoutDefault from '../../components/Layouts/Default/Default';
import Title from '../../components/Atoms/Title/Title';
import Button from '../../components/Atoms/Button/Button';
import SummaryBlock from '../../components/Molecules/SummaryBlock/SummaryBlock';
import AddToCalendar from '../../components/Molecules/AddToCalendar/AddToCalendar';
import { ArrowForward } from '@mui/icons-material';
import Img from '../../components/Atoms/Img/Img';
import Chip from '../../components/Atoms/Chip/Chip';
import TextBlock from '../../components/Molecules/TextBlock/TextBlock';

import { convertLinkDataToProps } from '../../utilities/utilities';

import { useSelector } from 'react-redux';
import { selectEventItemBySimpleId, selectEvents } from '../../redux/dataSlice';

import useStyles from './EventsDetailStyle';

const EventsDetail = () => {
    const classes = useStyles();
    const params = useParams();
    const eventsData = useSelector(selectEvents);
    const selectEventItemBySimpleIdFilter = useSelector(selectEventItemBySimpleId);
    const data = selectEventItemBySimpleIdFilter(params.simpleId);
    const drawerState = useDrawerContext();

    const hasLink1 = Object.keys(data.link1).length > 0 && data.link1.href !== '' && data.link1.label !== '';
    const hasLink2 = Object.keys(data.link2).length > 0 && data.link2.href !== '' && data.link2.label !== '';
    const matchesSmBreakpoint = useMediaQuery((theme) => theme.breakpoints.up('sm'));
    const matchesLgBreakpoint = useMediaQuery((theme) => theme.breakpoints.up('lg'));

    document.title = data.title;
    const { trackPageView, trackEvent } = useMatomo();
    useEffect(() => {
        trackPageView();
        // eslint-disable-next-line
    }, []);

    const getColClasses = () => {
        if (drawerState.opened && matchesSmBreakpoint) {
            return {
                xs: 12,
                md: 6,
                lg: 12,
            };
        }
        return {
            xs: 12,
            sm: 6,
            lg: 12,
        };
    };

    const getColClassesTop = () => {
        if (drawerState.opened && matchesSmBreakpoint) {
            return `${classes.summary}--drawerstate`;
        }
        return '';
    };

    const getColClassesBottom = () => {
        if (drawerState.opened && matchesSmBreakpoint) {
            return `${classes.content}--drawerstate`;
        }
        return '';
    };

    const buttonEvent = (buttonData) => {
        trackEvent({
            category: 'event-detail',
            action: 'button-click',
            name: buttonData.label,
            value: buttonData.href,
            customDimensions: [
                {
                    id: 'url',
                    value: data.simpleId,
                },
                {
                    id: 'title',
                    value: data.title,
                },
            ],
        });
    };
    const handleClickButton1 = () => buttonEvent(data.link1);
    const handleClickButton2 = () => buttonEvent(data.link2);

    if (data) {
        return (
            <LayoutDefault>
                <Container>
                    <Grid container className={classes.root} spacing={3} direction="column">
                        <Grid item xs>
                            <div className={classes.eventsDetailTitle}>
                                <Title title={data.title} size="h2" />
                            </div>
                        </Grid>
                        <Grid container item xs={12}>
                            <Grid
                                item
                                container
                                direction="row"
                                spacing={(matchesLgBreakpoint && 4) || (!matchesLgBreakpoint && 3)}
                            >
                                <Grid container item direction="row" xs={12} lg={8}>
                                    <Grid item container xs={12}>
                                        <div className={classes.teaser}>
                                            <Img {...data.imgLarge} className={classes.img} />
                                            <Chip className={classes.chip} label={data.label} />
                                            <div className={classes.logo}>
                                                <Img
                                                    src={data.logo.src}
                                                    aspectRatio={data.logo.aspectRatio}
                                                    alt={data.title}
                                                />
                                            </div>
                                        </div>
                                    </Grid>

                                    <Grid item container {...getColClasses()}>
                                        <Box sx={{ display: { xs: 'flex', lg: 'none' }, width: '100%' }}>
                                            <div className={`${classes.summary} ${getColClassesTop()}`}>
                                                <SummaryBlock
                                                    date={data.date}
                                                    time={data.time}
                                                    tags={data.tags}
                                                    language={data.language}
                                                    location={data.location}
                                                    eventLink={data.eventLink}
                                                    title={data.summaryTitle}
                                                >
                                                    <AddToCalendar
                                                        data={data.calendar}
                                                        label={eventsData.addToCalendarLabel}
                                                    />
                                                </SummaryBlock>
                                            </div>
                                        </Box>
                                    </Grid>
                                    <Grid item container {...getColClasses()}>
                                        <div className={`${classes.content} ${getColClassesBottom()}`}>
                                            <TextBlock
                                                text={`<p><b>${data.introText}</b></p>${data.text}`}
                                                className={classes.text}
                                            />
                                            {(hasLink1 || hasLink2) && (
                                                <div className={classes.buttons}>
                                                    {hasLink1 && (
                                                        <Button
                                                            endIcon={<ArrowForward />}
                                                            className={classes.button}
                                                            onClick={handleClickButton1}
                                                            {...convertLinkDataToProps(data.link1)}
                                                        />
                                                    )}
                                                    {hasLink2 && (
                                                        <Button
                                                            endIcon={<ArrowForward />}
                                                            variant="outlined"
                                                            className={classes.button}
                                                            onClick={handleClickButton2}
                                                            {...convertLinkDataToProps(data.link2)}
                                                        />
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid container item direction="column" xs={12} lg={4}>
                                    <Box sx={{ display: { xs: 'none', lg: 'flex' } }}>
                                        <SummaryBlock
                                            date={data.date}
                                            time={data.time}
                                            tags={data.tags}
                                            language={data.language}
                                            location={data.location}
                                            eventLink={data.eventLink}
                                            title={data.summaryTitle}
                                        >
                                            <AddToCalendar data={data.calendar} label={eventsData.addToCalendarLabel} />
                                        </SummaryBlock>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </LayoutDefault>
        );
    }
    return <Redirect to="/404/" />;
};

export default EventsDetail;
