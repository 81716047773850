import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => {
    return {
        // layout stuff
        mainItem: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            padding: theme.spacing(3),
            background: theme.palette.common.white,
            boxShadow: theme.shadows[2],
            borderRadius: '12px',
        },
        subitems: {
            width: '100%',
            paddingTop: theme.spacing(3),
            paddingLeft: theme.spacing(3),
            marginBottom: `-${theme.spacing(1)}`,
        },
        subitem: {
            marginBottom: theme.spacing(1),
        },

        // Card styles
        card: {
            width: '100%',
            padding: theme.spacing(2),
            borderRadius: theme.spacing(2),
            background: 'rgba(0,0,0,0.03)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            position: 'relative',
            [theme.breakpoints.up('md')]: {
                alignItems: 'flex-start',
                flexDirection: 'row',
            },
        },
        imgWrapper: {
            position: 'relative',
            width: 136,
            height: 136,
            flex: `0 0 136px`,
            borderRadius: theme.shape.borderRadius * 2,
            overflow: 'hidden',
            marginBottom: theme.spacing(2),
            [theme.breakpoints.up('md')]: {
                marginBottom: '0',
                marginRight: theme.spacing(2),
            },
        },
        img: {
            height: '100%',
        },
        text: { opacity: 0.5 },
        content: { width: '100%' },

        // variants as buttons
        variantsAsButtons: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'flex-end',
            marginTop: theme.spacing(2),
            marginBottom: `-${theme.spacing(1)}`,
        },
        buttonVariantButton: {
            margin: `0 0 ${theme.spacing(1)} ${theme.spacing(1)}`,
        },

        // variants as list
        variantsAsList: {},
        variantRow: {
            display: 'flex',
            alignItems: 'center',
            paddingTop: theme.spacing(0.5),
            paddingBottom: theme.spacing(0.5),
            borderBottom: `1px solid ${theme.palette.grey[300]}`,
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            '&:last-child': {
                borderBottom: 'none',
            },
            '&:hover': {
                backgroundColor: theme.palette.grey[200],
            },
        },
        fileSize: {
            whiteSpace: 'nowrap',
            opacity: 0.5,
            paddingLeft: theme.spacing(0.5),
            paddingRight: theme.spacing(0.5),
        },
        buttonVariantList: {
            marginLeft: 'auto',
            paddingLeft: theme.spacing(1),
        },
        button: {
            background: theme.palette.primary.main,
            color: theme.palette.common.white,
            '&:hover': {
                background: theme.palette.primary.colors[400],
                color: theme.palette.common.white,
            },
        },
    };
});

export default useStyles;
