import { Icon } from '@mui/material';

const getIcon = (icon) => {
    switch (icon.toLowerCase()) {
        case 'jpg':
        case 'png':
        case 'ai':
        case 'svg':
        case 'eps': {
            return 'image';
        }
        case 'ttf':
        case 'otf': {
            return 'font_download';
        }
        case 'indd':
        case 'indl':
        case 'indb':
        case 'xd': {
            return 'adobe';
        }
        case 'mp3': {
            return 'audiotrack';
        }
        case 'mp4': {
            return 'movie';
        }
        case 'pdf': {
            return 'picture_as_pdf';
        }
        case 'zip': {
            return 'folder';
        }
        // word & excell
        case 'docx':
        case 'docm':
        case 'dotx':
        case 'dotm':
        case 'docb':
        case 'csv':
        case 'xls':
        case 'xlsb':
        case 'xlsm':
        case 'xlsx': {
            return 'description';
        }

        default: {
            return 'description';
        }
    }
};

const DownloadIcon = ({ icon }) => {
    return <Icon className="material-icons-outlined">{getIcon(icon)}</Icon>;
};

export default DownloadIcon;
