import { alpha } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => {
    return {
        Cart: {
            '&__items': {
                marginTop: theme.spacing(3),
                width: '100%',
            },
            '&__item': {
                marginBottom: theme.spacing(2.5),
            },
            '&__buttons': {
                marginTop: theme.spacing(4),
                display: 'flex',
                justifyContent: 'flex-end',
            },
            '&__button-submit': {
                marginLeft: theme.spacing(2),
            },
            '&__loader': {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: alpha(theme.palette.secondary.colors[100], 0.8),
            },
        },
        text: {
            color: theme.palette.gray.dark,
        },
    };
});

export default useStyles;
