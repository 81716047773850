import React, { useEffect } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { ArrowForward } from '@mui/icons-material';
import { useMatomo } from '@datapunt/matomo-tracker-react';

import LayoutDefault from '../../components/Layouts/Default/Default';
import Title from '../../components/Atoms/Title/Title';
import Button from '../../components/Atoms/Button/Button';
import Text from '../../components/Atoms/Text/Text';

import useStyles from './ShopStyle';
import { useSelector } from 'react-redux';
import { selectShop } from '../../redux/dataSlice';

const ShopSuccess = () => {
    const data = useSelector(selectShop).success;

    document.title = data.title;
    const { trackPageView } = useMatomo();
    useEffect(() => {
        trackPageView();
        // eslint-disable-next-line
    }, []);

    const classes = useStyles();

    return (
        <LayoutDefault>
            <Container>
                <Grid container direction="column" className={classes.root}>
                    <Grid container item direction="column" className={classes.ShopOverviewTop} spacing={2}>
                        <Grid item xs={12}>
                            <Title title={data.title} size="h2" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Text text={data.text} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Button label={data.linkLabel} color="secondary" endIcon={<ArrowForward />} to={'/shop/'} />
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </LayoutDefault>
    );
};

export default ShopSuccess;
