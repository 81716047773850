import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            margin: `0px ${theme.spacing(1)} ${theme.spacing(4)}`,
        },
    };
});

export default useStyles;
