export const partnerPaletteColors = {
    50: '#FEF8E9',
    100: '#FEF0D3',
    200: '#FDE1A7',
    300: '#FCD37A',
    400: '#FBC44E',
    500: '#FAB522',
    600: '#F9AC21',
    700: '#F9A31F',
    800: '#F89A1E',
    900: '#F7911C',
    A100: '#b9f6ca',
    A200: '#69f0ae',
    A400: '#00e676',
    A700: '#00c853',
};
const partnerPalette = {
    colors: partnerPaletteColors,
    light: partnerPaletteColors[200],
    main: partnerPaletteColors[900],
    dark: partnerPaletteColors[900],
};

const themeOverrides = {
    palette: {
        secondary: partnerPalette,
        link: '#1686c6', // Bright blue link color from design
        pink: '#FD72AE',
        gradients: {
            toolbar: `transparent linear-gradient(90deg, ${partnerPaletteColors[900]} 0%, ${partnerPaletteColors[500]} 100%) 0% 0% no-repeat padding-box`,
        },
    },
    typography: {
        fontSizeSmaller: '12px',
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1280,
            xl: 1536,
        },
    },
};

const globalStyles = {
    html: {
        width: '100%',
        height: '100%',
    },
    body: {
        width: '100%',
        height: '100%',
        backgroundColor: partnerPaletteColors[100],
        overflowY: 'scroll',
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: '0.875rem',
    },
};

export { globalStyles };
export default themeOverrides;
