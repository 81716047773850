import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => {
    return {
        title: {
            marginBottom: theme.spacing(3),

            '& h2': {
                marginBottom: '0',
            },

            [theme.breakpoints.up('lg')]: {
                marginBottom: theme.spacing(4),

                '& h2': {
                    marginBottom: theme.spacing(1),
                },
            },
        },
        buttonWrapper: {
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            marginTop: theme.spacing(4),
        },
        filtersWrapper: {
            marginTop: theme.spacing(2),
        },
    };
});

export default useStyles;
