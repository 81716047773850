import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => {
    return {
        videoplayer: {
            width: '100%',
            position: 'relative',
        },
        aspect: {
            width: '100%',
            paddingBottom: '56.25%',
            position: 'relative',
            border: `5px solid ${theme.palette.common.white}`,
            borderRadius: '12px',
            overflow: 'hidden',
            background: theme.palette.common.white,
        },
        iframe: {
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: '0',
            left: '0',
        },
    };
});

export default useStyles;
