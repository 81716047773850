import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Body } from '@prowise/react-mui';

import Button from '../../Atoms/Button/Button';
import useStyles from './ShopOverviewCardStyle';
import Title from '../../Atoms/Title/Title';
import Icon from '@mui/material/Icon';

import ShopPopUp from '../../Molecules/ShopPopUp/ShopPopUp';
import CustomDialog from '../../Molecules/CustomDialog/CustomDialog';
import { formatPrice } from '../../../utilities/utilities';
import Img from '../../Atoms/Img/Img';

const ShopOverviewCard = ({
    img,
    title,
    price,
    description,
    priceLabel,
    id,
    variants,
    linkLabel,
    variantText,
    handleAddToCart,
}) => {
    const classes = useStyles();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const handleModalClose = () => {
        setModalIsOpen(false);
    };
    const handleModalOpen = () => {
        setModalIsOpen(true);
    };

    const handleClick = () => {
        if (variants.length > 1) return handleModalOpen();
        return handleAddToCart(id, variants[0].id, title, description, img);
    };

    return (
        <Fragment>
            <div className={classes.card} onClick={handleClick}>
                <div className={classes.imgWrapper}>
                    <Img {...img} className={classes.img} />
                    <div className={classes.price}>
                        {priceLabel} {formatPrice(price)}
                    </div>
                </div>

                <div className={classes.content}>
                    <Title title={title} size="h6" />
                    <Body>{description}</Body>
                </div>
                <div className={classes.button}>
                    <Button
                        label={linkLabel}
                        startIcon={<Icon className={'material-icons-outlined'}>add_shopping_cart</Icon>}
                        component="button"
                    />
                </div>
            </div>
            {variants.length > 1 && (
                <CustomDialog open={modalIsOpen} handleClose={handleModalClose} amountOfColumns={7} closeButton={true}>
                    <ShopPopUp
                        id={id}
                        variants={variants}
                        variantText={variantText}
                        img={img}
                        title={title}
                        linkLabel={linkLabel}
                        handleAddToCart={handleAddToCart}
                        handleModalClose={handleModalClose}
                    />
                </CustomDialog>
            )}
        </Fragment>
    );
};

ShopOverviewCard.propTypes = {
    image: PropTypes.string,
    alt: PropTypes.string,
    title: PropTypes.string,
    price: PropTypes.number,
    description: PropTypes.string,
    variants: PropTypes.array,
    handleAddToCart: PropTypes.func,
};

ShopOverviewCard.defaultProps = {
    image: '',
    alt: '',
    title: '',
    price: 0,
    description: '',
    variants: [],
    handleAddToCart: () => {},
};

export default ShopOverviewCard;
