import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => {
    return {
        link: {
            textDecoration: 'none',
        },
        card: {
            width: '100%',
            padding: theme.spacing(2),
            borderRadius: '12px',
            display: 'flex',
            flexDirection: 'column',
            background: theme.palette.common.white,
            height: '100%',
            [theme.breakpoints.up('lg')]: {
                padding: theme.spacing(4),
            },

            '&:hover': {
                '& $img': {
                    transform: 'scale(1.05)',
                },
            },
        },
        newsImgWrapper: {
            width: '100%',
            display: 'block',
            position: 'relative',
            marginBottom: theme.spacing(2),
            borderRadius: theme.shape.borderRadius * 2,
            overflow: 'hidden',
        },
        img: {
            width: '100%',
            transition: 'transform 0.2s ease-out',
        },
        titleWrapper: {
            marginBottom: theme.spacing(2),
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            '-webkit-line-clamp': 2,
            '-webkit-box-orient': 'vertical',

            '& h4': {
                marginBottom: '0',
            },
        },
        info: {
            marginBottom: theme.spacing(2),
            marginTop: 'auto',
        },
        content: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            '-webkit-line-clamp': 4,
            '-webkit-box-orient': 'vertical',
        },
    };
});

export default useStyles;
