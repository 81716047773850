import React, { useState, useEffect, useContext, createContext } from 'react';
import { useHistory } from 'react-router-dom';

const AppHistoryContext = createContext([]);

// https://github.com/remix-run/history/issues/573

export const AppHistoryProvider = ({ children }) => {
    const history = useHistory();
    const [items, setItems] = useState([]);
    const [nextItems, setNextItems] = useState([]);

    useEffect(() => {
        return history.listen((location) => {
            if (history.action === 'PUSH') {
                setItems((prevState) => [...prevState, ...[location]]);
                setNextItems([]);
            }

            // if (history.action === 'POP') {
            //     if (locationKeys[1] === location.key) {
            //         setLocationKeys(([_, ...keys]) => keys);
            //         // Handle forward event
            //     } else {
            //         setLocationKeys((keys) => [location.key, ...keys]);
            //         // Handle back event
            //     }
            // }
        });
        // eslint-disable-next-line
    }, []);

    const saveForNext = () => {
        setNextItems((prevState) => [...prevState, ...[getLast()]]);
    };

    const deleteLastNext = () => {
        return setNextItems((prevState) => prevState.splice(0, -1));
    };
    const getLastNext = () => {
        return [...nextItems].pop();
    };

    const deleteLast = () => {
        return setItems((prevState) => prevState.splice(0, -1));
    };
    const getLast = () => {
        return [...items].pop();
    };

    return (
        <AppHistoryContext.Provider
            value={{
                items,
                deleteLast,
                nextItems,
                deleteLastNext,
                getLastNext,
                getLast,
                saveForNext,
            }}
        >
            {children}
        </AppHistoryContext.Provider>
    );
};

export const useAppHistory = () => {
    const store = useContext(AppHistoryContext);
    return store;
};

export default useAppHistory;
