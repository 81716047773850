import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { allEqual } from '../utilities/utilities';
import { flatten, nestSubFilters } from '../utilities/dataFormatting';
import API from '../api/api';

export const getInitData = createAsyncThunk('getInitData', async (payload, thunkAPI) => {
    const currentState = thunkAPI.getState();
    const data = {
        includeFilter: true,
        includeFeatured: true,
        countPerPage: currentState.downloads.pagination.rowsPerPage,
        currentPage: currentState.downloads.pagination.currentPage,
    };
    const res = await API.post({
        endpoint: 'downloads',
        data,
    });
    return res.data;
});
export const getDownloadItemsAsync = createAsyncThunk('getDownloadItemsAsync', async (payload, thunkAPI) => {
    const currentState = thunkAPI.getState();

    // abortController
    const { signal } = thunkAPI;
    const controller = new AbortController();
    signal.addEventListener('abort', () => controller.abort());

    const data = {
        includeFilter: false,
        includeFeatured: false,
        filters: selectFiltersForAPI(currentState),
        search: currentState.downloads.search,
        countPerPage: currentState.downloads.pagination.rowsPerPage,
        currentPage: currentState.downloads.pagination.currentPage,
    };
    const res = await API.post({
        endpoint: 'downloads',
        data,
        axiosOptions: {
            signal: controller.signal,
        },
    });
    return res.data;
});

// NOTE: we also NEED to pass this same object structure to the "preloadedState" when initializing the store
export const initalDownloadsState = {
    isInited: false,
    isIniting: false,
    isFetching: false,
    hasError: false,
    items: [],
    featured: [],
    modalData: {},
    viewType: 'grid',
    pagination: {
        currentPage: 0,
        totalItems: 0,
        rowsPerPage: 24,
    },
    filters: [],
    search: '',
    suggestions: [],
};

export const dataSlice = createSlice({
    name: 'downloads',
    initialState: initalDownloadsState,
    reducers: {
        setViewType: (state, action) => {
            state.viewType = action.payload;
        },
        setSearch: (state, action) => {
            // update the search value
            state.search = action.payload;
            // clear all items! we need a clean slate
            state.items = [];
            // reset current page index
            state.pagination.currentPage = 0;
        },
        setIsFetching: (state, action) => {
            state.isFetching = action.payload;
        },
        setRowsPerPage: (state, action) => {
            state.pagination.rowsPerPage = action.payload;
            state.pagination.currentPage = 0;
        },
        setCurrentPage: (state, action) => {
            state.pagination.currentPage = action.payload;
        },
        resetAllFilters: (state) => {
            state.filters = state.filters.map((item) => ({ ...item, ...{ checked: false, expanded: false } }));
        },
        updateFilter: (state, action) => {
            let duplicate = JSON.parse(JSON.stringify(state.filters));
            // update the currently changed filter item
            duplicate = duplicate.map((item) => {
                if (item.id === action.payload.id)
                    return {
                        ...item,
                        checked: action.payload.checked,
                        // expanded: action.payload.checked,
                        indeterminate: false,
                    };
                return item;
            });

            // update all subfilter states if there are any subfilters
            if (action.payload.sub.length > 0) {
                // const obj = { checked: action.payload.checked };
                const allChildrenToUpdate = flatten(action.payload.sub).map((i) => i.id);
                duplicate = duplicate.map((item) => {
                    if (!allChildrenToUpdate.includes(item.id)) return item;
                    return {
                        ...item,
                        ...{
                            checked: action.payload.checked,
                            // expanded: action.payload.checked,
                            indeterminate: false,
                        },
                    };
                });
            }

            // check if a nested filter is updated
            if (action.payload.level > 0) {
                const allFiltersOfTheSameParentAndLevel = duplicate.filter(
                    (item) => item.parent === action.payload.parent,
                );
                const allStates = allFiltersOfTheSameParentAndLevel.map((item) => item.checked);
                const allItemsAreEqual = allEqual(allStates);

                if (allItemsAreEqual) {
                    duplicate = duplicate.map((item) => {
                        if (item.id === action.payload.parent)
                            return {
                                ...item,
                                ...{
                                    indeterminate: false,
                                    checked: allStates[0],
                                    // expanded: allStates[0],
                                },
                            };
                        return item;
                    });
                } else {
                    duplicate = duplicate.map((item) => {
                        if (item.id === action.payload.parent)
                            return {
                                ...item,
                                ...{
                                    indeterminate: true,
                                    // expanded: true,
                                },
                            };
                        return item;
                    });
                }
            }

            // update them filterz :)
            state.filters = duplicate;
            // clear all items! we need a clean slate
            state.items = [];
            // reset current page index
            state.pagination.currentPage = 0;
        },
        updateExpanded: (state, action) => {
            state.filters = state.filters.map((item) => {
                if (item.id === action.payload.id)
                    return {
                        ...item,
                        ...{
                            expanded: action.payload.expanded,
                        },
                    };
                return item;
            });
        },
        toggleLimited: (state, action) => {
            state.filters = state.filters.map((item) => {
                if (item.id === action.payload.id)
                    return {
                        ...item,
                        ...{
                            limited: !!!item.limited,
                        },
                    };
                return item;
            });
        },
        setModalData: (state, action) => {
            // read the item from either the featured or items array in this store.
            const keyToUse = action.payload.featured ? 'featured' : 'items';
            const result = state[keyToUse].filter((item) => item.id === action.payload.id);
            state.modalData = result.length === 1 ? result[0] : {};
        },
    },
    extraReducers: {
        // getInitData
        // *********************************************
        [getInitData.pending]: (state) => {
            state.isIniting = true;
            state.hasError = false;
        },
        [getInitData.rejected]: (state) => {
            state.isIniting = false;
            state.hasError = true;
        },
        [getInitData.fulfilled]: (state, action) => {
            state.filters = action.payload.filters.map((item) => {
                return {
                    ...item,
                    ...{
                        checked: false,
                        expanded: false,
                        indeterminate: false,
                        limited: true,
                    },
                };
            });
            state.items = action.payload.data;
            state.featured = action.payload.featured;
            state.pagination = {
                ...state.pagination,
                ...{
                    totalItems: action.payload.totalItems,
                },
            };
            state.hasError = false;
            state.isIniting = false;
            state.isInited = true;
        },

        // getDownloadItemsAsync
        // *********************************************
        [getDownloadItemsAsync.pending]: (state) => {
            state.isFetching = true;
            state.hasError = false;
        },
        [getDownloadItemsAsync.rejected]: (state) => {
            state.isFetching = false;
            state.hasError = true;
        },
        [getDownloadItemsAsync.fulfilled]: (state, action) => {
            state.items = [...state.items, ...action.payload.data];
            state.pagination = {
                ...state.pagination,
                ...{
                    totalItems: action.payload.totalItems,
                },
            };
            state.suggestions = action.payload.allSuggestions;
            state.hasError = false;
            state.isFetching = false;
        },
    },
});

export const selectAll = (state) => state.downloads;
export const selectItems = (state) => state.downloads.items;
export const selectFeatured = (state) => state.downloads.featured;
export const selectFormattedFilters = (state) => {
    const duplicate = JSON.parse(JSON.stringify(state.downloads.filters));
    return nestSubFilters(duplicate, '');
};
export const selectFiltersForAPI = (state) =>
    state.downloads.filters.filter((item) => item.checked && item.value !== '').map((item) => item.value);
export const selectModalData = (state) => state.downloads.modalData;
export const selectFilters = (state) => state.downloads.filters;
export const selectActiveFilters = (state) => state.downloads.filters.filter((f) => f.checked && f.value !== '');
export const selectPagination = (state) => state.downloads.pagination;
export const selectSearch = (state) => state.downloads.search;
export const selectViewtype = (state) => state.downloads.viewType;

export default dataSlice.reducer;
export const {
    setViewType,
    setSearch,
    setRowsPerPage,
    setCurrentPage,
    resetAllFilters,
    updateFilter,
    updateExpanded,
    toggleLimited,
    setIsFetching,
    setModalData,
} = dataSlice.actions;
