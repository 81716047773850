import makeStyles from '@mui/styles/makeStyles';
import { isTouchDevice } from '../../../utilities/utilities';

const useStyles = makeStyles((theme) => {
    return {
        wrapper: {
            position: 'relative',
            cursor: 'pointer',
        },
        card: {
            position: 'relative',
            width: '100%',
            padding: theme.spacing(1) / 2,
            background: 'rgba(255,255,255,0.6)',
            borderRadius: '12px',
            overflow: 'hidden',
            '&:hover': {
                '& $overlay': {
                    opacity: 0.8,
                    transform: `translateY(0)`,
                },
                '& $button': {
                    opacity: 1,
                    transform: `translateY(0)`,
                },
            },
        },
        apect: {
            position: 'relative',
            width: '100%',
            paddingTop: '100%',
            borderRadius: '12px',
            overflow: 'hidden',
        },
        imgIconWrapper: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: theme.palette.tertiary.main,
            fontSize: 36,
        },
        overlay: {
            display: isTouchDevice() ? 'none' : 'block',
            position: 'absolute',
            bottom: 0,
            right: 0,
            width: '100%',
            height: '100%',
            background: `transparent linear-gradient(180deg, transparent 0%, #B8D8EA 100%) 0% 0% no-repeat padding-box;`,
            opacity: 0,
            transform: `translateY(${theme.spacing(4)})`,
            transition: 'opacity 0.2s ease-out, transform 0.4s ease-out',
        },
        button: {
            position: 'absolute',
            bottom: theme.spacing(1),
            right: theme.spacing(1),
            opacity: isTouchDevice() ? 1 : 0,
            transform: isTouchDevice() ? 'none' : `translateY(${theme.spacing(1)})`,
            transition: 'opacity 0.2s ease-out, transform 0.2s ease-out',
            [theme.breakpoints.up('sm')]: {
                bottom: theme.spacing(2),
                right: theme.spacing(2),
            },
        },
        title: {
            marginTop: theme.spacing(1),
            opacity: '0.60',
        },
        favorite: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: theme.spacing(5),
            height: theme.spacing(5),
            background: theme.palette.common.white,
            borderBottomRightRadius: 12,
            boxShadow: `0px 1px 2px #00000029`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: theme.palette.primary.main,
        },
        collection: {
            position: 'absolute',
            top: 0,
            right: 0,
            width: theme.spacing(5),
            height: theme.spacing(5),
            background: theme.palette.common.white,
            borderBottomLeftRadius: 12,
            boxShadow: `0px 1px 2px #00000029`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: theme.palette.primary.main,
        },
    };
});

export default useStyles;
