import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => {
    return {
        logo: {
            width: '75px',
            height: '75px',
            borderRadius: '100%',
            boxShadow: theme.shadows[1],
            marginBottom: theme.spacing(3),
            position: 'relative',
            overflow: 'hidden',
            background: 'white',
        },
        img: {
            transition: 'transform 0.2s ease-out',
        },
        link: {
            color: theme.palette.link,
            textDecoration: 'none',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            marginTop: 'auto',

            '&:hover': {
                textDecoration: 'underline',
            },
        },
        cardlink: {
            textDecoration: 'none',
            color: 'unset',
            width: '100%',
        },
        title: {
            textAlign: 'center',
            marginBottom: theme.spacing(3),

            '& h4': {
                marginBottom: '0',
            },
        },
        date: {
            textAlign: 'center',
            marginBottom: theme.spacing(3),
            color: theme.palette.primary.main,
        },
        card: {
            width: '100%',
            display: 'flex',
            padding: theme.spacing(3),
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',

            [theme.breakpoints.up('lg')]: {
                padding: '0',
                paddingBottom: theme.spacing(2),
            },

            [theme.breakpoints.up('sm')]: {
                paddingTop: theme.spacing(4),
            },

            '&:hover': {
                '& $img': {
                    transform: 'scale(1.1)',
                },
            },
        },
    };
});

export default useStyles;
