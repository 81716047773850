import { Fragment, useEffect } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { useDrawerContext } from '@prowise/react-mui';
import useMediaQuery from '@mui/material/useMediaQuery';
import Alert from '@mui/material/Alert';
import { useMatomo } from '@datapunt/matomo-tracker-react';

import LayoutDefault from '../../components/Layouts/Default/Default';
import useStyles from './EventsStyle';
import Title from '../../components/Atoms/Title/Title';
import Filters from '../../components/Molecules/Filters/Filters';
import EventsOverviewCard from '../../components/Molecules/EventsOverviewCard/EventsOverviewCard';

import { useSelector, useDispatch } from 'react-redux';
import { selectFilteredEvents, selectActiveEventsFilters, toggleFilter } from '../../redux/dataSlice';

const Events = () => {
    const dispatch = useDispatch();
    const data = useSelector(selectFilteredEvents);

    document.title = data.title;
    const { trackPageView } = useMatomo();
    useEffect(() => {
        trackPageView();
        // eslint-disable-next-line
    }, []);

    const classes = useStyles();
    const drawerState = useDrawerContext();
    const matchesWeirdBreakpoint = useMediaQuery('(max-width: 830px)');
    const matchesSmBreakpoint = useMediaQuery((theme) => theme.breakpoints.up('sm'));
    const horizontalFilters = useMediaQuery((theme) => theme.breakpoints.between('sm', 'lg'));
    const betweenMdLg = useMediaQuery((theme) => theme.breakpoints.between('md', 'xl'));
    const activeFilters = useSelector(selectActiveEventsFilters);

    const getColClasses = (index) => {
        const defaultClasses = {
            xs: 12,
            sm: 6,
            lg: 12,
        };

        if (drawerState.opened && matchesWeirdBreakpoint) {
            return {
                xs: 12,
                sm: 12,
                md: 12,
            };
        }
        if (!drawerState.opened && betweenMdLg)
            return {
                md: 4,
                lg: 12,
            };

        return defaultClasses;
    };

    const updateFilter = (data) => {
        dispatch(
            toggleFilter({
                ...data,
                ...{
                    type: 'events',
                },
            }),
        );
    };

    return (
        <LayoutDefault>
            <Container>
                <Grid container direction="column" className={classes.root}>
                    <Grid container item xs>
                        <div className={classes.title}>
                            <Title title="Events" size="h2" />
                        </div>
                    </Grid>
                    <Grid container item xs>
                        <Grid container spacing={4} direction="row" alignItems="flex-start">
                            <Grid xs={12} lg={3} item direction="row" container>
                                <div className={classes.filtersTitle}>
                                    <Title title={data.filterLabel} size="h4" />
                                </div>
                                <Grid item xs={12} lg={10} className={classes.filtersWrapper}>
                                    <Filters
                                        filterData={data.filters}
                                        updateFilter={updateFilter}
                                        activeFilters={activeFilters}
                                        horizontal={horizontalFilters}
                                    />
                                </Grid>
                            </Grid>
                            <Grid xs={12} lg={9} container item className={classes.eventsWrapper}>
                                <Grid container item direction="row" spacing={(!matchesSmBreakpoint && 2) || 3}>
                                    {!data.items.length ? (
                                        <Grid xs={12} item container direction="column">
                                            <Alert severity="info">{data.noFilterResults}</Alert>
                                        </Grid>
                                    ) : (
                                        <Fragment>
                                            {data.items.map((item, i) => (
                                                <Grid
                                                    key={`events-item-${item.id}-${i}`}
                                                    item
                                                    xs={12}
                                                    {...getColClasses(i)}
                                                >
                                                    <EventsOverviewCard {...item} buttonLabel={data.buttonLabel} />
                                                </Grid>
                                            ))}
                                        </Fragment>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </LayoutDefault>
    );
};

export default Events;
