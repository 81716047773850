import { useDispatch, useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import Title from '../../Atoms/Title/Title';
import DownloadCard from '../../Molecules/DownloadCard/DownloadCard';
import ButtonRound from '../../Atoms/ButtonRound/ButtonRound';
import Preloader from '../../Atoms/Preloader/Preloader';

import { selectDownloads } from '../../../redux/dataSlice';
import { selectAll, selectFeatured, setModalData } from '../../../redux/downloadsSlice';
import useStyles from './FeaturedSliderStyle';

const CustomArrow = ({ onClick, dir, disabled = false }) => {
    const classes = useStyles();
    return (
        <ButtonRound
            icon={dir === 'left' ? 'chevron_left' : 'chevron_right'}
            onClick={onClick}
            className={`${classes.button} ${classes.button}--${dir}`}
            disabled={disabled}
        />
    );
};

const ButtonGroup = ({ next, previous, ...rest }) => {
    const classes = useStyles();
    const {
        carouselState: { currentSlide, totalItems, slidesToShow },
    } = rest;
    const leftEnabled = currentSlide !== 0;
    const rightEnabled = currentSlide + slidesToShow < totalItems;
    return (
        <div className={classes.buttonGroup}>
            {leftEnabled && <CustomArrow dir="left" onClick={() => previous()} />}
            {rightEnabled && <CustomArrow dir="right" onClick={() => next()} />}
        </div>
    );
};

const FeaturedSlider = () => {
    const dispatch = useDispatch();
    const all = useSelector(selectAll);
    const downloads = useSelector(selectDownloads);
    const featured = useSelector(selectFeatured);
    const classes = useStyles();

    const onTileClick = (modalData) => {
        dispatch(setModalData(modalData));
    };

    return (
        <Grid container item direction="row" spacing={4}>
            <Grid item xs={12}>
                <div className={classes.wrapper}>
                    <Title title={downloads.featuredTitle || 'Featured'} size="h4" className={classes.title} />
                    {all.isIniting && <Preloader />}
                    {!all.isIniting && (
                        <Carousel
                            partialVisible={true}
                            responsive={{
                                desktopXL: {
                                    breakpoint: { min: 1200, max: Number.POSITIVE_INFINITY },
                                    items: 6,
                                    slidesToSlide: 6,
                                    partialVisibilityGutter: 12,
                                },
                                desktop: {
                                    breakpoint: { min: 1024, max: 1200 },
                                    items: 4,
                                    slidesToSlide: 4,
                                    partialVisibilityGutter: 12,
                                },
                                tabletXL: {
                                    breakpoint: { max: 1024, min: 800 },
                                    items: 3,
                                    slidesToSlide: 3,
                                    partialVisibilityGutter: 12,
                                },
                                tablet: {
                                    breakpoint: { max: 800, min: 400 },
                                    items: 2,
                                    slidesToSlide: 2,
                                    partialVisibilityGutter: 12,
                                },
                                mobile: {
                                    breakpoint: { max: 400, min: Number.NEGATIVE_INFINITY },
                                    items: 1,
                                    slidesToSlide: 1,
                                    partialVisibilityGutter: 12,
                                },
                            }}
                            itemClass={classes.card}
                            arrows={false}
                            renderButtonGroupOutside={true}
                            customButtonGroup={<ButtonGroup />}
                        >
                            {featured.map((item) => (
                                <DownloadCard
                                    {...item}
                                    onClick={onTileClick}
                                    key={`featured-download-card-${item.id}`}
                                />
                            ))}
                        </Carousel>
                    )}
                </div>
            </Grid>
        </Grid>
    );
};

export default FeaturedSlider;
