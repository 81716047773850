import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => {
    return {
        link: {
            textDecoration: 'none',
            color: 'unset',
        },
        card: {
            width: '100%',
            height: '100%',
            background: theme.palette.common.white,
            borderRadius: '12px',
            padding: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            [theme.breakpoints.up('lg')]: {
                padding: theme.spacing(4),
            },
            cursor: 'pointer',
        },
        imgWrapper: {
            position: 'relative',
            marginBottom: theme.spacing(3),
        },
        img: {
            position: 'relative',
            borderRadius: theme.shape.borderRadius * 2,
            overflow: 'hidden',
        },
        price: {
            position: 'absolute',
            top: '-10px',
            right: '-10px',
            width: '60px',
            height: '60px',
            background: theme.palette.tertiary.main,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '100%',
            textAlign: 'center',
            color: theme.palette.primary.main,
            fontSize: '12px',
            [theme.breakpoints.up('lg')]: {
                top: '-20px',
                right: '-20px',
            },
        },
        content: {
            marginBottom: theme.spacing(2),
            '& h6': {
                marginBottom: theme.spacing(0.5),
            },
        },
        button: {
            justifySelf: 'flex-end',
            marginTop: 'auto',
            position: 'relative',
            '& button ': {
                width: '100%',
                textAlign: 'left',
            },
            '& .MuiTouchRipple-root': {
                color: 'rgba(255,255,255,0.87)',
            },
            '& .MuiButton-label': {
                fontWeight: '500',
            },
        },
    };
});

export default useStyles;
