import { createAsyncThunk, createSlice, createSelector } from '@reduxjs/toolkit';
import memoize from 'lodash.memoize';
import API from '../api/api';
import { arrayContainsOneOrMultipleValues } from '../utilities/utilities';

export const getData = createAsyncThunk('/getData', async () => {
    const res = await API.get({
        endpoint: 'general',
    });
    return res.data;
});

export const submitShoppingCart = createAsyncThunk('/submitShoppingCart', async (data) => {
    const res = await API.post({
        endpoint: 'shop/order',
        data: data,
    });
    return res.data;
});

// NOTE: we also NEED to pass this same object structure to the "preloadedState" when initializing the store
export const initialDataState = {
    isLoadingData: false,
    hasError: false,
    data: {},
    general: {},
    filters: {
        news: [],
        events: [],
    },
    cart: [],
    isSubmittingCart: false,
};

export const dataSlice = createSlice({
    name: 'data',
    initialState: initialDataState,
    reducers: {
        toggleFilter: (state, action) => {
            const isAlreadyFilterd = state.filters[action.payload.type].includes(action.payload.id);
            if (!isAlreadyFilterd) {
                state.filters[action.payload.type].push(action.payload.id);
            } else {
                state.filters[action.payload.type] = state.filters[action.payload.type].filter(
                    (item) => item !== action.payload.id,
                );
            }
        },
        addToCart: (state, action) => {
            const variantIsAlreadyInCart =
                state.cart.filter((item) => item.variantId === action.payload.variantId).length > 0;
            if (!variantIsAlreadyInCart) {
                state.cart = [...state.cart, ...[action.payload]];
            } else {
                state.cart = state.cart.map((item) => {
                    if (item.variantId === action.payload.variantId) {
                        return {
                            ...item,
                            ...{
                                quantity: item.quantity + 1,
                            },
                        };
                    }
                    return item;
                });
            }
        },
        removeFromCart: (state, action) => {
            state.cart = state.cart.filter((item) => item.variantId !== action.payload.variantId);
        },
        updateCartItemQuantity: (state, action) => {
            state.cart = state.cart.map((item) => {
                if (item.variantId === action.payload.variantId)
                    return {
                        ...item,
                        ...{
                            quantity: action.payload.quantity,
                        },
                    };
                return item;
            });
        },
    },
    extraReducers: {
        // getData
        [getData.pending]: (state) => {
            state.isLoadingData = true;
            state.hasError = false;
        },
        [getData.rejected]: (state) => {
            state.isLoadingData = false;
            state.hasError = true;
        },
        [getData.fulfilled]: (state, action) => {
            // action.payload.data.editorials.items[1].link1.href = '#cookie-settings';
            // action.payload.data.editorials.items[1].link1.type = 'cookiesettings';
            // action.payload.data.editorials.items[1].link1.target = '_self';
            state.data = action.payload.data;
            state.general = action.payload.general;
            state.hasError = false;
            state.isLoadingData = false;
        },

        // submitShoppingCart
        [submitShoppingCart.pending]: (state) => {
            state.isSubmittingCart = true;
        },
        [submitShoppingCart.rejected]: (state) => {
            state.isSubmittingCart = false;
        },
        [submitShoppingCart.fulfilled]: (state, action) => {
            state.isSubmittingCart = false;
            if (action.payload.success) state.cart = [];
        },
    },
});

// General selectors
// ****************************************************
export const selectGeneral = (state) => state.data.general;

// News selectors
// ****************************************************
export const selectNews = (state) => state.data.data.news;
export const selectNewsItemBySimpleId = createSelector(selectNews, (news) =>
    memoize((simpleId) => {
        const res = news.items.filter((item) => item.simpleId === simpleId);
        return res.length ? res[0] : null;
    }),
);
export const selectFilteredNews = (state) => {
    if (!state.data.filters.news.length) return state.data.data.news;
    return {
        ...state.data.data.news,
        ...{
            items: state.data.data.news.items.filter((item) =>
                arrayContainsOneOrMultipleValues(item.filters, state.data.filters.news),
            ),
        },
    };
};
export const selectActiveNewsFilters = (state) => state.data.filters.news;

// Events selectors
// ****************************************************
export const selectEvents = (state) => state.data.data.events;
export const selectEventItemBySimpleId = createSelector(selectEvents, (events) =>
    memoize((simpleId) => {
        const res = events.items.filter((item) => item.simpleId === simpleId);
        return res.length ? res[0] : null;
    }),
);
export const selectFilteredEvents = (state) => {
    if (!state.data.filters.events.length) return state.data.data.events;
    return {
        ...state.data.data.events,
        ...{
            items: state.data.data.events.items.filter((item) =>
                arrayContainsOneOrMultipleValues(item.filters, state.data.filters.events),
            ),
        },
    };
};
export const selectActiveEventsFilters = (state) => state.data.filters.events;

// Shop selectors
// ****************************************************
export const selectShop = (state) => state.data.data.shop;
export const selectCartItems = (state) => {
    const formattedCart = state.data.cart
        .map((crtItm) => {
            const item = state.data.data.shop.overview.items.filter((item) => item.id === crtItm.id);
            if (item.length) {
                const variant = item[0].variants.filter((v) => v.id === crtItm.variantId);
                if (variant.length) {
                    return {
                        id: item[0].id,
                        variantId: variant[0].id,
                        title: item[0].title,
                        description: variant[0].description,
                        price: variant[0].price * crtItm.quantity,
                        quantity: crtItm.quantity,
                        img: item[0].img,
                    };
                }
            }
            return {};
        })
        .filter((ci) => Object.keys(ci).length);
    return formattedCart;
};
export const selectTotalCartPrice = (state) => {
    const totalPrice = selectCartItems(state).reduce(
        (accumulator, currentValue) => accumulator + currentValue.price,
        0,
    );
    return totalPrice;
};
export const selectTotalCartCount = (state) =>
    selectCartItems(state).reduce((accumulator, currentValue) => accumulator + currentValue.quantity, 0);
export const selectIsSubmittingCart = (state) => state.data.isSubmittingCart;

// Editorials selectors
// ****************************************************
export const selectEditorial = (state) => state.data.data.editorials;

// Dashboard selectors
// ****************************************************
export const selectDashboard = (state) => {
    return {
        news: {
            ...state.data.data.news,
            ...{
                items: state.data.data.news.items.filter((_, i) => i < 3),
            },
        },
        events: {
            ...state.data.data.events,
            ...{
                items: state.data.data.events.items.filter((_, i) => i < 3),
            },
        },
        editorials: {
            ...state.data.data.editorials,
            ...{
                items: state.data.data.editorials.items.filter((_, i) => i < 4),
            },
        },
        dashboard: state.data.data.dashboard,
    };
};

export const selectDownloads = (state) => state.data.data.downloads;

export default dataSlice.reducer;
export const { toggleFilter, addToCart, removeFromCart, updateCartItemQuantity } = dataSlice.actions;
