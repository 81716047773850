const flatten = (items) => {
    const flat = [];
    items.forEach((item) => {
        flat.push(item);
        if (item.sub && item.sub.length) {
            flat.push(...flatten(item.sub));
        }
    });
    return flat;
};
function nestSubFilters(arr, parent) {
    var out = [];
    for (let i in arr) {
        if (arr[i].parent === parent) {
            arr[i].sub = nestSubFilters(arr, arr[i].id);
            out.push(arr[i]);
        }
    }
    return out;
}

export { flatten, nestSubFilters };
