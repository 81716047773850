import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            width: '100%',
            '& .infinite-scroll-component__outerdiv': {
                width: '100%',
                overflow: 'hidden',
            },
        },
        table: {
            width: '100%',
            background: theme.palette.common.white,
            borderRadius: '12px',
        },
        tableItem: {
            '&:last-child': {
                '& div': {
                    borderBottom: 'none',
                },
            },
        },
    };
});

export default useStyles;
