import { createAuthManager } from '@prowise/react-auth';
import config from './utilities/config';

const manager = {
    manager: null,
    init: function () {
        this.manager = createAuthManager(config.ssoAuthority, config.ssoClientId, {
            scope: 'openid profile email apps technical sales',
        });
    },
    getManager: function () {
        return this.manager;
    },
};
export default manager;
