import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DownloadPopUp from '../../Molecules/DownloadPopUp/DownloadPopUp';
import CustomDialog from '../../Molecules/CustomDialog/CustomDialog';

import { selectModalData, setModalData } from '../../../redux/downloadsSlice';

const DownloadsModal = () => {
    const dispatch = useDispatch();
    const modalData = useSelector(selectModalData);
    const handleClose = () => {
        dispatch(
            setModalData({
                modalData: {},
            }),
        );
    };
    return (
        <CustomDialog
            open={Object.keys(modalData).length > 0}
            handleClose={handleClose}
            amountOfColumns={7}
            closeButton={true}
        >
            <DownloadPopUp {...modalData} />
        </CustomDialog>
    );
};

export default DownloadsModal;
