import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import useStyles from './EventCardStyle';
import Title from '../../Atoms/Title/Title';
import Img from '../../Atoms/Img/Img';

const EventCard = ({ title, logo, simpleId, date, buttonLabel }) => {
    const classes = useStyles();
    const dateSplit = date.split(' - ');
    return (
        <Link to={`/events/${simpleId}/`} className={classes.cardlink}>
            <div className={classes.card}>
                <div className={classes.logo}>
                    <Img {...logo} alt={title} className={classes.img} />
                </div>
                <div className={classes.title}>
                    <Title title={title} size="h4" />
                </div>
                <div className={classes.date}>
                    {dateSplit[0]}{' '}
                    {dateSplit.length === 2 && (
                        <Fragment>
                            <br /> - {dateSplit[1]}
                        </Fragment>
                    )}
                </div>
                <div className={classes.link}>{buttonLabel}</div>
            </div>
        </Link>
    );
};

EventCard.propTypes = {
    date: PropTypes.string,
    title: PropTypes.string,
    link: PropTypes.string,
    img: PropTypes.object,
    buttonLabel: PropTypes.string,
};

EventCard.defaultProps = {
    date: '',
    img: {},
    title: '',
    link: '',
    buttonLabel: '',
};

export default EventCard;
