import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { useMatomo } from '@datapunt/matomo-tracker-react';

import LayoutDefault from '../../components/Layouts/Default/Default';
import Title from '../../components/Atoms/Title/Title';
import FeaturedSlider from '../../components/Organisms/FeaturedSlider/FeaturedSlider';
import FilterCategories from '../../components/Organisms/FilterCategories/FilterCategories';
import DownloadsModal from '../../components/Organisms/DownloadsModal/DownloadsModal';

import useStyles from './DownloadsStyle';

import { selectDownloads } from '../../redux/dataSlice';
import { selectAll, getInitData } from '../../redux/downloadsSlice';

const Downloads = () => {
    const dispatch = useDispatch();
    const data = useSelector(selectDownloads);
    const all = useSelector(selectAll);
    const classes = useStyles();
    document.title = data.title;
    const { trackPageView } = useMatomo();

    // load data
    const getData = useCallback(() => {
        if (!all.isInited) dispatch(getInitData());
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        getData();
        trackPageView();
        return () => {};
        // eslint-disable-next-line
    }, []);

    return (
        <LayoutDefault>
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={12} className={classes.title}>
                        <Title title={data.title} size="h2" />
                    </Grid>
                </Grid>
                <FeaturedSlider />
                <FilterCategories />
                <DownloadsModal />
            </Container>
        </LayoutDefault>
    );
};
export default Downloads;
