import PropTypes from 'prop-types';

import useStyles from './VideoPlayerStyle';

const VideoPlayer = ({ videoId, videoPlatform }) => {
    const classes = useStyles();

    return (
        <div className={classes.videoplayer}>
            <div className={classes.aspect}>
                {videoPlatform === 'youtube' ? (
                    <iframe
                        src={`https://www.youtube.com/embed/${videoId}`}
                        title={`https://www.youtube.com/embed/${videoId}`}
                        allow="accelerometer; autoplay; fullscreen; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        className={classes.iframe}
                    ></iframe>
                ) : (
                    <iframe
                        src={`https://player.vimeo.com/video/${videoId}`}
                        title={`https://player.vimeo.com/video/${videoId}`}
                        width="640"
                        height="360"
                        allow="autoplay; fullscreen; picture-in-picture"
                        className={classes.iframe}
                    />
                )}
            </div>
        </div>
    );
};

VideoPlayer.propTypes = {
    videoId: PropTypes.string,
};

VideoPlayer.defaultProps = {
    videoId: '0JWvvtgUDJo',
};

export default VideoPlayer;
