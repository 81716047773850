import PropTypes from 'prop-types';
import React from 'react';
import useStyles from './TextStyle';
import parseHTML from '../../../utilities/htmlParser';

const Text = ({ text, className, htmlTag, inverted }) => {
    const classes = useStyles();
    const Tag = htmlTag;
    return <Tag className={`${classes.text} ${className} ${inverted ? classes.textWhite : ''}`}>{parseHTML(text)}</Tag>;
};

Text.propTypes = {
    className: PropTypes.string,
    text: PropTypes.string,
    htmlTag: PropTypes.string,
    inverted: PropTypes.bool,
};

Text.defaultProps = {
    className: '',
    text: '',
    htmlTag: 'div',
    inverted: false,
};

export default Text;
