import PropTypes from 'prop-types';
import useStyles from './ChipStyle';

const Chip = ({ label, className }) => {
    const classes = useStyles();
    return <span className={`${classes.chip} ${className}`}>{label}</span>;
};

Chip.propTypes = {
    label: PropTypes.string.isRequired,
    className: PropTypes.string,
};

Chip.defaultProps = {
    label: '',
    className: '',
};

export default Chip;
