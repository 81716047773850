import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => {
    return {
        title: {
            marginBottom: theme.spacing(4),
            [theme.breakpoints.up('lg')]: {
                marginBottom: theme.spacing(4),
            },
            '& h2': {
                marginBottom: '0',
            },
        },
    };
});

export default useStyles;
