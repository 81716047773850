import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => {
    return {
        drawerRoot: {
            '& .MuiPaper-root': {
                [theme.breakpoints.up('sm')]: {
                    height: 'calc(100% - 112px)',
                    top: '112px',
                },
            },
            '& .MuiDrawer-paper': {
                overflowY: 'unset',
                boxShadow: 'none !important',
                [theme.breakpoints.up('sm')]: {
                    '&:before': {
                        content: '""',
                        position: 'absolute',
                        width: theme.spacing(1),
                        height: '100%',
                        left: 0,
                        top: 0,
                        boxShadow: 'inset 6px 0 6px -3px #0000003d',
                    },
                },
            },
        },
        innerWrapper: {
            background: theme.palette.secondary.colors[200],
            height: '100%',
            maxHeight: '100vh',
            width: '100vw',
            display: 'flex',
            flexDirection: 'column',
            [theme.breakpoints.up('sm')]: {
                maxWidth: 334,
            },
        },
        header: {
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            overflow: 'visible',
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4),
            paddingTop: theme.spacing(1.5),
            paddingBottom: theme.spacing(2),
            boxShadow: theme.shadows[1],
            [theme.breakpoints.up('sm')]: {
                boxShadow: 'unset',
            },
            [theme.breakpoints.up('lg')]: {
                paddingTop: theme.spacing(2),
            },
            '& h4': {
                marginBottom: '0',
            },
        },
        content: {
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4),
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(2),
            maxHeight: '100%',
            overflow: 'scroll',
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': {
                display: 'none',
            },
        },
        footer: {
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4),
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            marginTop: 'auto',
            justifySelf: 'flex-end',
            display: 'flex',
            justifyContent: 'center',
            boxShadow: theme.shadows[1],
            [theme.breakpoints.up('sm')]: {
                display: 'none',
            },
        },
        closeButton: {
            width: '24px',
            height: '24px',
            borderRadius: '100%',
            background: theme.palette.common.white,
            boxShadow: theme.shadows[2],
            // display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '4px',
            cursor: 'pointer',
            color: theme.palette.primary.main,
            [theme.breakpoints.up('sm')]: {
                position: 'absolute',
                left: '-12px',
                top: '46%',
                transform: 'translateY(-50%)',
            },
        },
        toggleIcon: {
            fontSize: '16px !important',
        },
        title: {
            color: theme.palette.secondary.colors[900],
        },
    };
});

export default useStyles;
