import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => {
    return {
        content: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',

            [theme.breakpoints.up('sm')]: {
                // maxWidth: '50%',
                marginRight: theme.spacing(3),
            },
            [theme.breakpoints.up('lg')]: {
                maxWidth: 'unset',
            },
        },
        title: {
            marginBottom: theme.spacing(3),
            '& h4': {
                marginBottom: '0',
            },
        },
        text: {
            color: theme.palette.gray.dark,
        },
        buttons: {
            display: 'flex',
            marginTop: 'auto',
            justifySelf: 'flex-end',
            flexWrap: 'wrap',
            paddingTop: theme.spacing(3),
        },
        button: {
            minWidth: 'max-content',
            marginRight: theme.spacing(1),
            marginTop: theme.spacing(1),
            '&:last-child': {
                marginRight: '0',
            },
        },
        imgWrapper: {
            width: '100%',
            marginBottom: theme.spacing(3),

            [theme.breakpoints.up('sm')]: {
                marginBottom: '0',
            },

            [theme.breakpoints.up('md')]: {
                maxWidth: '44.444%',
            },
        },
        imgWrappDrawer: {
            marginBottom: theme.spacing(3),

            [theme.breakpoints.up('md')]: {
                marginBottom: '0',
            },
        },
        imgWrapp: {
            borderRadius: theme.spacing(1),
            overflow: 'hidden',
            width: '100%',
        },
        img: {
            transition: 'transform 0.2s ease-out',
        },
        card: {
            width: '100%',
            padding: theme.spacing(2),
            // paddingBottom: theme.spacing(1),
            borderRadius: '12px',
            display: 'flex',
            flexDirection: 'column-reverse',
            background: theme.palette.common.white,
            height: '100%',
            [theme.breakpoints.up('sm')]: {
                flexDirection: 'row',
            },
            [theme.breakpoints.up('lg')]: {
                padding: theme.spacing(4),
            },
            '&:hover': {
                '& $img': {
                    transform: 'scale(1.05)',
                },
            },
        },
        cardColumn: {
            flexDirection: 'column-reverse !important',
            [theme.breakpoints.up('md')]: {
                flexDirection: 'row !important',
            },
        },
    };
});

export default useStyles;
