import makeStyles from '@mui/styles/makeStyles';
import { alpha } from '@mui/material';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            '& .c_cookiewall': {
                '&__bar-wrapper, &__modal-wrapper': {
                    opacity: 1,
                    visibility: 'visible',
                    pointerEvents: 'auto',
                    transition: `opacity 0.4s ease, visibility 0.4s ease`,
                    '&.s_hidden': {
                        opacity: 0,
                        visibility: 'hidden',
                        pointerEvents: 'none',
                    },
                },
                '&__bar, &__modal': {
                    '&-text': {
                        position: 'relative',
                        fontSize: '14px',
                        lineHeight: '18px',
                        fontWeight: 200,
                        color: theme.palette.text.primary,
                        marginBottom: theme.spacing(2),
                    },
                },
                '&__bar-wrapper': {
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    background: theme.palette.tertiary.colors[500],
                    padding: `${theme.spacing(4)} 0`,
                    zIndex: 99999,
                    boxShadow: `0 -4px 4px rgba(0, 0, 0, 0.1)`,
                    [theme.breakpoints.up('lg')]: {
                        padding: `${theme.spacing(5)} 0`,
                    },
                },
                '&__bar': {
                    maxWidth: 720,
                    padding: `0px 15px`,
                    margin: `0 auto`,

                    '&-buttons': {
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: theme.spacing(2),
                    },
                },
                '&__title': {
                    display: 'block',
                    fontFamily: 'Ubuntu, sans-serif',
                    fontSize: '38px',
                    lineHeight: '42px',
                    fontWeight: 400,
                    color: theme.palette.primary.main,
                    marginBottom: theme.spacing(1),
                },
                '&__button': {
                    display: 'inline-flex',
                    alignItems: 'center',
                    textDecoration: 'none',
                    fontFamily: 'Ubuntu, sans-serif',
                    fontSize: '18px',
                    fontWeight: 400,
                    boxShadow: '0 1px 2px rgba(#000000, 0.16)',
                    transition: 'all 0.3s ease',
                    lineHeight: '30px',
                    minHeight: '44px',
                    borderRadius: theme.spacing(1),
                    padding: '6px 14px', // 2px border!

                    // base styling (full-primary)
                    border: `1px solid ${theme.palette.primary.main}`,
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.common.white,
                    cursor: 'pointer',

                    '& svg': {
                        marginLeft: '3px',
                        opacity: 0.6,
                        transition: 'all 0.3s ease',

                        '& path': {
                            fill: 'currentColor',
                        },
                    },

                    '&:hover': {
                        outline: 'none',
                        boxShadow: `0 0 4px 0 ${theme.palette.primary.main}`,
                        backgroundColor: theme.palette.primary.colors[400],
                        color: theme.palette.common.white,
                        '& svg': {
                            transform: 'translateX(4px)',
                        },
                    },

                    '&--ghost-primary': {
                        background: 'transparent',
                        border: `1px solid ${theme.palette.primary.main}`,
                        color: theme.palette.primary.main,

                        '&:hover': {
                            backgroundColor: theme.palette.primary.colors[100],
                            color: theme.palette.primary.main,
                            boxShadow: 'none',
                        },
                    },
                },
                '&__modal-wrapper': {
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    zIndex: 999999,
                    background: `linear-gradient(180deg, ${theme.palette.primary.main} 0%, ${alpha(
                        theme.palette.primary.main,
                        0.8,
                    )} 100%)`,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    overflow: 'auto',
                    padding: `${theme.spacing(3)} 0`,
                    [theme.breakpoints.down('sm')]: {
                        padding: `${theme.spacing(6)} 0`,
                    },
                },

                '&__modal': {
                    position: 'relative',
                    margin: 'auto',
                    width: '100%',
                    maxWidth: '400px',
                    background: theme.palette.tertiary.colors[400],
                    padding: `${theme.spacing(3)} ${theme.spacing(4)}`,
                    zIndex: 1,
                    '&-close': {
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        transform: `translate(${theme.spacing(4)}, 0)`,
                        opacity: 0.5,
                        [theme.breakpoints.down('sm')]: {
                            transform: `translate(0, -${theme.spacing(4)})`,
                        },
                    },
                    '&-rules': {
                        margin: `${theme.spacing(3)} 0`,
                    },
                    '&-rule, &-text': {
                        color: theme.palette.text.primary,
                        marginBottom: theme.spacing(3),
                    },
                    '&-checkbox': {
                        fontSize: '14px',
                        fontWeight: 500,
                    },
                    '&-info-text': {
                        paddingLeft: theme.spacing(4),
                        fontSize: '14px',
                        lineHeight: '18px',
                        fontWeight: 200,
                    },
                    '&-buttons': {
                        display: 'flex',
                        justifyContent: 'flex-end',
                    },
                },
            },

            '& .c_checkbox': {
                position: 'relative',
                width: '100%',
                margin: 0,
                padding: 0,
                border: 0,
                fontSize: '100%',
                font: 'inherit',
                verticalAlign: 'baseline',

                '&__label': {
                    color: theme.palette.text.primary,
                    display: 'flex',
                    alignItems: 'flex-start',
                    cursor: 'pointer',
                },
                '&__check-custom': {
                    position: 'absolute',
                    left: '-10000px',
                    top: 'auto',
                    width: '1px',
                    height: '1px',
                    overflow: 'hidden',
                },
                '&__check-custom ~ .c_checkbox__check-toggle': {
                    width: '18px',
                    height: '18px',
                    flex: '0 0 18px',
                    position: 'relative',
                    display: 'inline-block',
                    verticalAlign: 'middle',
                    backgroundColor: theme.palette.common.white,
                    cursor: 'pointer',
                    marginRight: '16px',
                    border: `2px solid ${theme.palette.tertiary.colors[700]}`,
                    transition: `all 0.2s ease`,
                    marginTop: '2px',
                    borderRadius: '2px',

                    '& svg': {
                        '& path': {
                            fill: theme.palette.common.white,
                        },
                    },
                },
                '&__check-inner-label': {
                    lineHeight: '20px',
                },

                '&__check-custom:disabled ~ .c_checkbox__check-toggle': {
                    opacity: 0.5,
                    cursor: 'not-allowed',
                },
                '&__check-custom:checked': {
                    '& ~ .c_checkbox__check-toggle': {
                        backgroundColor: theme.palette.secondary.main,
                        borderColor: 'transparent',
                        '& .c_checkbox__check-icon': {
                            opacity: 1,
                        },
                    },
                },
            },

            '& .c_circle-button': {
                display: 'block',
                position: 'relative',
                width: theme.spacing(3),
                height: theme.spacing(3),
                padding: 0,
                borderRadius: theme.spacing(3),
                border: 'none',
                textAlign: 'center',
                cursor: 'pointer',

                background: theme.palette.tertiary.colors[400], // dbebf4
                color: theme.palette.primary.colors[400], // 476389

                '&__icon': {
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                    transform: `translate(-50%, -50%)`,
                    width: '18px',

                    '& > svg': {
                        width: '100%',
                        '& path': {
                            fill: 'currentColor',
                        },
                    },
                },
            },
        },
    };
});

export default useStyles;
