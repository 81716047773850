import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => {
    return {
        top: {
            marginBottom: theme.spacing(3),
        },
        title: { marginBottom: theme.spacing(1) },
        popover: {
            '& .MuiPopover-paper': {
                right: '16px',
                bottom: '96px',
                top: 'unset !important',
                left: 'unset !important',
                maxWidth: '334px',
                paddingLeft: '16px',
                width: '100%',
                background: 'unset',
                boxShadow: 'none',
                display: 'flex',
                justifyContent: 'space-between',
                overflow: 'visible',

                [theme.breakpoints.up('sm')]: {
                    bottom: '120px',
                    right: '8px',
                },
            },
        },
        popoverClose: {
            width: '24px',
            height: '24px',
            borderRadius: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'rgba(255,255,255,0.5)',
            padding: '3px',
            cursor: 'pointer',
            transition: 'background 0.2s ease-out, color 0.2s ease-out',
            color: theme.palette.primary.main,

            '& span': {
                fontSize: '18px !important',
            },

            '&:hover': {
                background: theme.palette.primary.main,
                color: theme.palette.common.white,
            },
        },
        text: {
            color: theme.palette.gray.dark,
        },
    };
});

export default useStyles;
