import PropTypes from 'prop-types';
import { Icon } from '@mui/material';
import useStyles from './IconWithTagStyle';

const IconWithTag = ({ icon, tag, href, tags, variant }) => {
    const classes = useStyles();
    return (
        <div className={classes.IconWithTagWrapper}>
            <div className={classes.IconWithTagIcon}>
                <Icon className={`${classes.icon} material-icons-outlined`}>{icon}</Icon>
            </div>
            <div className={`${classes.IconWithTagTag} ${classes.IconWithTagTag}--${variant}`}>
                {href !== '' && (
                    <a href={href} target="_blank" rel="noopener noreferrer">
                        {tag}
                    </a>
                )}
                {href === '' && tag !== '' && tag}
                {tags.length >= 1 &&
                    tags.map((item, i) => (
                        <span key={`tags-item-${i}-${item}`}>{(i !== tags.length - 1 && item + ', ') || item}</span>
                    ))}
            </div>
        </div>
    );
};

IconWithTag.propTypes = {
    icon: PropTypes.string,
    tag: PropTypes.string,
    tags: PropTypes.array,
    href: PropTypes.string,
    variant: PropTypes.string,
};

IconWithTag.defaultProps = {
    tag: '',
    tags: [],
    icon: '',
    href: '',
    variant: 'default',
};

export default IconWithTag;
