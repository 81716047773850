import React, { useRef } from 'react';
import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { ShoppingCartOutlined } from '@mui/icons-material';
import { NavLink } from 'react-router-dom';

import useStyles from './ActionButtonStyle';

import Fab from '../../Atoms/Fab/Fab';

const ActionButton = ({ count, to, icon, onClick, classList }) => {
    const buttonRef = useRef(null);
    const classes = useStyles();

    return (
        <Fragment>
            {(to !== '' && (
                <NavLink className={`${classes.ActionButton} ${classList}`} to={to}>
                    <div className={classes.button} ref={buttonRef}>
                        <Fab color="secondary">{icon}</Fab>
                        {count > 0 && <div className={classes.counter}>{count}</div>}
                    </div>
                </NavLink>
            )) || (
                <div className={`${classes.ActionButton} ${classList}`} onClick={onClick}>
                    <div className={classes.button} ref={buttonRef}>
                        <Fab color="secondary">{icon}</Fab>
                        {count > 0 && <div className={classes.counter}>{count}</div>}
                    </div>
                </div>
            )}
        </Fragment>
    );
};

ActionButton.propTypes = {
    count: PropTypes.number,
    to: PropTypes.string,
    classList: PropTypes.string,
    icon: PropTypes.element,
};

ActionButton.defaultProps = {
    count: 0,
    to: '',
    classList: '',
    icon: <ShoppingCartOutlined />,
};

export default ActionButton;
