import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import CircularProgress from '@mui/material/CircularProgress';
import ErrorIcon from '@mui/icons-material/Error';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'fixed',
        display: 'flex',
        height: '100vh',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    center: {
        padding: theme.spacing(2),
    },
    status: {
        display: 'block',
        textAlign: 'center',
    },
    text: {
        display: 'block',
        textAlign: 'center',
        color: theme.palette.primary.main,
        marginTop: theme.spacing(2),
    },
}));

const StatusScreen = ({ type, text }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <span className={classes.center}>
                <span className={classes.status}>
                    {type === 'error' ? <ErrorIcon color="secondary" /> : <CircularProgress />}
                </span>
                {text !== '' && <span className={classes.text}>{text}</span>}
            </span>
        </div>
    );
};

export default StatusScreen;

StatusScreen.propTypes = {
    type: PropTypes.oneOf(['loading', 'error']),
    text: PropTypes.string,
};

StatusScreen.defaultProps = {
    type: 'loading',
    text: '',
};
