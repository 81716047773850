import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => {
    return {
        button: {
            display: 'flex',
            width: '100%',
            maxWidth: 'max-content',
            height: 36,
            alignItems: 'center',
            color: theme.palette.secondary.colors[900],
            fontWeight: 'bold',
            cursor: 'pointer',
            fontSize: theme.typography.fontSize,
            [theme.breakpoints.up('md')]: {
                height: theme.spacing(5),
            },

            [theme.breakpoints.up('lg')]: {
                display: 'none',
            },
        },
        icon: {
            marginRight: theme.spacing(1),
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            fontSize: '24px',
        },
    };
});

export default useStyles;
