import React from 'react';
import PropTypes from 'prop-types';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box } from '@mui/material';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import StarOutlinedIcon from '@mui/icons-material/StarOutlined';

import DownloadButton from '../../Atoms/DownloadButton/DownloadButton';
import DownloadIcon from '../../Atoms/DownloadIcon/DownloadIcon';
import Img from '../../Atoms/Img/Img';
import Text from '../../Atoms/Text/Text';

import useStyles from './DownloadListCardStyle';

const DownloadListCard = ({ id, types, title, img, featured, type, drawerIsOpen, onClick, icon }) => {
    const classes = useStyles();
    const matchesBreakpoint = useMediaQuery('(max-width: 800px)');
    const handleOnClick = () => {
        onClick({
            featured,
            id,
        });
    };

    return (
        <Box className={classes.card} onClick={handleOnClick}>
            <Box className={classes.wrapper}>
                <Box className={classes.imgIconWrapper}>
                    {img.src === '' ? <DownloadIcon icon={icon} /> : <Img {...img} objectFit={true} />}
                </Box>
                <Box className={`${classes.content} ${drawerIsOpen && matchesBreakpoint ? classes.contentSmall : ''}`}>
                    <Text text={title} className={classes.title} htmlTag={'span'} />
                    <Box className={classes.details}>
                        <Box
                            className={`${classes.detail} ${
                                drawerIsOpen && matchesBreakpoint ? classes.detailSmall : ''
                            }`}
                        >
                            {[...new Set(types)].join(', ')}
                        </Box>
                    </Box>
                </Box>
                <Box className={classes.actions}>
                    {type === 'collection' && <FolderOutlinedIcon className={classes.icons} />}
                    {featured && <StarOutlinedIcon className={classes.icons} />}
                    <DownloadButton className={classes.button} />
                </Box>
            </Box>
        </Box>
    );
};

DownloadListCard.propTypes = {
    id: PropTypes.string,
    isLoading: PropTypes.bool,
    title: PropTypes.string,
    img: PropTypes.object,
    types: PropTypes.array,
    variants: PropTypes.array,
};

DownloadListCard.defaultProps = {
    id: '',
    isLoading: false,
    title: '',
    img: {},
    types: [],
    variants: [],
};

export default DownloadListCard;
