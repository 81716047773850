import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            padding: theme.spacing(1),
            '&.Mui-disabled': {
                opacity: 0.5,
            },
        },
        span: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: theme.palette.secondary.main,
            width: theme.spacing(3),
            height: theme.spacing(3),
            margin: 0,
            borderRadius: '100%',
            color: theme.palette.common.white,
            fontSize: 16,
        },
        secondary: {
            background: theme.palette.secondary.main,
        },
        primary: {
            background: theme.palette.primary.main,
        },
        icon: {
            position: 'relative',
            fontSize: `16px !important`,
            color: theme.palette.common.white,
            zIndex: 2,
        },
    };
});

export default useStyles;
