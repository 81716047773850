import { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { useAuth } from '@prowise/react-auth';
import { useSelector, useDispatch } from 'react-redux';

import { getData } from '../redux/dataSlice';

import StatusScreen from '../components/Organisms/StatusScreen/StatusScreen';

const _TRANSLATIONS = {
    authChecking: 'Checking session status...',
    redirecting: 'Redirecting to login...',
    loading: 'Loading application data...',
    error: 'Error loading data. Reload window or contact Prowise.',
};

const ProtectedRoute = (props) => {
    const dispatch = useDispatch();
    const auth = useAuth();
    const data = useSelector((state) => state.data);
    const hasData = Object.keys(data.data).length > 0;

    // load app data on route load (if we are logged in and we have no data yet)
    useEffect(() => {
        if (!auth.isChecking && auth.isAuthenticated && auth.state === 'LOGGED_IN' && !hasData) {
            dispatch(getData());
        }
        // eslint-disable-next-line
    }, [auth]);

    if (auth.isChecking) return <StatusScreen type="loading" text={_TRANSLATIONS.authChecking} />;
    if (!auth.isAuthenticated) {
        auth.login();
        return <StatusScreen type="loading" text={_TRANSLATIONS.redirecting} />;
    }
    if (data.hasError) return <StatusScreen type="error" text={_TRANSLATIONS.error} />;
    if (data.isLoadingData || !hasData) return <StatusScreen type="loading" text={_TRANSLATIONS.loading} />;
    return <Route {...props} />;
};

export default ProtectedRoute;
