import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

const Preloader = () => {
    return (
        <Box component="span" display="flex" justifyContent="center" style={{ width: '100%' }} p={2}>
            <CircularProgress color="secondary" />
        </Box>
    );
};

export default Preloader;
