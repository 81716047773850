import PropTypes from 'prop-types';

import Button from '../../Atoms/Button/Button';
import { Icon } from '@mui/material';
import { atcb_action } from 'add-to-calendar-button';
import 'add-to-calendar-button/assets/css/atcb.css';
import './style.css';

const createEvent = (data) => {
    const eventData = {
        ...{
            options: ['Apple', 'Google', 'iCal', 'Microsoft365', 'MicrosoftTeams', 'Outlook.com', 'Yahoo'],
        },
        ...data,
    };
    return eventData;
};

const AddToCalendar = ({ data, label }) => {
    const onClick = (event) => {
        event.preventDefault();
        atcb_action(createEvent(data));
    };
    return <Button onClick={onClick} startIcon={<Icon>calendar_today</Icon>} label={label} />;
};
AddToCalendar.propTypes = {
    data: PropTypes.object,
    label: PropTypes.string,
};

AddToCalendar.defaultProps = {
    data: {},
    label: 'Add to calendar',
};

export default AddToCalendar;
