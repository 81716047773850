import React, { useCallback, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useDrawerContext } from '@prowise/react-mui';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Icon } from '@mui/material';

import Button from '../../Atoms/Button/Button';
import FilterCategoryCard from '../../Molecules/FilterCategoryCard/FilterCategoryCard';
import Title from '../../Atoms/Title/Title';
import Preloader from '../../Atoms/Preloader/Preloader';

import useStyles from './FilterCategoriesStyle';

import { selectDownloads } from '../../../redux/dataSlice';
import {
    selectAll,
    selectFilters,
    selectFormattedFilters,
    getInitData,
    getDownloadItemsAsync,
    updateFilter,
    resetAllFilters,
} from '../../../redux/downloadsSlice';

const _OVERVIEW_URL = '/downloads/overview/';

const FilterCategories = () => {
    const dispatch = useDispatch();
    const drawerState = useDrawerContext();
    const all = useSelector(selectAll);
    const downloads = useSelector(selectDownloads);
    const history = useHistory();
    const filters = useSelector(selectFilters);
    const formattedFilters = useSelector(selectFormattedFilters);

    const drawerIsOpen = drawerState.opened;

    const classes = useStyles();

    // load data
    const getData = useCallback(() => {
        if (!all.isInited) dispatch(getInitData());
        // eslint-disable-next-line
    }, [filters]);
    useEffect(() => {
        getData();
        return () => {};
        // eslint-disable-next-line
    }, []);

    const handleUpdateFilter = async (data) => {
        data.checked = true;
        dispatch(resetAllFilters());
        await dispatch(updateFilter(data));
        dispatch(getDownloadItemsAsync()); // do NOT await this, we can move to the overview page while loading the download items :)
        history.push(_OVERVIEW_URL);
    };

    const handleOnButtonClick = async () => {
        dispatch(resetAllFilters());
        history.push(_OVERVIEW_URL);
    };

    const categoryColumns = () => {
        if (drawerIsOpen) {
            return {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 3,
            };
        }
        return {
            xs: 12,
            sm: 6,
            md: 4,
            lg: 3,
        };
    };
    return (
        <Fragment>
            <Grid container item xs={12} className={classes.container}>
                <Grid container item direction="row" spacing={4}>
                    <Grid item xs={12}>
                        <Box className={classes.categoryBox}>
                            <Title
                                title={downloads.categoriesTitle || 'Categories'}
                                size="h4"
                                className={classes.categoryTitle}
                            />
                            <Button
                                label={downloads.categoriesButtonLabel || 'View all'}
                                color="primary"
                                variant="outlined"
                                endIcon={<Icon>east</Icon>}
                                className={classes.categoryButton}
                                onClick={handleOnButtonClick}
                            />
                        </Box>
                    </Grid>
                </Grid>
                <Grid container item direction="row" spacing={4}>
                    {all.isIniting && <Preloader />}
                    {formattedFilters.map((cat) => (
                        <Grid item {...categoryColumns()} key={`category-block-${cat.id}`}>
                            <FilterCategoryCard
                                {...cat}
                                onClick={() => {
                                    handleUpdateFilter(cat);
                                }}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Fragment>
    );
};

export default FilterCategories;
