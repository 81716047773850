import PropTypes from 'prop-types';
import CheckboxMUI from '@mui/material/Checkbox';

import useStyles from './CheckboxStyle';

const Checkbox = ({ label, isChecked, name, id, value, disabled, onChange, indeterminate, onLabelClick }) => {
    const classes = useStyles();
    return (
        <div className={classes.wrapper}>
            <label htmlFor={id} className={classes.checkbox}>
                <CheckboxMUI
                    name={name}
                    id={id}
                    checked={isChecked}
                    value={value}
                    disabled={disabled}
                    className={classes.input}
                    onChange={onChange}
                    indeterminate={indeterminate}
                />
                <div className={classes.inputbackground}></div>
                <span className={classes.label} onClick={onLabelClick}>
                    {label}
                </span>
            </label>
        </div>
    );
};

export default Checkbox;

Checkbox.propTypes = {
    className: PropTypes.string,
    label: PropTypes.any,
    value: PropTypes.any,
    name: PropTypes.string.isRequired,
    id: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    indeterminate: PropTypes.bool,
    onLabelClick: PropTypes.func,
};
Checkbox.defaultProps = {
    className: '',
    label: '',
    value: '',
    name: '',
    id: 'id',
    disabled: false,
    onChange: () => {},
    indeterminate: false,
    onLabelClick: () => {},
};
