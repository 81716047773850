const config =
    !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
        ? {
              ssoAuthority: process.env.REACT_APP_SSO_AUTHORITY,
              ssoClientId: process.env.REACT_APP_SSO_CLIENT_ID,
              apiUrl: process.env.REACT_APP_API_URL || 'https://partner.prowise.com/api',

              matomoUrlBase: process.env.REACT_APP_MATOMO_URLBASE || '',
              matomoSiteId: process.env.REACT_APP_MATOMO_SITEID || '',
          }
        : document.getElementById('root').dataset;

export default config;
