import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Text from '../../Atoms/Text/Text';

const TextBlock = ({ className, text }) => {
    return (
        <Grid container direction="column" spacing={2}>
            <Grid item xs={12}>
                <Text text={text} className={className} />
            </Grid>
        </Grid>
    );
};

TextBlock.propTypes = {
    text: PropTypes.string,
};

TextBlock.defaultProps = {
    text: '',
};

export default TextBlock;
