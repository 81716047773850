import PropTypes from 'prop-types';
import { useState } from 'react';
import { Icon } from '@mui/material';
import LazyLoad from 'react-lazyload';
import useStyles from './ImgStyle';

const Img = ({ src, alt, aspectRatio, lazyload, className, objectFit }) => {
    const [error, setError] = useState(false);
    const classes = useStyles();

    const onError = () => {
        setError(true);
    };

    if (lazyload) {
        return (
            <div
                className={`${classes.imgWrapper} ${className} ${objectFit ? 'objectfit' : ''}`}
                style={{ paddingTop: `${aspectRatio}%` }}
            >
                <LazyLoad classNamePrefix={`lazyloadWrapper`} resize={true}>
                    {error ? (
                        <div className={classes.error}>
                            <Icon className="material-icons-outlined" fontSize="large">
                                broken_image
                            </Icon>
                        </div>
                    ) : (
                        <img
                            src={src}
                            alt={alt}
                            onError={onError}
                            className={`${classes.img} ${objectFit ? 'objectfit' : ''}`}
                        />
                    )}
                </LazyLoad>
            </div>
        );
    }
    return (
        <div className={`${classes.imgWrapper} ${className}`} style={{ paddingTop: `${aspectRatio}%` }}>
            <img src={src} alt={alt} className={classes.img} />
        </div>
    );
};

Img.propTypes = {
    src: PropTypes.string,
    alt: PropTypes.string,
    lazyload: PropTypes.bool,
    className: PropTypes.string,
    aspectRatio: PropTypes.any,
    objectFit: PropTypes.bool,
};

Img.defaultProps = {
    src: '',
    alt: '',
    lazyload: true,
    className: '',
    aspectRatio: '',
    objectFit: false,
};

export default Img;
