import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => {
    return {
        title: {
            marginBottom: theme.spacing(2),
            '& h2': {
                marginBottom: '0',
            },
        },
        categoryBox: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: theme.spacing(2),
            [theme.breakpoints.up('lg')]: {
                marginBottom: theme.spacing(4),
            },
        },
        categoryTitle: {
            marginBottom: 0,
        },
        categoryButton: {
            marginLeft: theme.spacing(2),
        },
    };
});

export default useStyles;
