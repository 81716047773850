import PropTypes from 'prop-types';
import { Icon } from '@mui/material';

import Button from '../../Atoms/Button/Button';
import Title from '../../Atoms/Title/Title';

import { convertLinkDataToProps } from '../../../utilities/utilities';
import Text from '../../Atoms/Text/Text';
import useStyles from './ActionBlockStyle';

const ActionBlock = ({ title, text, link }) => {
    const classes = useStyles();

    return (
        <div className={classes.ActionBlock}>
            <div className={classes.content}>
                <Title title={title} size="h4" inverted />
                <Text text={text} inverted />
            </div>
            {Object.keys(link).length > 0 && link.label !== '' && link.href !== '' && (
                <div className={classes.button}>
                    <Button endIcon={<Icon>east</Icon>} {...convertLinkDataToProps(link)} />
                </div>
            )}
        </div>
    );
};

ActionBlock.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    link: PropTypes.object,
};

ActionBlock.defaultProps = {
    title: '',
    text: '',
    link: {},
};

export default ActionBlock;
