import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => {
    return {
        dialog: {
            '& .MuiDialog-paper': {
                background: 'transparent',
                // display: 'flex',
                width: '100%',
                maxWidth: 'unset',
                boxShadow: 'unset',
                minHeight: '100vh',
                zIndex: 9999999,
                margin: '0',
            },
            '& .MuiDialog-scrollPaper': {
                background: `linear-gradient(180deg, ${theme.palette.primary.main} 0%, rgba(25, 60, 107, 0) 100%)`,
            },
        },
        wrapper: {
            scrollbarWidth: '0px',
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%) !important',
            padding: theme.spacing(4),
            minHeight: '100vh',
            display: 'flex',
            alignItems: 'center',

            '&::-webkit-scrollbar': {
                display: 'none',
                width: '0px',
                background: 'transparent',
            },
        },
        container: {
            justifyContent: 'center',
            maxHeight: '100%',
        },
        column: {
            position: 'relative',
        },
        close: {
            position: 'absolute',
            width: '24px',
            height: '24px',
            borderRadius: '100%',
            background: theme.palette.common.white,
            right: theme.spacing(1),
            top: theme.spacing(1),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            opacity: '0.6',
            cursor: 'pointer',
            transition: 'all 0.2s ease-out',
            color: theme.palette.primary.main,

            [theme.breakpoints.up('sm')]: {
                right: '-36px',
                top: '0',
            },
            '&:hover': {
                opacity: '1',
            },
        },
        closeIcon: {
            fontSize: '18px !important',
        },
    };
});

export default useStyles;
