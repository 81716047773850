import PropTypes from 'prop-types';
import { Icon } from '@mui/material';
import clsx from 'clsx';

import useStyles from './FilterButtonStyle';

const FilterButton = ({ onClick, count, label, className }) => {
    const classes = useStyles();
    return (
        <div className={clsx(classes.button, { [className]: true })} onClick={onClick}>
            <div className={classes.icon}>
                <Icon>tune</Icon>
            </div>
            {label} {count > 0 && `(${count})`}
        </div>
    );
};

FilterButton.propTypes = {
    onClick: PropTypes.func,
    count: PropTypes.number,
    label: PropTypes.string,
    className: PropTypes.string,
};
FilterButton.defaultProps = {
    onClick: () => {},
    count: 0,
    label: '',
    className: '',
};

export default FilterButton;
