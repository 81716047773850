import Axios from 'axios';
import config from '../utilities/config';
import manager from '../manager';

const _BASE_URL = config.apiUrl.replace(/\/*$/, '');

// Add a request interceptor
Axios.interceptors.request.use(
    async (config) => {
        config.headers['Content-Type'] = 'application/json';

        // add the access_token as Authorization header
        const _manager = manager.getManager();
        if (_manager) {
            const user = await _manager.getUser();
            config.headers.Authorization = `Bearer ${user.access_token}`;
            config.headers['Accept-Language'] = `${user.profile.locale}`;
        }
        return config;
    },
    (error) => {
        // Do something with request error
        return Promise.reject(error);
    },
);

const API = {
    async post({ endpoint = '', headers = {}, params = {}, data = {}, axiosOptions = {} }) {
        return await this.doAjaxCall(endpoint, 'post', headers, params, data, axiosOptions);
    },
    async get({ endpoint = '', headers = {}, params = {}, data = {}, axiosOptions = {} }) {
        return await this.doAjaxCall(endpoint, 'get', headers, params, data, axiosOptions);
    },
    async doAjaxCall(endpoint = '', method = 'post', headers = {}, params = {}, data = {}, axiosOptions = {}) {
        const fullURL = `/${endpoint}/`;
        const axiosConfig = {
            withCredentials: true,
            baseURL: _BASE_URL,
            url: fullURL,
            headers,
            params,
            method,
            ...{ ...axiosOptions },
        };
        if (method === 'post') axiosConfig.data = JSON.stringify(data);
        return await Axios(axiosConfig);
    },
};
export default API;
