import PropTypes from 'prop-types';
import Fab from '@mui/material/Fab';
import useStyles from './DownloadButtonStyle';
import SaveAltIcon from '@mui/icons-material/SaveAlt';

const DownloadButton = ({ className, onClick }) => {
    const classes = useStyles();
    return (
        <Fab
            size="small"
            color="primary"
            aria-label="add"
            className={`${classes.button} ${className}`}
            onClick={onClick}
        >
            <SaveAltIcon />
        </Fab>
    );
};

DownloadButton.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
    iconOnly: PropTypes.bool,
};

DownloadButton.defaultProps = {
    className: '',
    onClick: () => {},
    iconOnly: false,
};

export default DownloadButton;
