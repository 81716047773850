import React from 'react';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Grid from '@mui/material/Grid';

import Checkbox from '../../Atoms/Checkbox/Checkbox';

import useStyles from './FiltersStyle';

const Filters = ({ filterData, activeFilters, updateFilter, horizontal }) => {
    const classes = useStyles();

    const handleFilterChange = (e) => {
        if (updateFilter)
            updateFilter({
                id: e.target.name,
                checked: e.target.checked,
            });
    };

    if (horizontal) {
        return (
            <Grid container spacing={4} direction="row" alignItems="flex-start" className={classes.filtersHorizontal}>
                {filterData.map((item, index) => (
                    <Grid
                        xs={12}
                        sm={6}
                        md={index % 2 === 0 ? 4 : 8}
                        item
                        key={item.id}
                        className={classes.itemHorizontal}
                    >
                        <Checkbox
                            label={item.label}
                            name={item.id}
                            id={item.id}
                            onChange={handleFilterChange}
                            isChecked={activeFilters.includes(item.id)}
                        />
                    </Grid>
                ))}
            </Grid>
        );
    }

    return (
        <List className={classes.filters}>
            {filterData.map((item) => (
                <ListItem key={item.id}>
                    <Checkbox
                        label={item.label}
                        name={item.id}
                        id={item.id}
                        onChange={handleFilterChange}
                        isChecked={activeFilters.includes(item.id)}
                    />
                </ListItem>
            ))}
        </List>
    );
};

export default Filters;

Filters.propTypes = {
    filterData: PropTypes.array,
    activeFilters: PropTypes.array,
    onChange: PropTypes.func,
    horizontal: PropTypes.bool,
};
Filters.defaultProps = {
    filterData: [],
    activeFilters: [],
    onChange: () => {},
    horizontal: false,
};
