import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => {
    return {
        GridListViewToggler: {
            border: '1px solid transparent',
            width: '100%',
            maxWidth: 'max-content',
            display: 'flex',
            alignItems: 'center',
            boxSizing: 'border-box',

            '&__item': {
                width: theme.spacing(3),
                height: theme.spacing(3),
                padding: 0,
                cursor: 'pointer',
                transition: 'background 0.2s ease-out',
                border: '1px solid transparent',
                borderColor: '#00000012',
                color: theme.palette.secondary.colors[900],
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                [theme.breakpoints.up('sm')]: {
                    width: 36,
                    height: 36,
                    padding: theme.spacing(1),
                },
                [theme.breakpoints.up('md')]: {
                    width: theme.spacing(5),
                    height: theme.spacing(5),
                },
                '&:first-child': {
                    borderRadius: '4px 0 0 4px',
                },
                '&:last-child': {
                    borderRadius: '0 4px 4px 0',
                },
                '&:hover': {
                    background: theme.palette.secondary.light,
                },
                '&--active': {
                    background: theme.palette.secondary.colors[900],
                    borderColor: '#00000012',
                    color: theme.palette.common.white,
                    '&:hover': {
                        background: theme.palette.secondary.dark,
                    },
                },
            },
        },
        icon: {
            fontSize: '16px !important',
            [theme.breakpoints.up('sm')]: {
                fontSize: '21px !important',
            },
        },
    };
});

export default useStyles;
