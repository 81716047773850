import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import debounce from 'lodash.debounce';

import ButtonRound from '../../Atoms/ButtonRound/ButtonRound';

import API from '../../../api/api';

import useStyles from './SearchBarStyle';

import { selectFiltersForAPI } from '../../../redux/downloadsSlice';

const _DEBOUNCE_TIMEOUT = 500;

const filter = createFilterOptions();

const fetchAutocompleteData = async (query, filters, cb) => {
    // empty query: no api autocomplete call required!
    if (query === '') return cb([]);
    // get autocomplete data from api
    const res = await API.post({
        endpoint: 'downloadAutocomplete',
        data: {
            search: query,
            filters,
        },
    });
    // if no items: return empty options array
    if (!res.data.items || !Array.isArray(res.data.items)) return cb([]);
    // success! Whoohoo!
    return cb(res.data.items);
};

const debouncedFetchData = debounce((query, filters, startCB, endCB) => {
    startCB();
    fetchAutocompleteData(query, filters, endCB);
}, _DEBOUNCE_TIMEOUT);

const SearchBar = ({ applySearchQuery, value, placeholder }) => {
    const classes = useStyles();
    const filters = useSelector(selectFiltersForAPI);
    const initValue = value !== '' ? { label: value, value } : null;
    const [_value, _setValue] = useState(initValue);
    const [_options, _setOptions] = useState([]);
    const [_query, _setQuery] = useState(value);
    const [_open, _setOpen] = useState(false);
    const [_loading, _setLoading] = useState(false);
    const [_closeBtn, _setCloseBtn] = useState(false);

    // fetch autocomplete options on change
    useEffect(() => {
        debouncedFetchData(
            _query,
            filters,
            () => {
                _setLoading(true);
            },
            (res) => {
                _setLoading(false);
                _setOptions(res);
            },
        );
    }, [_query, filters]);

    // if store value changes also update this component again
    useEffect(() => {
        const initValue = value !== '' ? { label: value, value } : null;
        _setValue(initValue);
    }, [value]);

    const onInputChange = (e, value) => {
        _setQuery(value);
    };
    const onChange = (_, newValue) => {
        if (typeof newValue === 'string') {
            _setValue({
                label: newValue,
                value: newValue,
            });
            _setQuery(newValue);
            applySearchQuery(newValue);
        } else {
            // if clear button is pressed the newValue is null... we do not want this in our state
            if (newValue == null) {
                _setValue(null);
                _setQuery('');
                applySearchQuery('');
                return;
            }
            _setValue(newValue);
            _setQuery(newValue.value);
            applySearchQuery(newValue.value);
        }
    };
    const onOpen = () => {
        _setOpen(true);
    };
    const onClose = () => {
        _setOpen(false);
    };
    const filterOptions = (options, params) => {
        const filtered = filter(options, params);
        // Suggest the creation of a new value
        // if (params.inputValue !== '') {
        //     filtered.push({
        //         inputValue: params.inputValue,
        //         label: `Add "${params.inputValue}"`,
        //     });
        // }
        return filtered;
    };
    const handleOnSearchButtonClick = (e) => {
        onChange(e, _query);
        onClose();
    };
    const handleOnKeyDown = (evt) => {
        if (evt.key === 'Enter') handleOnSearchButtonClick(evt, _query);
    };
    const handleOnKeyUp = (e) => {
        const value = e.target.value;
        _setValue(value);
        _setCloseBtn(value.length > 0);
    };
    const handleOnClearButtonClick = (e) => {
        onChange(e, '');
        onClose();
        _setCloseBtn(false);
    };

    return (
        <Autocomplete
            value={_value}
            onChange={onChange}
            onKeyUp={handleOnKeyUp}
            filterOptions={filterOptions}
            open={_open}
            onOpen={onOpen}
            onClose={onClose}
            selectOnFocus
            classes={classes}
            handleHomeEndKeys
            options={_options}
            fullWidth
            freeSolo
            onInputChange={onInputChange}
            inputValue={_query}
            renderInput={(params) => {
                return (
                    <div className={classes.inputWrapper} ref={params.InputProps.ref}>
                        <input
                            type="text"
                            {...params.inputProps}
                            className={classes.input}
                            placeholder={placeholder}
                            onKeyDown={handleOnKeyDown}
                        />
                        <div className={classes.inputActions}>
                            {_loading ? (
                                <CircularProgress color="secondary" size={20} className={classes.loader} />
                            ) : null}
                            <ButtonRound
                                icon="search"
                                disabled={_loading}
                                onClick={handleOnSearchButtonClick}
                                className={classes.submitButton}
                            />
                            {_closeBtn && (
                                <ButtonRound
                                    icon="close"
                                    onClick={handleOnClearButtonClick}
                                    className={classes.closeButton}
                                    variant="primary"
                                />
                            )}
                        </div>
                    </div>
                );
            }}
        />
    );
};

SearchBar.propTypes = {
    applySearchQuery: PropTypes.func,
    value: PropTypes.string,
    placeholder: PropTypes.string,
};

SearchBar.defaultProps = {
    applySearchQuery: () => {},
    value: '',
    placeholder: '',
};

export default SearchBar;
