import PropTypes from 'prop-types';
import { Icon } from '@mui/material';

import useStyles from './GridListViewTogglerStyle';

const componentClass = 'GridListViewToggler';

const GridListViewToggler = ({ viewType, onChange }) => {
    const classes = useStyles();
    const className = classes[componentClass];

    const changeViewTypeToGrid = () => {
        onChange('grid');
    };
    const changeViewTypeToList = () => {
        onChange('list');
    };

    return (
        <div className={className}>
            <div
                className={`${className}__item ${viewType === 'grid' ? `${className}__item--active` : ''}`}
                onClick={changeViewTypeToGrid}
            >
                <Icon className={classes.icon}>grid_view</Icon>
            </div>
            <div
                className={`${className}__item ${viewType === 'list' ? `${className}__item--active` : ''}`}
                onClick={changeViewTypeToList}
            >
                <Icon className={classes.icon}>list</Icon>
            </div>
        </div>
    );
};

GridListViewToggler.propTypes = {
    viewType: PropTypes.string,
    onChange: PropTypes.func,
};

GridListViewToggler.defaultProps = {
    viewType: 'grid',
    onChange: () => {},
};

export default GridListViewToggler;
