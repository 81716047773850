import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Collapse from '@mui/material/Collapse';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';

import Checkbox from '../../Atoms/Checkbox/Checkbox';
import Button from '../../Atoms/Button/Button';
import useStyles from './FiltersDownloadsStyle';

const Label = ({ label, count }) => {
    const classes = useStyles();
    return (
        <span className={classes.label}>
            {label}
            <span className={classes.count}>({count})</span>
        </span>
    );
};

const FiltersDownloads = ({
    isLoading,
    filterData,
    updateFilter,
    updateExpanded,
    toggleLimited,
    showMoreLabel,
    showLessLabel,
}) => {
    const classes = useStyles();

    const FilterItemWithSubs = ({ item, index = 0 }) => {
        const hasSubfilters = item.sub && item.sub.length > 0;
        return (
            <Fragment>
                {hasSubfilters && (
                    <ListItem className={`${index === 0 ? classes.level1 : ''}`}>
                        <Checkbox
                            label={<Label {...item} />}
                            name={item.id}
                            id={item.id}
                            onChange={(e) => {
                                updateFilter({
                                    ...item,
                                    ...{ checked: e.target.checked },
                                });
                            }}
                            // when clicked on label: don't change the input checkbox
                            onLabelClick={(e) => {
                                e.preventDefault();
                                updateExpanded({ id: item.id, expanded: !!!item.expanded });
                            }}
                            isChecked={item.checked || false}
                            indeterminate={item.indeterminate}
                        />
                        <span
                            className={classes.icon}
                            onClick={() => updateExpanded({ id: item.id, expanded: !!!item.expanded })}
                        >
                            {item.expanded ? <ExpandLess color="secondary" /> : <ExpandMore color="secondary" />}
                        </span>
                    </ListItem>
                )}
                {!hasSubfilters && (
                    <ListItem className={`${index === 0 ? classes.level1 : ''}`}>
                        <Checkbox
                            label={<Label {...item} />}
                            name={item.id}
                            id={item.id}
                            onChange={(e) => {
                                updateFilter({
                                    ...item,
                                    ...{ checked: e.target.checked },
                                });
                            }}
                            isChecked={item.checked || false}
                            indeterminate={item.indeterminate}
                        />
                    </ListItem>
                )}
                {hasSubfilters && (
                    <Collapse in={item.expanded} timeout="auto" unmountOnExit>
                        <List disablePadding>
                            {item.sub
                                .filter((_, index) => {
                                    if (item.limited && index >= 3) return false;
                                    return true;
                                })
                                .map((subItem, index) => (
                                    <FilterItemWithSubs key={subItem.id} item={subItem} index={index + 1} />
                                ))}
                        </List>
                        {item.sub.length > 3 && (
                            <List disablePadding>
                                <Button
                                    label={item.limited ? showMoreLabel : showLessLabel}
                                    color="primary"
                                    variant="outlined"
                                    endIcon={item.limited ? <ExpandMore /> : <ExpandLess />}
                                    className={classes.toggleButton}
                                    onClick={() => {
                                        toggleLimited({
                                            id: item.id,
                                        });
                                    }}
                                />
                            </List>
                        )}
                    </Collapse>
                )}
            </Fragment>
        );
    };

    if (isLoading) return <CircularProgress color="secondary" />;

    return (
        <List className={`${classes.list} bold`}>
            {filterData.map((item) => (
                <FilterItemWithSubs key={item.id} item={item} index={0} />
            ))}
        </List>
    );
};

export default FiltersDownloads;

FiltersDownloads.propTypes = {
    isLoading: PropTypes.bool,
    filterData: PropTypes.array,
    updateFilter: PropTypes.func,
    updateExpanded: PropTypes.func,
    toggleLimited: PropTypes.func,
    showMoreLabel: PropTypes.string,
    showLessLabel: PropTypes.string,
};
FiltersDownloads.defaultProps = {
    isLoading: false,
    filterData: [],
    updateFilter: () => {},
    updateExpanded: () => {},
    toggleLimited: () => {},
    showMoreLabel: 'Show more',
    showLessLabel: 'Show less',
};
