import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => {
    return {
        card: {
            position: 'relative',
            width: '100%',
            background: `rgba(255,255,255,0.6)`,
            borderRadius: theme.spacing(1),
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            cursor: 'pointer',
            overflow: 'hidden',
            border: 'none',
            padding: 0,
            margin: 0,
            outline: 'none',
            textAlign: 'center',

            '&:hover': {
                '& $img': {
                    transform: 'scale(1.05)',
                },
            },
        },
        imgWrapper: {
            position: 'relative',
            display: 'block',
            width: '100%',
            paddingTop: '59.3103448276%',
            overflow: 'hidden',
            background: theme.palette.common.white,
        },
        img: {
            position: 'absolute',
            top: 0,
            left: 0,
            transition: 'transform 0.2s ease-out',
        },
        title: {
            width: '100%',
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            height: theme.spacing(6),
            lineHeight: theme.spacing(6),
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
        },
    };
});

export default useStyles;
