import PropTypes from 'prop-types';
import { ExpandMore } from '@mui/icons-material';
import { Icon } from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import { Body } from '@prowise/react-mui';

import useStyles from './CartItemStyle';
import Title from '../../Atoms/Title/Title';
import { formatPrice } from '../../../utilities/utilities';
import Img from '../../Atoms/Img/Img';

const _QUANTITY_SELECTS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const CartItem = ({
    id,
    variantId,
    title,
    price,
    description,
    quantity,
    img,
    isSubmittingCart,
    handleRemoveFromCart,
    handleChangeQuantity,
}) => {
    const classes = useStyles();
    return (
        <div className={classes.card}>
            <div className={classes.imgWrapper}>
                <Img {...img} />
            </div>
            <div className={classes.content}>
                <Title title={title} size="h6" />
                <Body>{description}</Body>
                <div className={classes.inputAndPrice}>
                    <Select
                        className={classes.input}
                        variant="outlined"
                        IconComponent={ExpandMore}
                        disabled={isSubmittingCart}
                        onChange={(e) => {
                            handleChangeQuantity({
                                variantId,
                                quantity: e.target.value,
                            });
                        }}
                        value={quantity}
                        MenuProps={{ disableScrollLock: true }}
                    >
                        {_QUANTITY_SELECTS.map((item) => (
                            <MenuItem key={`variant-quantity-selector-${variantId}-${item}`} value={item}>
                                {item}
                            </MenuItem>
                        ))}
                    </Select>
                    <div className={classes.price}>{formatPrice(price)}</div>
                </div>
            </div>
            <button
                key={`cartitem-delete-button-${id}`}
                className={classes.delete}
                onClick={() => {
                    handleRemoveFromCart({ id, variantId });
                }}
            >
                <Icon className="material-icons-round">close</Icon>
            </button>
        </div>
    );
};

CartItem.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    price: PropTypes.number,
    img: PropTypes.shape({
        src: PropTypes.string,
        alt: PropTypes.string,
    }).isRequired,
    isSubmittingCart: PropTypes.bool,
    handleRemoveFromCart: PropTypes.func,
    handleChangeQuantity: PropTypes.func,
};

CartItem.defaultProps = {
    title: '',
    description: '',
    price: 0,
    isSubmittingCart: false,
};

export default CartItem;
