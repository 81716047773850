import AuthCallback from '../containers/Auth/AuthCallback';
import AuthError from '../containers/Auth/AuthError';
import NotFound from '../containers/NotFound/NotFound';

import Dashboard from '../containers/Dashboard/Dashboard';
import Shop from '../containers/Shop/Shop';
import ShopSuccess from '../containers/Shop/ShopSuccess';
import ShopError from '../containers/Shop/ShopError';
import Cart from '../containers/Shop/Cart';
import News from '../containers/News/News';
import NewsDetail from '../containers/NewsDetail/NewsDetail';
import Events from '../containers/Events/Events';
import EventsDetail from '../containers/EventsDetail/EventsDetail';
import Downloads from '../containers/Downloads/Downloads';
import DownloadsOverview from '../containers/DownloadsOverview/DownloadsOverview';

// icons for pages in menu
import {
    DashboardOutlined,
    AssignmentOutlined,
    CalendarTodayOutlined,
    GetApp,
    ShoppingCartOutlined,
    // SchoolOutlined,
} from '@mui/icons-material';

// unproctected routes
// ****************************************************************
const authRoutes = [
    {
        path: '/auth/callback',
        component: AuthCallback,
        exact: true,
    },
    {
        path: '/auth/error',
        component: AuthError,
        exact: true,
    },
];

// proctected routes
// ****************************************************************
const routes = [
    // dashboard
    {
        path: '/',
        component: Dashboard,
        exact: true,
        label: 'dashboard',
        icon: <DashboardOutlined />,
        id: 'dashoard',
    },
    // news
    {
        path: '/news/',
        component: News,
        exact: true,
        label: 'news',
        icon: <AssignmentOutlined />,
        id: 'news',
    },
    {
        path: '/news/:simpleId/',
        component: NewsDetail,
        exact: true,
        label: 'newsDetail',
        notInMenu: true,
        id: 'newsDetail',
        parentId: 'news',
    },
    // events
    {
        path: '/events/',
        component: Events,
        exact: true,
        label: 'events',
        icon: <CalendarTodayOutlined />,
        id: 'events',
    },
    {
        path: '/events/:simpleId/',
        component: EventsDetail,
        exact: true,
        label: 'EventDetail',
        notInMenu: true,
        id: 'EventDetail',
        parentId: 'events',
    },
    // downloads
    {
        path: '/downloads/',
        component: Downloads,
        exact: true,
        label: 'downloads',
        icon: <GetApp />,
        id: 'downloads',
    },
    {
        path: '/downloads/overview/',
        component: DownloadsOverview,
        exact: true,
        label: 'Download overview',
        notInMenu: true,
        icon: <GetApp />,
        id: 'downloads',
        parentId: 'downloads',
    },
    // shop
    {
        path: '/shop/',
        component: Shop,
        exact: true,
        label: 'shop',
        icon: <ShoppingCartOutlined />,
        id: 'shop',
    },
    {
        path: '/shop/cart',
        component: Cart,
        exact: true,
        label: 'shopCart',
        notInMenu: true,
        id: 'shopCart',
        parentId: 'shop',
    },
    {
        path: '/shop/success',
        component: ShopSuccess,
        exact: true,
        label: 'shopSuccess',
        notInMenu: true,
        parentId: 'shop',
    },
    {
        path: '/shop/error',
        component: ShopError,
        exact: true,
        label: 'shopError',
        notInMenu: true,
        id: 'shopError',
        parentId: 'shop',
    },
    // 404 not found
    {
        // path: '/404', this 404 does not require a path, that's why it catches all other unknown paths
        component: NotFound,
        exact: true,
        notInMenu: true,
        id: '404',
    },
];

export default routes;
export { authRoutes };
