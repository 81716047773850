import { Redirect } from 'react-router-dom';
import { AuthCallback as AuthCallbackComponent } from '@prowise/react-auth';
import StatusScreen from '../../components/Organisms/StatusScreen/StatusScreen';

const AuthCallback = () => (
    <AuthCallbackComponent
        redirector={({ to }) => <Redirect to={to} />}
        loader={() => <StatusScreen type="loading" text="Loading..." />}
    />
);

export default AuthCallback;
