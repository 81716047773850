import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import useStyles from './GridListViewStyle';
import InfiniteScroll from 'react-infinite-scroll-component';

import Preloader from '../../Atoms/Preloader/Preloader';

const GridListView = ({
    isIniting,
    isFetching,
    viewType,
    data,
    Component,
    totalItems,
    extraComponentData,
    leftDrawerIsOpen,
    rightDrawerIsOpen,
    fetchData,
    NoResultsComponent,
}) => {
    const classes = useStyles();
    const matchesSmallerBreakpoint = useMediaQuery('(max-width: 660px)');
    const matchesSmBreakpoint = useMediaQuery((theme) => theme.breakpoints.up('sm'));
    const matchesLgBreakpoint = useMediaQuery((theme) => theme.breakpoints.up('lg'));

    const getColClasses = () => {
        const defaultClasses = {
            xs: 6,
            sm: 4,
            lg: 3,
        };
        if ((leftDrawerIsOpen || rightDrawerIsOpen) && matchesSmBreakpoint && !matchesSmallerBreakpoint) {
            return {
                xs: 12,
                sm: 6,
                md: 4,
                lg: 3,
            };
        }
        if ((leftDrawerIsOpen || rightDrawerIsOpen) && matchesSmallerBreakpoint) {
            return {
                xs: 12,
            };
        }
        return defaultClasses;
    };

    if (totalItems <= 0 && !isIniting && NoResultsComponent)
        return (
            <Grid xs={12} item container direction="column">
                {NoResultsComponent}
            </Grid>
        );
    if (isIniting || (data.length < 0 && isFetching)) return <Preloader />;

    if (viewType === 'grid') {
        return (
            <Grid container item className={classes.root}>
                <InfiniteScroll
                    dataLength={data.length}
                    next={fetchData}
                    hasMore={data.length < totalItems}
                    loader={<Preloader />}
                    style={{ overflow: 'hidden', width: '100%' }}
                >
                    <Grid
                        container
                        item
                        direction="row"
                        spacing={
                            (!matchesSmBreakpoint && 1) || (matchesLgBreakpoint && 4) || (!matchesLgBreakpoint && 3)
                        }
                        style={{ minHeight: 40 }}
                    >
                        {data.map((item, i) => (
                            <Grid item key={`gridlistview-item-grid-${item.id}-${i}`} {...getColClasses()}>
                                <Component {...item} {...extraComponentData} />
                            </Grid>
                        ))}
                    </Grid>
                </InfiniteScroll>
            </Grid>
        );
    }
    return (
        <Grid container item className={classes.root}>
            <InfiniteScroll
                dataLength={data.length}
                next={fetchData}
                hasMore={data.length < totalItems}
                loader={<Preloader />}
                style={{ overflow: 'hidden', width: '100%' }}
            >
                <div className={classes.table}>
                    {data.map((item, i) => (
                        <Grid item xs={12} key={`gridlistview-item-list-${item.id}-${i}`} className={classes.tableItem}>
                            <Component {...item} {...extraComponentData} />
                        </Grid>
                    ))}
                </div>
            </InfiniteScroll>
        </Grid>
    );
};

export default GridListView;

GridListView.propTypes = {
    isIniting: PropTypes.bool,
    isFetching: PropTypes.bool,
    viewType: PropTypes.oneOf(['grid', 'list']),
    data: PropTypes.array,
    Component: PropTypes.any.isRequired,
    totalItems: PropTypes.number.isRequired,
    NoResultsComponent: PropTypes.any,
};
GridListView.defaultProps = {
    isIniting: false,
    isFetching: false,
    viewType: 'grid',
    data: [],
    NoResultsComponent: null,
};
