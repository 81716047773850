import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { Fragment, useEffect } from 'react';
import { useDrawerContext } from '@prowise/react-mui';
import useMediaQuery from '@mui/material/useMediaQuery';
import Icon from '@mui/material/Icon';
import { useMatomo } from '@datapunt/matomo-tracker-react';

import LayoutDefault from '../../components/Layouts/Default/Default';
import Button from '../../components/Atoms/Button/Button';
import Title from '../../components/Atoms/Title/Title';
import NewsCard from '../../components/Molecules/NewsCard/NewsCard';
import EditorialBlock from '../../components/Molecules/EditorialBlock/EditorialBlock';
import EventCard from '../../components/Molecules/EventCard/EventCard';

import useStyles from './DashboardStyle';

import { useSelector } from 'react-redux';
import { selectDashboard } from '../../redux/dataSlice';

const componentClass = 'Dashboard';

const Dashboard = () => {
    const drawerState = useDrawerContext();
    const data = useSelector(selectDashboard);
    const classes = useStyles();
    const className = classes[componentClass];

    const matchesSmBreakpoint = useMediaQuery((theme) => theme.breakpoints.up('sm'));
    const matchesMdBreakpoint = useMediaQuery((theme) => theme.breakpoints.up('md'));
    const matchesLgBreakpoint = useMediaQuery((theme) => theme.breakpoints.up('lg'));
    const matchesEventsBreakpoint = useMediaQuery('(max-width: 960px)');
    const matchesEventsBreakpointSmall = useMediaQuery('(max-width: 750px)');

    document.title = data.dashboard.title;
    const { trackPageView } = useMatomo();

    useEffect(() => {
        trackPageView();
        // eslint-disable-next-line
    }, []);

    const getColClassesNews = (i) => {
        const defaultClasses = {
            xs: 12,
            sm: 4,
            md: 4,
        };
        if (drawerState.opened) {
            if (matchesSmBreakpoint && !matchesMdBreakpoint)
                if (i === 0) {
                    return {
                        xs: 12,
                        sm: 12,
                        md: 4,
                    };
                } else {
                    return {
                        sm: 6,
                        md: 6,
                    };
                }
            return defaultClasses;
        }
        return defaultClasses;
    };

    const getColClassesEvents = () => {
        const defaultClasses = {
            xs: 12,
            sm: 4,
            lg: 12,
        };
        if (!drawerState.opened && matchesEventsBreakpointSmall)
            return {
                xs: 12,
            };
        if (!drawerState.opened && !matchesEventsBreakpointSmall && !matchesLgBreakpoint)
            return {
                xs: 12,
                sm: 4,
                lg: 12,
                className: `${className}__event__hideborder`,
            };
        if (drawerState.opened && matchesSmBreakpoint)
            return {
                xs: 12,
                md: 4,
                lg: 12,
            };
        if (drawerState.opened && !matchesEventsBreakpoint)
            return {
                className: `${className}__event__showborder`,
            };
        return defaultClasses;
    };

    return (
        <LayoutDefault>
            <Container>
                <Grid container direction="row" spacing={4} className={className}>
                    <Grid container item xs={12} lg={9} direction="column">
                        {
                            // NEWS
                            // ************************************************************
                            data.news.items.length > 0 && (
                                <Fragment>
                                    <Title title={data.news.title} size="h2" className={`${className}__news__title`} />
                                    <div className={`${className}__news`}>
                                        <Grid container item direction="row" spacing={(!matchesLgBreakpoint && 3) || 4}>
                                            {data.news.items.map((item, i) => (
                                                <Grid
                                                    item
                                                    key={`dashboard-news-item-${item.id}-${i}`}
                                                    {...getColClassesNews(i)}
                                                >
                                                    <NewsCard {...item} />
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </div>
                                    <Grid container item justifyContent="center">
                                        <div className={`${className}__news__button`}>
                                            <Button
                                                label={data.dashboard.news.link.label}
                                                endIcon={<Icon>east</Icon>}
                                                to={data.dashboard.news.link.href}
                                                color="secondary"
                                            />
                                        </div>
                                    </Grid>
                                </Fragment>
                            )
                        }
                        {
                            // EDITORIALS
                            // ************************************************************
                            data.editorials.items.length > 0 && (
                                <Fragment>
                                    <div className={`${className}__editorials__title`}>
                                        <Title title={data.editorials.title} size="h2" />
                                    </div>
                                    <div className={`${className}__editorials`}>
                                        <Grid container item direction="row">
                                            {data.editorials.items.map((item, i) => (
                                                <Grid
                                                    item
                                                    xs={12}
                                                    key={`dashboard-editorial-item-${item.id}-${i}`}
                                                    className={`${className}__editorial`}
                                                >
                                                    <EditorialBlock {...item} />
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </div>
                                </Fragment>
                            )
                        }
                    </Grid>
                    <Grid container item xs={12} lg={3}>
                        {
                            // EVENTS
                            // ************************************************************
                            data.events.items.length > 0 && (
                                <Fragment>
                                    <div className={`${className}__events__title`}>
                                        <Title title={data.events.title} size="h2" />
                                    </div>
                                    <div
                                        className={`${className}__events ${
                                            (matchesEventsBreakpoint && `${className}__events__padding`) || ''
                                        }`}
                                    >
                                        <Grid container item direction="row">
                                            {data.events.items.map((item, i) => (
                                                <Grid
                                                    item
                                                    key={`dashboard-event-item-${item.id}-${i}`}
                                                    className={`${classes.Dashboard}__event ${
                                                        (!matchesEventsBreakpoint && `${className}__event__border`) ||
                                                        ''
                                                    }`}
                                                    {...getColClassesEvents(i)}
                                                >
                                                    <EventCard {...item} buttonLabel={data.events.buttonLabel} />
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </div>
                                    {data.events.items.length > 3 && (
                                        <div className={`${classes.Dashboard}__events__button`}>
                                            <Button
                                                label={data.dashboard.events.link.label}
                                                endIcon={<Icon>east</Icon>}
                                                to={data.dashboard.events.link.href}
                                                color="secondary"
                                            />
                                        </div>
                                    )}
                                </Fragment>
                            )
                        }
                    </Grid>
                </Grid>
            </Container>
        </LayoutDefault>
    );
};
export default Dashboard;
