import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => {
    return {
        teaser: {
            position: 'relative',
            width: '100%;',
            marginBottom: theme.spacing(2),
            [theme.breakpoints.up('sm')]: {
                marginBottom: theme.spacing(3),
            },
            [theme.breakpoints.up('lg')]: {
                marginBottom: theme.spacing(4),
            },
        },
        img: {
            borderRadius: '12px',
            overflow: 'hidden',
        },
        chip: {
            position: 'absolute',
            left: '20px',
            top: '-16px',
        },
        logo: {
            width: '124px',
            height: '124px',
            background: theme.palette.common.white,
            position: 'absolute',
            left: '-62px',
            top: '50%',
            transform: 'translateY(-50%)',
            borderRadius: '100%',
            boxShadow: theme.shadows[1],
            display: 'none',
            overflow: 'hidden',
            '@media (min-width: 1670px)': {
                display: 'flex',
            },
        },
        content: {
            width: '100%',
            padding: theme.spacing(2),
            background: theme.palette.common.white,
            borderRadius: '12px',
            [theme.breakpoints.up('sm')]: {
                marginLeft: theme.spacing(1.5),
            },
            [theme.breakpoints.up('lg')]: {
                padding: theme.spacing(4),
                marginLeft: 0,
            },
            '& button': {
                margin: '20px 0',
            },
            '& p': {
                marginBottom: theme.spacing(2),
                '&:last-child': {
                    marginBottom: 0,
                },
            },
            '&--drawerstate': {
                marginLeft: 0,
                [theme.breakpoints.down('md')]: {
                    marginTop: theme.spacing(3),
                },
                [theme.breakpoints.up('md')]: {
                    marginLeft: theme.spacing(1.5),
                    marginBottom: 0,
                },
                [theme.breakpoints.up('lg')]: {
                    marginLeft: 0,
                },
            },
        },
        summary: {
            width: '100%',
            marginBottom: theme.spacing(2),
            [theme.breakpoints.up('sm')]: {
                marginRight: theme.spacing(1.5),
                marginBottom: 0,
            },
            '&--drawerstate': {
                marginRight: 0,
                [theme.breakpoints.up('md')]: {
                    marginRight: theme.spacing(1.5),
                    marginBottom: 0,
                },
            },
        },
        buttons: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(-1),
        },
        button: {
            marginBottom: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        introText: {
            marginBottom: theme.spacing(2),
        },
        text: {
            color: theme.palette.gray.dark,
        },
    };
});

export default useStyles;
