import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@mui/material/Icon';
import { useHistory } from 'react-router';

import { Body } from '@prowise/react-mui';
import Button from '../../Atoms/Button/Button';

import useStyles from './NewsOverviewCardStyle';
import Title from '../../Atoms/Title/Title';
import IconWithTag from '../IconWithTag/IconWithTag';
import Text from '../../Atoms/Text/Text';
import Img from '../../Atoms/Img/Img';

const NewsOverviewCard = ({ simpleId, title, img, date, introText, tags, buttonLabel }) => {
    const classes = useStyles();
    const history = useHistory();

    const handleClick = () => {
        history.push(`/news/${simpleId}`);
    };

    return (
        <div className={classes.link} onClick={handleClick}>
            <div className={classes.card}>
                <div className={classes.content}>
                    <div className={classes.contentItem}>
                        {title !== '' && <Title title={title} size="h4" className={classes.title} />}
                    </div>
                    <div className={classes.contentItem}>
                        <IconWithTag icon="schedule" tag={date} variant="light" />
                        <IconWithTag icon="folder" tags={tags} variant="light" />
                    </div>
                    <div className={classes.contentItem}>
                        <Body>
                            <Text text={introText} className={classes.contentItem} />
                        </Body>
                    </div>
                    <div className={`${classes.button} ${classes.contentItem}`}>
                        <Button endIcon={<Icon>east</Icon>} label={buttonLabel} to={`/news/${simpleId}/`} />
                    </div>
                </div>
                <div className={classes.imgWrapper}>
                    <Img {...img} className={classes.img} />
                </div>
            </div>
        </div>
    );
};

NewsOverviewCard.propTypes = {
    simpleId: PropTypes.string,
    title: PropTypes.string,
    img: PropTypes.object,
    date: PropTypes.string,
    introText: PropTypes.string,
    buttonLabel: PropTypes.string,
};

NewsOverviewCard.defaultProps = {
    simpleId: '',
    title: '',
    img: {},
    date: '',
    introText: '',
    buttonLabel: '',
};

export default NewsOverviewCard;
