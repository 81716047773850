import PropTypes from 'prop-types';

import useStyles from './CartSummaryStyle';
import Title from '../../Atoms/Title/Title';

const CartSummary = ({ totalCartAmount, label }) => {
    const classes = useStyles();

    return (
        <div className={`${classes.CartSummary}`}>
            <div className={`${classes.CartSummary}__description`}>
                <Title title={label} size="h6" />
            </div>
            <div className={`${classes.CartSummary}__amount`}>
                <Title title={`${totalCartAmount}*`} size="h6" />
            </div>
        </div>
    );
};

CartSummary.propTypes = {
    totalCartAmount: PropTypes.string,
    label: PropTypes.string,
};

CartSummary.defaultProps = {
    totalCartAmount: '',
    label: '',
};

export default CartSummary;
