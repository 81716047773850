import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';
import { Fragment } from 'react';
import config from './config';

const Matomo = ({ children }) => {
    if (config.matomoUrlBase === '' || config.matomoSiteId === '') return <Fragment>{children}</Fragment>;

    const matomoConfig = createInstance({
        urlBase: config.matomoUrlBase,
        siteId: config.matomoSiteId,
        // trackerUrl: 'https://LINK.TO.DOMAIN/tracking.php', // optional, default value: `${urlBase}matomo.php`
        // userId: 'UID76903202', // optional, default value: `undefined`.
        // srcUrl: 'https://LINK.TO.DOMAIN/tracking.js', // optional, default value: `${urlBase}matomo.js`
        // heartBeat: {
        //     // optional, enabled by default
        //     active: true, // optional, default value: true
        //     seconds: 10, // optional, default value: `15
        // },
        // linkTracking: false, // optional, default value: true
        // configurations: {
        //     // optional, default value: {}
        //     // any valid matomo configuration, all below are optional
        //     disableCookies: true,
        //     setSecureCookie: true,
        //     setRequestMethod: 'POST',
        // },
    });

    return <MatomoProvider value={matomoConfig}>{children}</MatomoProvider>;
};

export default Matomo;
