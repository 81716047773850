import parse, { domToReact } from 'html-react-parser';
import { NavLink } from 'react-router-dom';
import { Body, Subtitle, Link } from '@prowise/react-mui';
import Title from '../components/Atoms/Title/Title';

const replace = (node) => {
    // transform internal links to a navlink instead of a "a" tag
    // ***************************************************************
    if (node.type === 'tag' && node.name === 'a') {
        // check if we want to render a regular A tag or a NavLink element
        const hasTargetBlank = node.attribs.target === '_blank';
        const containsMailto = node.attribs.href.includes('mailto:');
        const containsTel = node.attribs.href.includes('tel:');

        // make sure external link have 'noopener noreferrer' attributes to prevent 'tabnabbing'
        // https://www.manjuladube.dev/target-blank-security-vulnerability
        if (hasTargetBlank) node.attribs.rel = 'noopener noreferrer';
        if (hasTargetBlank || containsMailto || containsTel) {
            return (
                <Link {...node.attribs} href={node.attribs.href}>
                    {node.children[0] ? node.children[0].data : ''}
                </Link>
            );
            // return convertNodeToElement(node, index, transform);
        } else {
            return (
                <Link {...node.attribs} to={node.attribs.href} component={NavLink} underline="always">
                    {node.children[0] ? node.children[0].data : ''}
                </Link>
            );
        }
    }
    // body
    // ***************************************************************
    if (node.type === 'tag' && node.name === 'p')
        return <Body gutterBottom={true}>{domToReact(node.children, { replace })}</Body>;
    // titles
    // ***************************************************************
    if (
        node.type === 'tag' &&
        (node.name === 'h1' ||
            node.name === 'h2' ||
            node.name === 'h3' ||
            node.name === 'h4' ||
            node.name === 'h5' ||
            node.name === 'h6')
    ) {
        // h1
        if (node.name === 'h1')
            return <Title color="primary" title={node.children[0] ? node.children[0].data : ''} size={node.name} />;
        // smaller titles
        return <Subtitle size="2">{node.children[0] ? node.children[0].data : ''}</Subtitle>;
    }
    // lists
    // ***************************************************************
    if (node.type === 'tag' && (node.name === 'ul' || node.name === 'ol')) {
        return (
            <Body>
                {node.name === 'ul' ? (
                    <ul>{domToReact(node.children, { replace })}</ul>
                ) : (
                    <ol>{domToReact(node.children, { replace })}</ol>
                )}
            </Body>
        );
    }
};

const parseHTML = (htmlString = '') => parse(htmlString, { replace });

export default parseHTML;
