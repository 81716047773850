import makeStyles from '@mui/styles/makeStyles';

const righDrawerWidth = 334;

const useStyles = makeStyles((theme) => {
    return {
        wrapper: {
            display: 'flex',
            width: '100%',
            height: '100%',
            background: theme.palette.secondary.colors['100'],
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            [theme.breakpoints.up('sm')]: {
                paddingLeft: theme.spacing(0.5),
                paddingRight: theme.spacing(0.5),
            },
            [theme.breakpoints.up('md')]: {
                paddingLeft: theme.spacing(3),
                paddingRight: theme.spacing(3),
                paddingTop: theme.spacing(6),
                paddingBottom: theme.spacing(6),
            },
            transition: 'padding 0.4s ease!important',
        },
        wrapperShifted: {
            // on smallest screens, the drawer is fixed over the content so we don't need to change any padding
            [theme.breakpoints.up('sm')]: {
                paddingRight: theme.spacing(0.5) + righDrawerWidth,
            },
            [theme.breakpoints.up('md')]: {
                paddingRight: theme.spacing(3) + righDrawerWidth,
            },
        },
    };
});

export default useStyles;
