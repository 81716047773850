import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => {
    return {
        list: {
            display: 'flex',
            flexWrap: 'wrap',
            listStyle: 'none',
            padding: '0',
            width: '100%',
            flexDirection: 'column',
            '&.bold > li': {
                fontWeight: 'bold',
            },
            '& li': {
                padding: `${theme.spacing(1)} 0`,
                width: '100%',

                '& ul': {
                    padding: `${theme.spacing(0.5)} 0`,
                },

                '& div': {
                    width: '100%',
                },
            },
            '& ul': {
                listStyle: 'none',
                paddingLeft: theme.spacing(1.5),
                marginLeft: theme.spacing(2),
            },
        },
        level1: {
            position: 'relative',
            marginBottom: theme.spacing(1),
            paddingBottom: `${theme.spacing(2)} !important`,
            '&:after': {
                content: '""',
                display: 'block',
                position: 'absolute',
                bottom: 0,
                left: 0,
                width: '100%',
                height: 1,
                background: theme.palette.secondary.colors[500],
                opacity: 0.5,
            },
        },

        // labels with counter and arrow
        // labelWrapper: {
        //     display: 'flex',
        //     alignItems: 'flex-start',
        // },
        count: {
            // flexShrink: 0,
            fontsSize: 10,
            marginLeft: theme.spacing(1),
            opacity: 0.7,
        },
        icon: {
            width: '24px',
            height: '1rem',
            fontSize: '24px',
            color: theme.palette.secondary.main,
            marginTop: '-12px',
            cursor: 'pointer',
        },
        toggleButton: {
            marginTop: 10,
            marginBottom: theme.spacing(4),
        },
    };
});

export default useStyles;
