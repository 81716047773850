import { Fragment, useEffect } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { Icon } from '@mui/material';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import { useHistory } from 'react-router-dom';
import { useDrawerContext } from '@prowise/react-mui';
import { useMatomo } from '@datapunt/matomo-tracker-react';

import Title from '../../components/Atoms/Title/Title';
import LayoutDefault from '../../components/Layouts/Default/Default';
import CartItem from '../../components/Molecules/CartItem/CartItem';
import CartSummary from '../../components/Molecules/CartSummary/CartSummary';
import Button from '../../components/Atoms/Button/Button';
import Text from '../../components/Atoms/Text/Text';

import useStyles from './CartStyle';
import { formatPrice } from '../../utilities/utilities';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectShop,
    selectCartItems,
    removeFromCart,
    updateCartItemQuantity,
    selectTotalCartPrice,
    selectIsSubmittingCart,
    submitShoppingCart,
} from '../../redux/dataSlice';

const Cart = () => {
    const dispatch = useDispatch();
    let history = useHistory();
    const data = useSelector(selectShop).cart;
    const drawerState = useDrawerContext();

    document.title = data.title;
    const { trackPageView } = useMatomo();
    useEffect(() => {
        trackPageView();
        // eslint-disable-next-line
    }, []);

    const isSubmittingCart = useSelector(selectIsSubmittingCart);
    const cartItems = useSelector(selectCartItems);
    const totalPrice = useSelector(selectTotalCartPrice);
    const classes = useStyles();

    const handleSubmit = () => {
        dispatch(submitShoppingCart(cartItems)).then((res) => {
            if (res.payload && res.payload.success) return history.push('/shop/success');
            return history.push('/shop/error');
        });
    };
    const handleRemoveFromCart = (data) => {
        dispatch(removeFromCart(data));
    };
    const handleChangeQuantity = (data) => {
        dispatch(updateCartItemQuantity(data));
    };

    return (
        <LayoutDefault>
            <Container>
                <Grid container direction="column" className={classes.Cart}>
                    <Grid container item direction="column">
                        <Grid item xs={12}>
                            <Title title={data.title} size="h2" gutterBottom={false} />
                        </Grid>
                        <Grid item xs={12} md={drawerState.opened ? 12 : 8} lg={8} xl={6}>
                            <Text text={data.text} className={classes.text} />
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} md={drawerState.opened ? 12 : 8} lg={8} xl={6} direction="column">
                        <div className={`${classes.Cart}__items`}>
                            {cartItems.length ? (
                                <Fragment>
                                    {cartItems.map((item, i) => (
                                        <div className={`${classes.Cart}__item`} key={`cart-item-${item.id}-${i}`}>
                                            <CartItem
                                                {...item}
                                                isSubmittingCart={isSubmittingCart}
                                                handleRemoveFromCart={handleRemoveFromCart}
                                                handleChangeQuantity={handleChangeQuantity}
                                            />
                                        </div>
                                    ))}
                                    <div className={`${classes.Cart}__summary`}>
                                        <CartSummary
                                            totalCartAmount={formatPrice(totalPrice)}
                                            label={data.totalPriceLabel}
                                        />
                                    </div>
                                </Fragment>
                            ) : (
                                <Alert severity="info">{data.emptyCartText}</Alert>
                            )}
                        </div>

                        <div className={`${classes.Cart}__buttons`}>
                            <Button
                                label={data.backLinkLabel}
                                startIcon={<Icon>west</Icon>}
                                variant="outlined"
                                color="primary"
                                to="/shop/"
                                className={`${classes.Cart}__button-back`}
                                disabled={isSubmittingCart}
                            />
                            <Button
                                label={data.nextLinkLabel}
                                color="secondary"
                                startIcon={<Icon>send</Icon>}
                                onClick={handleSubmit}
                                component={'button'}
                                className={`${classes.Cart}__button-submit`}
                                disabled={cartItems.length <= 0 || isSubmittingCart}
                            />
                        </div>
                        {isSubmittingCart && (
                            <div className={`${classes.Cart}__loader`}>
                                <CircularProgress />
                            </div>
                        )}
                    </Grid>
                </Grid>
            </Container>
        </LayoutDefault>
    );
};

export default Cart;
