import PropTypes from 'prop-types';

import Title from '../../Atoms/Title/Title';
import IconWithTag from '../../Molecules/IconWithTag/IconWithTag';

import useStyles from './SummaryBlockStyle';

const SummaryBlock = ({ children, date, time, tags, language, location, eventLink, title }) => {
    const classes = useStyles();

    return (
        <div className={`${classes.SummaryBlock}`}>
            <Title title={title} size="h4" />
            <div className={`${classes.SummaryBlock}__items`}>
                <div className={`${classes.SummaryBlock}__item`}>
                    <IconWithTag icon="calendar_today" tag={date} />
                </div>
                {time !== '' && (
                    <div className={`${classes.SummaryBlock}__item`}>
                        <IconWithTag icon="schedule" tag={time} />
                    </div>
                )}
                <div className={`${classes.SummaryBlock}__item`}>
                    <IconWithTag icon="folder" tags={tags} />
                </div>
                <div className={`${classes.SummaryBlock}__item`}>
                    <IconWithTag icon="comment" tag={language} />
                </div>
                <div className={`${classes.SummaryBlock}__item`}>
                    <IconWithTag icon="place" tag={location} />
                </div>
                {Object.keys(eventLink).length > 0 && (
                    <div className={`${classes.SummaryBlock}__item`}>
                        <IconWithTag icon="language" tag={eventLink.href} href={eventLink.href} />
                    </div>
                )}
            </div>
            {children !== null && <div className={`${classes.SummaryBlock}__children`}>{children}</div>}
        </div>
    );
};

SummaryBlock.propTypes = {
    children: PropTypes.any,
    title: PropTypes.string,
    date: PropTypes.string,
    time: PropTypes.string,
    tags: PropTypes.array,
    location: PropTypes.string,
    language: PropTypes.string,
    eventLink: PropTypes.object,
};

SummaryBlock.defaultProps = {
    children: null,
    title: '',
    date: '',
    time: '',
    location: '',
    language: '',
    eventLink: {},
    tags: [],
};

export default SummaryBlock;
