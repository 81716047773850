import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => {
    console.log(theme);
    return {
        card: {
            width: '100%',
            position: 'relative',
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
            borderBottom: '1px solid transparent',
            borderColor: `${theme.palette.gray.light} !important`,
            cursor: 'pointer',
            [theme.breakpoints.up('sm')]: {
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2),
            },
            [theme.breakpoints.up('md')]: {
                paddingLeft: theme.spacing(4),
                paddingRight: theme.spacing(4),
            },
            transition: 'background 0.2s ease-out',
            '&:hover': {
                background: theme.palette.tertiary.light,
            },
        },
        wrapper: {
            position: 'relative',
            display: 'flex',
            alignItems: 'flex-start',
            [theme.breakpoints.up('sm')]: {
                alignItems: 'center',
            },
        },
        imgIconWrapper: {
            minWidth: theme.spacing(5),
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            overflow: 'hidden',
            marginRight: theme.spacing(2),
            color: theme.palette.tertiary.main,
            fontSize: 24,

            [theme.breakpoints.up('sm')]: {
                marginRight: theme.spacing(4),
                display: 'flex',
            },
        },
        content: {
            width: '100%',
            overflow: 'hidden',
            marginRight: theme.spacing(2),
            [theme.breakpoints.up('sm')]: {
                display: 'flex',
            },
        },
        contentSmall: {
            flexDirection: 'column',
            '& span': {
                marginBottom: theme.spacing(2),
            },
        },
        title: {
            width: '100%',
            opacity: '0.65',
            marginBottom: theme.spacing(2),
            display: 'inline-block',
            [theme.breakpoints.up('sm')]: {
                marginBottom: '0',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                paddingRight: theme.spacing(4),
                flex: 1,
            },
        },
        details: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            width: 'auto',
            flex: '0 1 auto',
        },
        detail: {
            fontSize: '10px',
            opacity: '0.65',
            [theme.breakpoints.up('sm')]: {
                fontSize: theme.typography.fontSize,
                width: '50%',
            },
        },
        detailSmall: {
            fontSize: '10px !important',
        },
        button: {},
        actions: {
            marginLeft: 'auto',
            display: 'flex',
            alignItems: 'center',
        },
        icons: {
            color: theme.palette.primary.main,
            marginRight: theme.spacing(1),
        },
    };
});

export default useStyles;
