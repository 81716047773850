import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => {
    return {
        Fab: {
            '&.MuiFab-secondary': {
                background: theme.palette.secondary.colors[900],
                color: theme.palette.common.white,
                '&:hover': {
                    background: `${theme.palette.secondary.main} !important`,
                },
            },
        },
    };
});

export default useStyles;
