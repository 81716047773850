import { Fragment, useEffect } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { Icon } from '@mui/material';
import React, { useState } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { useDrawerContext } from '@prowise/react-mui';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useMatomo } from '@datapunt/matomo-tracker-react';

import { useSelector } from 'react-redux';
import { selectNewsItemBySimpleId } from '../../redux/dataSlice';

import useStyles from './NewsDetailStyle';

import LayoutDefault from '../../components/Layouts/Default/Default';
import Button from '../../components/Atoms/Button/Button';
import Title from '../../components/Atoms/Title/Title';
import TextBlock from '../../components/Molecules/TextBlock/TextBlock';
import IconWithTag from '../../components/Molecules/IconWithTag/IconWithTag';
import ActionBlock from '../../components/Molecules/ActionBlock/ActionBlock';
import VideoPlayer from '../../components/Molecules/VideoPlayer/VideoPlayer';
import CustomDialog from '../../components/Molecules/CustomDialog/CustomDialog';
import Img from '../../components/Atoms/Img/Img';
import { convertLinkDataToProps } from '../../utilities/utilities';

const NewsDetail = () => {
    const classes = useStyles();
    const params = useParams();
    const selectNewsItemBySimpleIdFilter = useSelector(selectNewsItemBySimpleId);
    const data = selectNewsItemBySimpleIdFilter(params.simpleId);
    const drawerState = useDrawerContext();

    document.title = data.title;
    const { trackPageView, trackEvent } = useMatomo();
    useEffect(() => {
        trackPageView();
        // eslint-disable-next-line
    }, []);

    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleClickOpen = () => {
        setOpen(true);
    };
    const hasLink1 = Object.keys(data.link1).length > 1 && data.link1.href !== '' && data.link1.label !== '';
    const hasLink2 = Object.keys(data.link2).length > 1 && data.link2.href !== '' && data.link2.label !== '';

    const matchesSmBreakpoint = useMediaQuery((theme) => theme.breakpoints.up('sm'));
    const matchesLgBreakpoint = useMediaQuery((theme) => theme.breakpoints.up('lg'));

    const getColClasses = () => {
        const defaultClasses = {
            xs: 12,
            sm: 8,
        };
        if (drawerState.opened && matchesSmBreakpoint) {
            return {
                xs: 12,
                md: 8,
            };
        }
        return defaultClasses;
    };

    const getSmallColClasses = () => {
        const defaultClasses = {
            xs: 12,
            sm: 4,
        };
        if (drawerState.opened && matchesSmBreakpoint) {
            return {
                xs: 12,
                md: 4,
            };
        }
        return defaultClasses;
    };

    const buttonEvent = (buttonData) => {
        trackEvent({
            category: 'news-detail',
            action: 'button-click',
            name: buttonData.label,
            value: buttonData.href,
            customDimensions: [
                {
                    id: 'url',
                    value: data.simpleId,
                },
                {
                    id: 'title',
                    value: data.title,
                },
            ],
        });
    };
    const handleClickButton1 = () => buttonEvent(data.link1);
    const handleClickButton2 = () => buttonEvent(data.link2);

    if (data) {
        return (
            <LayoutDefault>
                <Container>
                    <Grid container className={classes.root} spacing={2}>
                        <Grid item xs={12} className={classes.titleWrapper}>
                            <Title title={data.title} size="h2" />
                        </Grid>

                        <Grid container item xs={12}>
                            <Grid
                                container
                                item
                                direction="row"
                                spacing={
                                    (matchesLgBreakpoint && 4) ||
                                    (matchesSmBreakpoint && 3) ||
                                    (!matchesSmBreakpoint && 2)
                                }
                                alignItems="flex-start"
                            >
                                <Grid container item {...getColClasses()}>
                                    <Grid container item className={classes.wrapper}>
                                        <div className={classes.info}>
                                            <IconWithTag icon="schedule" tag={data.date} variant="light" />
                                            <IconWithTag icon="folder" tags={data.tags} variant="light" />
                                        </div>

                                        <TextBlock text={data.text} className={classes.text} />
                                        {(hasLink1 || hasLink2) && (
                                            <div className={classes.buttons}>
                                                {hasLink1 && (
                                                    <Button
                                                        endIcon={<Icon>east</Icon>}
                                                        className={classes.button}
                                                        onClick={handleClickButton1}
                                                        {...convertLinkDataToProps(data.link1)}
                                                    />
                                                )}
                                                {hasLink2 && (
                                                    <Button
                                                        endIcon={<Icon>east</Icon>}
                                                        className={classes.button}
                                                        variant="outlined"
                                                        onClick={handleClickButton2}
                                                        {...convertLinkDataToProps(data.link2)}
                                                    />
                                                )}
                                            </div>
                                        )}
                                    </Grid>
                                </Grid>

                                <Grid
                                    container
                                    item
                                    {...getSmallColClasses()}
                                    direction="column"
                                    className={classes.sidebar}
                                >
                                    {data.sidebar.map((item, i) => (
                                        <div className={classes.sidebarItem} key={`sidebar-item-${i}`}>
                                            {item.type === 'editorial' && <ActionBlock variant="primary" {...item} />}
                                            {item.type === 'image' && (
                                                <div className={classes.box}>
                                                    <Img {...item.img} />
                                                </div>
                                            )}
                                            {item.type === 'video' && (
                                                <Fragment>
                                                    <div
                                                        className={`${classes.box} ${classes.videobox}`}
                                                        onClick={handleClickOpen}
                                                    >
                                                        <Img {...item.img} />
                                                        <div className={classes.play}>
                                                            <Icon>play_arrow</Icon>
                                                        </div>
                                                    </div>
                                                    <CustomDialog
                                                        open={open}
                                                        handleClose={handleClose}
                                                        amountOfColumns={10}
                                                        closeButton={true}
                                                    >
                                                        <VideoPlayer
                                                            videoId={item.src}
                                                            videoPlatform={item.videoPlatform}
                                                        />
                                                    </CustomDialog>
                                                </Fragment>
                                            )}
                                        </div>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </LayoutDefault>
        );
    }
    return <Redirect to="/404/" />;
};

export default NewsDetail;
