import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => {
    return {
        CartSummary: {
            width: '100%',
            border: '1px solid',
            borderColor: theme.palette.gray.light,
            padding: theme.spacing(4),
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(3),
            borderRadius: '12px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',

            '& h6': {
                fontWeight: 'bold',
                marginBottom: '0',
            },
        },
    };
});

export default useStyles;
