import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => {
    return {
        card: {
            display: 'flex',
            flexDirection: 'column',
            padding: theme.spacing(4),
            right: '0',
            background: theme.palette.common.white,
            width: '100%',
            maxWidth: '286px',
            borderRadius: '12px',
            boxShadow: theme.shadows[4],
        },
        imgWrapper: {
            position: 'relative',
            marginRight: '16px',
            minWidth: '88px',
            maxWidth: '88px',
            minHeight: '88px',
            maxHeight: '88px',
            overflow: 'hidden',
            borderRadius: theme.shape.borderRadius * 2,
        },
        title: {
            fontSize: '1.25rem !important',
            fontWeight: '500',
            marginBottom: '42px',
        },
        content: {
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
        },
        wrapper: {
            display: 'flex',
            marginBottom: '30px',
            '& p': {
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                marginTop: 'auto',
            },
        },
    };
});

export default useStyles;
