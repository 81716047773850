import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => {
    return {
        IconWithTagWrapper: {
            display: 'flex',
            alignItems: 'flex-start',
            marginBottom: theme.spacing(1),

            '&:last-child': {
                marginBottom: '0',
            },
        },
        IconWithTagIcon: {
            display: 'flex',
            marginRight: theme.spacing(1),
            color: theme.palette.primary.main,
        },
        icon: {
            fontSize: '21px !important',
        },
        IconWithTagTag: {
            fontWeight: 'bold',
            color: theme.palette.primary.main,

            '& a': {
                textDecoration: 'none',
                color: theme.palette.link,
                transition: 'color 0.2s ease-out',

                '&:hover': {
                    color: theme.palette.primary.main,
                },
            },

            '&--light': {
                fontWeight: 'normal',
            },
        },
    };
});

export default useStyles;
