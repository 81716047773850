export const IconCheckBox = () => (
    <svg viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.637,15.333L17.629,7.836C18.236,7.077 19.346,6.953 20.105,7.56C20.865,8.167 20.988,9.277 20.381,10.036L11.93,20.609L5.681,14.36C4.994,13.673 4.994,12.556 5.681,11.869C6.369,11.181 7.485,11.181 8.172,11.869L11.637,15.333Z" />
    </svg>
);

export const IconButtonArrow = () => (
    <svg viewBox="0 0 26 26" width="19px" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.686,12.934L15.975,8.223C15.837,8.085 15.649,8.007 15.454,8.007C15.05,8.007 14.718,8.34 14.718,8.744C14.718,8.939 14.795,9.126 14.933,9.264L18.388,12.719L5.807,12.719C5.404,12.719 5.072,13.05 5.072,13.454C5.072,13.857 5.404,14.189 5.807,14.189L18.388,14.189L14.933,17.643C14.795,17.781 14.718,17.968 14.718,18.164C14.718,18.568 15.05,18.9 15.454,18.9C15.649,18.9 15.837,18.822 15.975,18.684L20.685,13.974C20.823,13.836 20.901,13.649 20.901,13.454C20.901,13.259 20.824,13.072 20.686,12.934Z" />
    </svg>
);

export const IconCircleButtonCross = () => (
    <svg viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.094,11.593L17.426,7.262C17.939,6.748 18.773,6.748 19.286,7.262C19.799,7.775 19.799,8.609 19.286,9.122L14.955,13.454L19.286,17.785C19.799,18.298 19.799,19.132 19.286,19.645C18.773,20.159 17.939,20.159 17.426,19.645L13.094,15.314L8.763,19.645C8.249,20.159 7.416,20.159 6.903,19.645C6.389,19.132 6.389,18.298 6.903,17.785L11.234,13.454L6.903,9.122C6.389,8.609 6.389,7.775 6.903,7.262C7.416,6.748 8.249,6.748 8.763,7.262L13.094,11.593Z" />
    </svg>
);
