import React, { useState, useCallback, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Icon } from '@mui/material';
import { useDrawerContext } from '@prowise/react-mui';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Alert from '@mui/material/Alert';
import Link from '@mui/material/Link';
import { useMatomo } from '@datapunt/matomo-tracker-react';

import LayoutDefault from '../../components/Layouts/Default/Default';
import Title from '../../components/Atoms/Title/Title';
import Button from '../../components/Atoms/Button/Button';
import FiltersDownloads from '../../components/Molecules/FiltersDownloads/FiltersDownloads';
import SearchBar from '../../components/Molecules/SearchBar/SearchBar';
import GridListView from '../../components/Organisms/GridListView/GridListView';
import GridListViewToggler from '../../components/Organisms/GridListView/GridListViewToggler';
import FilterButton from '../../components/Molecules/FilterButton/FilterButton';
import DownloadCard from '../../components/Molecules/DownloadCard/DownloadCard';
import DownloadListCard from '../../components/Molecules/DownloadListCard/DownloadListCard';
import ActionButton from '../../components/Molecules/ActionButton/ActionButton';
import CustomDrawer from '../../components/Organisms/CustomDrawer/CustomDrawer';
import DownloadsModal from '../../components/Organisms/DownloadsModal/DownloadsModal';

import useStyles from './DownloadsOverviewStyle';

import { selectDownloads } from '../../redux/dataSlice';
import {
    selectAll,
    selectItems,
    selectFilters,
    selectFormattedFilters,
    selectActiveFilters,
    selectPagination,
    selectViewtype,
    setViewType,
    setSearch,
    selectSearch,
    getInitData,
    getDownloadItemsAsync,
    setCurrentPage,
    updateFilter,
    updateExpanded,
    toggleLimited,
    setModalData,
} from '../../redux/downloadsSlice';

const NoResultsComponent = ({ applySuggestion, noResults, noResultsSuggestions, suggestions }) => (
    <Alert severity="info">
        {suggestions.length > 0 ? `${noResultsSuggestions} ` : noResults}
        {suggestions.length > 0 && (
            <Fragment>
                {suggestions.map((suggestion, index) => (
                    <Fragment key={`suggestion-${suggestion.value}`}>
                        <Link
                            component="button"
                            onClick={() => {
                                applySuggestion(suggestion.value || '');
                            }}
                        >
                            '{suggestion.label || 'Unknown'}'
                        </Link>
                        <Fragment>{index < suggestions.length - 1 && `, `}</Fragment>
                    </Fragment>
                ))}
            </Fragment>
        )}
    </Alert>
);
NoResultsComponent.propTypes = {
    applySuggestion: PropTypes.func,
    noResults: PropTypes.string,
    noResultsSuggestions: PropTypes.string,
    suggestions: PropTypes.array,
};
NoResultsComponent.defaultProps = {
    applySuggestion: () => {},
    noResults: 'No results',
    noResultsSuggestions: 'No results. Did you mean',
    suggestions: [],
};

const DownloadsOverview = () => {
    const dispatch = useDispatch();
    const drawerState = useDrawerContext();
    const data = useSelector(selectDownloads);
    const all = useSelector(selectAll);
    const items = useSelector(selectItems);
    const filters = useSelector(selectFilters);
    const activeFilters = useSelector(selectActiveFilters);
    const formattedFilters = useSelector(selectFormattedFilters);
    const search = useSelector(selectSearch);
    const pagination = useSelector(selectPagination);
    const viewType = useSelector(selectViewtype);
    const drawerIsOpen = drawerState.opened;
    const theme = useTheme();
    const classes = useStyles();
    const [filtersIsOpen, setIsFiltersIsOpen] = useState(false);
    const [searchIsOpen, setSearchIsOpen] = useState(false);
    const [filterPromise, setFilterPromise] = useState(null);

    document.title = data.title;
    const { trackPageView } = useMatomo();

    // load data
    const getData = useCallback(() => {
        if (!all.isInited) dispatch(getInitData());
        // eslint-disable-next-line
    }, [filters]);
    useEffect(() => {
        getData();
        trackPageView();
        return () => {};
        // eslint-disable-next-line
    }, []);

    const onViewTypeChange = (newType) => dispatch(setViewType(newType));
    const applySearchQuery = async (searchValue) => {
        await dispatch(setSearch(searchValue));
        await dispatch(getDownloadItemsAsync());
    };
    const fetchData = async () => {
        await dispatch(setCurrentPage(pagination.currentPage + 1));
        await dispatch(getDownloadItemsAsync());
    };
    const handleUpdateFilter = async (data) => {
        await dispatch(updateFilter(data));
        // reset the previous filter-dispatch call if there is any
        if (filterPromise) filterPromise.abort();
        const promise = dispatch(getDownloadItemsAsync());
        setFilterPromise(promise);
    };
    const handleUpdateExpanded = (data) => dispatch(updateExpanded(data));
    const handleToggleLimited = (data) => dispatch(toggleLimited(data));
    const toggleSearchDrawer = () => setSearchIsOpen(!!!searchIsOpen);
    const toggleFilterDrawer = () => {
        drawerState.closeDrawer();
        setIsFiltersIsOpen(!!!filtersIsOpen);
    };

    const isBetweenSmMd = useMediaQuery(theme.breakpoints.between('sm', 'lg'));
    const searchBarColumns = () => {
        if (isBetweenSmMd && !drawerIsOpen) {
            return {
                xs: 6,
                sm: 4,
                md: 8,
                lg: 9,
            };
        }
        return {
            xs: 6,
            sm: 6,
            md: 4,
            lg: 9,
        };
    };

    const buttonColumns = () => {
        if (isBetweenSmMd && !drawerIsOpen) {
            return {
                xs: 12,
                sm: 8,
                md: 4,
                lg: 3,
            };
        }
        return {
            xs: 12,
            sm: 6,
            md: 8,
            lg: 3,
        };
    };

    const onTileClick = (modalData) => {
        dispatch(setModalData(modalData));
    };

    return (
        <LayoutDefault rightDrawerIsOpen={filtersIsOpen}>
            <Container>
                <Grid container className={classes.downloads} spacing={2}>
                    <Grid item xs={12} className={classes.title}>
                        <Title title={data.title} size="h2" />
                    </Grid>
                    <Grid container item xs={12} className={classes.container}>
                        <Grid container item direction="row" spacing={4} alignItems="flex-start">
                            <Grid container item xs={12} lg={3} direction="column">
                                <Box sx={{ display: { xs: 'none', lg: 'flex' }, flexDirection: 'column' }}>
                                    <div className={classes.filterstitle}>
                                        <Title title={data.filterLabel} size="h4" />
                                    </div>
                                    <div className={classes.filters}>
                                        <FiltersDownloads
                                            filterData={formattedFilters}
                                            isLoading={all.isIniting}
                                            updateFilter={handleUpdateFilter}
                                            updateExpanded={handleUpdateExpanded}
                                            toggleLimited={handleToggleLimited}
                                            showMoreLabel={data.showMoreLabel}
                                            showLessLabel={data.showLessLabel}
                                        />
                                    </div>
                                </Box>
                            </Grid>
                            <Grid container item xs={12} lg={9} direction="column">
                                <Grid container item direction="row" spacing={3} className={classes.options}>
                                    <Grid item className={classes.optionsleft} {...searchBarColumns()}>
                                        <SearchBar
                                            value={search}
                                            placeholder={data.searchLabel}
                                            applySearchQuery={applySearchQuery}
                                        />
                                    </Grid>
                                    <Grid item className={classes.optionsright} {...buttonColumns()}>
                                        <GridListViewToggler viewType={viewType} onChange={onViewTypeChange} />
                                        <FilterButton
                                            onClick={toggleFilterDrawer}
                                            label={data.filterLabel}
                                            count={activeFilters.length}
                                            className={filtersIsOpen ? classes.filterButton : ''}
                                        />
                                    </Grid>
                                </Grid>
                                <GridListView
                                    viewType={viewType}
                                    data={items}
                                    Component={viewType === 'grid' ? DownloadCard : DownloadListCard}
                                    noResultsText={data.noFilterResults}
                                    isIniting={all.isIniting}
                                    isFetching={all.isFetching}
                                    fetchData={fetchData}
                                    totalItems={pagination.totalItems}
                                    extraComponentData={{
                                        onClick: onTileClick,
                                    }}
                                    leftDrawerIsOpen={drawerIsOpen}
                                    rightDrawerIsOpen={filtersIsOpen}
                                    NoResultsComponent={
                                        <NoResultsComponent
                                            applySuggestion={applySearchQuery}
                                            noResults={data.noResults}
                                            noResultsSuggestions={data.noResultsSuggestions}
                                            suggestions={all.suggestions}
                                        />
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <ActionButton
                    icon={<Icon>search</Icon>}
                    onClick={toggleSearchDrawer}
                    classList={classes.searchbutton}
                />
                <CustomDrawer
                    open={filtersIsOpen}
                    toggleDrawer={toggleFilterDrawer}
                    title={data.filterLabel}
                    mobileFooter={
                        <Button
                            label={(data.showResultsLabel || 'Show {{1}} results').replace(
                                '{{1}}',
                                pagination.totalItems,
                            )}
                            color="secondary"
                            onClick={toggleFilterDrawer}
                        />
                    }
                >
                    <FiltersDownloads
                        filterData={formattedFilters}
                        isLoading={all.isIniting}
                        updateFilter={handleUpdateFilter}
                        updateExpanded={handleUpdateExpanded}
                        toggleLimited={handleToggleLimited}
                        showMoreLabel={data.showMoreLabel}
                        showLessLabel={data.showLessLabel}
                    />
                </CustomDrawer>
                <CustomDrawer
                    open={searchIsOpen}
                    toggleDrawer={toggleSearchDrawer}
                    title={data.searchLabel}
                    mobileFooter={
                        <Button
                            label={(data.showResultsLabel || 'Show {{1}} results').replace(
                                '{{1}}',
                                pagination.totalItems,
                            )}
                            color="secondary"
                            onClick={toggleSearchDrawer}
                        />
                    }
                >
                    <SearchBar value={search} placeholder={data.searchLabel} setSearch={applySearchQuery} />
                </CustomDrawer>
                <DownloadsModal />
            </Container>
        </LayoutDefault>
    );
};
export default DownloadsOverview;
