import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

import LayoutDefault from '../../components/Layouts/Default/Default';
import Title from '../../components/Atoms/Title/Title';
// import Button from '../../components/Atoms/Button/Button';

import Text from '../../components/Atoms/Text/Text';
import { useSelector } from 'react-redux';
import { selectGeneral } from '../../redux/dataSlice';

const NotFound = () => {
    const data = useSelector(selectGeneral)['404'];
    document.title = data.title;

    return (
        <LayoutDefault>
            <Container>
                <Grid container direction="column">
                    <Grid item xs>
                        <div>
                            <Title title={data.title} size="h2" />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Text text={data.text} />
                    </Grid>
                </Grid>
            </Container>
        </LayoutDefault>
    );
};

export default NotFound;
