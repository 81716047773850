import { configureStore } from '@reduxjs/toolkit';
import dataReducer, { initialDataState } from './dataSlice';
import downloadsReducer, { initalDownloadsState } from './downloadsSlice';

const localstorageKey = 'pw_partnerportal_data';

const getDataFromLocalStorage = () => {
    try {
        const persistedState = localStorage.getItem(localstorageKey);
        if (persistedState) return JSON.parse(persistedState);
    } catch (e) {
        return false;
    }
};

let preloadedState = {
    data: { ...initialDataState },
    downloads: { ...initalDownloadsState },
};
const initData = getDataFromLocalStorage();
if (initData && initData.cart) {
    preloadedState.data.cart = initData.cart;
}

const store = configureStore({
    reducer: {
        data: dataReducer,
        downloads: downloadsReducer,
    },
    preloadedState,
});

store.subscribe(() => {
    const currentState = store.getState();
    const dataToSave = {
        cart: [...currentState.data.cart],
    };
    localStorage.setItem(localstorageKey, JSON.stringify(dataToSave));
});

export default store;
export { getDataFromLocalStorage };
