import PropTypes from 'prop-types';
import { Icon } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { useMatomo } from '@datapunt/matomo-tracker-react';

import Title from '../../Atoms/Title/Title';
import Button from '../../Atoms/Button/Button';
import Img from '../../Atoms/Img/Img';
import Text from '../../Atoms/Text/Text';
import { openURLInNewTab, humanFileSize } from '../../../utilities/utilities';

import useStyles from './DownloadPopUpStyle';

const Variants = ({ id = '', variants = [], title = '' }) => {
    const classes = useStyles();
    const { trackEvent } = useMatomo();

    const handleClick = (variant, title, id) => {
        trackEvent({
            category: 'downloads',
            action: 'download',
            name: title,
            value: variant.title,
            customDimensions: [
                {
                    id: 'id',
                    value: id,
                },
                {
                    id: 'title',
                    value: title,
                },
                {
                    id: 'variant-id',
                    value: variant.id,
                },
                {
                    id: 'variant-title',
                    value: variant.title,
                },
                {
                    id: 'variant-href',
                    value: variant.href,
                },
                {
                    id: 'variant-label',
                    value: variant.label,
                },
            ],
        });
        openURLInNewTab(variant.href);
    };

    if (variants.length === 1) {
        return (
            <Box className={classes.variantsAsButtons}>
                {variants.map((variant, i) => (
                    <Box className={classes.variantItem} key={`download-item-${i}-${variant.id}`}>
                        <Button
                            label={variant.label}
                            className={classes.buttonVariantButton}
                            startIcon={<Icon>download</Icon>}
                            onClick={(e) => {
                                e.preventDefault();
                                handleClick(variant, title, id);
                            }}
                        />
                    </Box>
                ))}
            </Box>
        );
    }
    return (
        <Box>
            {variants.map((variant, i) => (
                <Box key={`download-item-${i}-${variant.id}`} className={classes.variantRow}>
                    <Box>{variant.title}</Box>
                    <Box className={classes.fileSize} sx={{ display: { xs: 'none', lg: 'flex' } }}>
                        <small>
                            ({variant.label}, {humanFileSize(variant.file.fileSize)})
                        </small>
                    </Box>
                    <Box className={classes.buttonVariantList}>
                        {variants.length === 1 ? (
                            <Button
                                label={variant.label}
                                className={classes.buttonVariantButton}
                                startIcon={<Icon>download</Icon>}
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleClick(variant, title, id);
                                }}
                            />
                        ) : (
                            <IconButton
                                className={classes.button}
                                size="small"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleClick(variant, title, id);
                                }}
                            >
                                <SaveAltIcon />
                            </IconButton>
                        )}
                    </Box>
                </Box>
            ))}
        </Box>
    );
};

const Card = ({ id, img, title, text, date, variants, className }) => {
    const classes = useStyles();
    return (
        <div className={`${classes.card} ${className}`}>
            <div className={classes.imgWrapper}>
                <Img {...img} objectFit={true} className={classes.img} />
            </div>
            <div className={classes.content}>
                <Title title={title} size="h4" />
                <Text text={text} className={classes.text} />
                <Text text={date} className={classes.text} />
                <Variants id={id} variants={variants} title={title} />
            </div>
        </div>
    );
};
Card.propTypes = {
    id: PropTypes.string,
    img: PropTypes.object,
    title: PropTypes.string,
    text: PropTypes.string,
    date: PropTypes.string,
    variants: PropTypes.array,
    className: PropTypes.string,
};

Card.defaultProps = {
    id: '',
    img: {
        src: '',
        alt: '',
    },
    title: '',
    text: '',
    date: '',
    variants: [],
    className: '',
};

const DownloadPopUp = (data) => {
    const classes = useStyles();
    if (data.type === 'collection') {
        return (
            <div className={classes.mainItem}>
                <Card
                    id={data.id}
                    img={data.img}
                    title={data.title}
                    text={data.text}
                    date={data.dateFormatted}
                    variants={data.variants}
                />
                <div className={classes.subitems}>
                    {data.downloads.map((subitem) => (
                        <Card
                            id={data.id}
                            key={`subItem-${data.id}-${subitem.id}`}
                            img={subitem.img}
                            title={subitem.title}
                            text={subitem.text}
                            date={subitem.dateFormatted}
                            variants={subitem.variants}
                            className={classes.subitem}
                        />
                    ))}
                </div>
            </div>
        );
    }
    return (
        <div className={classes.mainItem}>
            <Card
                img={data.img}
                title={data.title}
                text={data.text}
                date={data.dateFormatted}
                variants={data.variants}
            />
        </div>
    );
};

export default DownloadPopUp;
