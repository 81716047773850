import PropTypes from 'prop-types';
import { Button } from '@prowise/react-mui';
import { NavLink } from 'react-router-dom';
import useStyles from './ButtonStyle';

const componentClass = 'Button';

const CustomButton = ({
    linkType,
    to,
    target,
    rel,
    variant,
    label,
    color,
    size,
    className,
    endIcon,
    startIcon,
    disabled,
    component,
    onClick,
}) => {
    const classes = useStyles();
    const classList = classes[componentClass];

    // button with no link
    if (!to) {
        return (
            <Button
                variant={variant}
                color={color}
                endIcon={endIcon}
                startIcon={startIcon}
                component={'button'}
                onClick={onClick}
                className={`${className} ${classList}`}
                disabled={disabled}
                size={size}
            >
                {label}
            </Button>
        );
    }

    // internal link
    if (linkType === 'internal') {
        return (
            <Button
                to={to}
                variant={variant}
                rel={rel !== '' ? rel : undefined}
                color={color}
                endIcon={endIcon}
                startIcon={startIcon}
                disabled={disabled}
                component={component}
                onClick={onClick}
                className={`${className} ${classList}`}
                size={size}
            >
                {label}
            </Button>
        );
    }

    // other external link
    return (
        <Button
            href={to}
            target={target}
            variant={variant}
            rel={rel !== '' ? rel : undefined}
            color={color}
            endIcon={endIcon}
            startIcon={startIcon}
            component={'a'}
            onClick={onClick}
            className={`${className} ${classList}`}
            disabled={disabled}
            size={size}
        >
            {label}
        </Button>
    );
};

CustomButton.propTypes = {
    linkType: PropTypes.oneOf(['internal', 'external', 'email', 'tel', 'file']),
    to: PropTypes.string,
    target: PropTypes.oneOf(['_self', '_blank']),
    label: PropTypes.string,
    disabled: PropTypes.bool,
    startIcon: PropTypes.element,
    endIcon: PropTypes.element,
    variant: PropTypes.oneOf(['contained', 'outlined', 'text']),
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    color: PropTypes.oneOf(['default', 'primary', 'secondary', 'default']),
    className: PropTypes.string,
    rel: PropTypes.string,
};

CustomButton.defaultProps = {
    linkType: 'internal',
    target: '_self',
    label: '',
    variant: 'contained',
    color: 'primary',
    disabled: false,
    component: NavLink,
    className: '',
    rel: '',
    size: 'medium',
};

export default CustomButton;
