import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => {
    return {
        Dashboard: {
            flexGrow: 1,
            alignItems: 'flex-start',

            '&__test': {
                color: 'red',
            },
            '&__news': {
                width: '100%',
                marginBottom: theme.spacing(2),
                marginTop: theme.spacing(2),

                [theme.breakpoints.up('lg')]: {
                    marginBottom: theme.spacing(4),
                    marginTop: theme.spacing(3),
                },

                '&__button': {
                    marginBottom: theme.spacing(5),
                },

                '&__title': {
                    marginBottom: '0',
                },
            },

            '&__editorials': {
                marginTop: theme.spacing(2),
                width: '100%',

                [theme.breakpoints.up('lg')]: {
                    marginTop: theme.spacing(3),
                },

                '&__title': {
                    '& h2': {
                        [theme.breakpoints.down('xl')]: {
                            marginBottom: '0',
                        },
                    },
                },
            },

            '&__editorial': {
                marginBottom: '24px',

                [theme.breakpoints.up('lg')]: {
                    marginBottom: '20px',
                },

                '&:last-child': {
                    marginBottom: '0',
                },
            },

            '&__events': {
                width: '100%',
                padding: theme.spacing(4),
                paddingTop: '0',
                paddingBottom: '0',

                [theme.breakpoints.up('sm')]: {
                    padding: '0',
                },

                '&__padding': {
                    padding: theme.spacing(4),
                    paddingTop: '0',
                    paddingBottom: '0',

                    [theme.breakpoints.up('sm')]: {
                        padding: '0',
                    },
                },

                [theme.breakpoints.up('md')]: {
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: theme.spacing(4),
                    paddingTop: '0',
                    paddingBottom: '0',
                },

                '&__title': {
                    display: 'flex',
                    justifyContent: 'flex-start',
                    width: '100%',

                    [theme.breakpoints.up('md')]: {
                        justifyContent: 'center',
                    },

                    '& h2': {
                        marginBottom: '0',

                        [theme.breakpoints.between('sm', 'xl')]: {
                            marginTop: theme.spacing(1),
                        },
                    },
                },

                '&__button': {
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                    marginTop: theme.spacing(-1),

                    [theme.breakpoints.up('lg')]: {
                        marginTop: theme.spacing(2),
                    },
                },
            },

            '&__event': {
                padding: '0',
                borderBottom: `1px solid rgba(0,0,0,0.12)`,
                '&:last-child': {
                    borderColor: 'transparent',
                },
                '&__border': {
                    borderBottom: 'unset',
                },
                '&__showborder': {
                    borderBottom: `1px solid rgba(0,0,0,0.12)`,
                    '&:last-child': {
                        borderBottom: 'unset',
                    },
                },
                '&__hideborder': {
                    borderBottom: `none`,
                },
                [theme.breakpoints.up('lg')]: {
                    borderBottom: `1px solid rgba(0,0,0,0.12)`,
                },
            },
        },
    };
});

export default useStyles;
