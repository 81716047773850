import PropTypes from 'prop-types';
import { Icon } from '@mui/material';
import { useDrawerContext } from '@prowise/react-mui';
import useMediaQuery from '@mui/material/useMediaQuery';

import useStyles from './EditorialBlockStyle';
import Title from '../../Atoms/Title/Title';
import Button from '../../Atoms/Button/Button';
import Text from '../../Atoms/Text/Text';
import { convertLinkDataToProps } from '../../../utilities/utilities';
import Img from '../../Atoms/Img/Img';

const EditorialBlock = ({ title, text, img, link1, link2 }) => {
    const classes = useStyles();
    const drawerState = useDrawerContext();
    const matchesSmBreakpoint = useMediaQuery((theme) => theme.breakpoints.up('xs'));

    const hasLink1 = Object.keys(link1).length > 0 && link1.href !== '' && link1.label !== '';
    const hasLink2 = Object.keys(link2).length > 0 && link2.href !== '' && link2.label !== '';

    var imgWrapperClassName = '';

    if (drawerState.opened && matchesSmBreakpoint) {
        imgWrapperClassName = classes.imgWrappDrawer;
    }

    return (
        <div className={`${classes.card} ${drawerState.opened && matchesSmBreakpoint ? classes.cardColumn : ''}`}>
            <div className={classes.content}>
                <div className={classes.title}>
                    <Title title={title} size="h4" />
                </div>
                <Text text={text} className={classes.text} />
                {(hasLink1 || hasLink2) && (
                    <div className={classes.buttons}>
                        {hasLink1 && (
                            <div className={classes.button}>
                                <Button startIcon={<Icon>{link1.icon}</Icon>} {...convertLinkDataToProps(link1)} />
                            </div>
                        )}
                        {hasLink2 && (
                            <div className={classes.button}>
                                <Button
                                    variant="outlined"
                                    startIcon={<Icon>{link2.icon}</Icon>}
                                    {...convertLinkDataToProps(link2)}
                                />
                            </div>
                        )}
                    </div>
                )}
            </div>
            <div className={`${classes.imgWrapper} ${imgWrapperClassName}`}>
                <div className={classes.imgWrapp}>
                    <Img {...img} className={classes.img} />
                </div>
            </div>
        </div>
    );
};

EditorialBlock.propTypes = {
    link1: PropTypes.object,
    link2: PropTypes.object,
    text: PropTypes.string,
    title: PropTypes.string,
    img: PropTypes.object,
};

EditorialBlock.defaultProps = {
    link1: '',
    link2: '',
    text: '',
    title: '',
    img: {},
};

export default EditorialBlock;
