import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => {
    return {
        button: {
            flexShrink: 0,
            '&:hover': {
                background: theme.palette.primary.colors[400],
                color: theme.palette.common.white,
            },
        },
    };
});

export default useStyles;
