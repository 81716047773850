import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => {
    return {
        wrapper: {
            position: 'relative',
            width: '100%',
            padding: theme.spacing(4),
            marginBottom: theme.spacing(8),
            '&:before': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                border: `2px solid ${theme.palette.secondary.main}`,
                borderRadius: theme.spacing(2),
                opacity: 0.5,
            },
        },
        title: {
            marginBottom: theme.spacing(2),
        },
        card: {
            paddingRight: theme.spacing(4),
            'user-drag': 'none',
            'user-select': 'none',
            '-moz-user-select': 'none',
            '-webkit-user-drag': 'none',
            '-webkit-user-select': 'none',
            '-ms-user-select': 'none',
        },
        buttonGroup: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            pointerEvents: 'none',
        },
        button: {
            position: 'absolute',
            top: '50%',
            zIndex: 999,
            pointerEvents: 'auto',
            '&--left': {
                left: 0,
                transform: `translate(-50%, -50%)`,
            },
            '&--right': {
                right: 0,
                transform: `translate(50%, -50%)`,
            },
        },
    };
});

export default useStyles;
