export function arrayContainsOneOrMultipleValues(arr1, arr2) {
    return arr1.some((r) => arr2.indexOf(r) >= 0);
}

export function allEqual(arr) {
    return arr.every((val) => val === arr[0]);
}

export function formatPrice(number) {
    return new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(number);
}

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export function humanFileSize(bytes, si = false, dp = 1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }

    const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10 ** dp;

    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

    return bytes.toFixed(dp) + ' ' + units[u];
}

export function convertLinkDataToProps(data) {
    let d = {
        ...data,
        ...{
            linkType: data.type,
            to: data.href,
        },
    };
    if (data.target === '_blank') {
        d.rel = 'noopener noreferrer';
    }
    if (data.type === 'mail' || data.type === 'tel') {
        d.target = '_self';
    }
    return d;
}

export function openURLInNewTab(url) {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
}

export function truncate(input, maxlength = 100) {
    return input.length > maxlength ? `${input.substring(0, maxlength)}...` : input;
}

export function isTouchDevice() {
    try {
        const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
        const mq = (query) => window.matchMedia(query).matches;
        if (
            'ontouchstart' in window ||
            (typeof window.DocumentTouch !== 'undefined' && document instanceof window.DocumentTouch)
        ) {
            return true;
        }
        return mq(['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join(''));
    } catch (e) {
        return false;
    }
}
