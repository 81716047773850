import React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import { Icon } from '@mui/material';
import { Dialog } from '@mui/material';
import Slide from '@mui/material/Slide';

import useStyles from './CustomDialogStyle';

const CustomDialog = ({ children, handleClose, open, closeButton, amountOfColumns }) => {
    const classes = useStyles();
    const containerRef = React.useRef(null);

    const preventDef = (e) => e.stopPropagation();

    return (
        <Dialog open={open} className={classes.dialog} onClick={handleClose} ref={containerRef}>
            <Container className={classes.wrapper}>
                <Slide direction="up" in={open} mountOnEnter unmountOnExit container={containerRef.current}>
                    <Grid container className={classes.container}>
                        <Grid item xs={12} sm={amountOfColumns} onClick={preventDef} className={classes.column}>
                            {children}
                            {closeButton && (
                                <div className={classes.close} onClick={handleClose}>
                                    <Icon className={classes.closeIcon + ' material-icons-round'}>close</Icon>
                                </div>
                            )}
                        </Grid>
                    </Grid>
                </Slide>
            </Container>
        </Dialog>
    );
};

CustomDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func,
    closeButton: PropTypes.bool,
    amountOfColumns: PropTypes.number,
};

CustomDialog.defaultProps = {
    open: false,
    handleClose: () => {},
    closeButton: true,
    amountOfColumns: 6,
};

export default CustomDialog;
