import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => {
    return {
        imgWrapper: {
            position: 'relative',
            display: 'block',
            width: '100%',

            '& .lazyloadWrapper-wrapper': {
                position: 'absolute',
                display: 'block',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
            },
        },
        img: {
            position: 'absolute',
            display: 'block',
            width: '100%',
            top: 0,
            left: 0,
            'user-drag': 'none',
            'user-select': 'none',
            '-moz-user-select': 'none',
            '-webkit-user-drag': 'none',
            '-webkit-user-select': 'none',
            '-ms-user-select': 'none',

            '&.objectfit': {
                position: 'relative',
                top: 'auto',
                left: 'auto',
                width: '100%',
                height: '100%',
                objectFit: 'contain',
                objectPosition: 'center',
            },
        },
        error: {
            position: 'absolute',
            width: '100%',
            height: '100%',
            background: theme.palette.grey['100'],
            color: theme.palette.grey['500'],
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    };
});

export default useStyles;
