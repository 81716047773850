import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => {
    return {
        filters: {
            display: 'flex',
            flexWrap: 'wrap',
            listStyle: 'none',
            padding: '0',
            width: '100%',
            flexDirection: 'column',
            '& li': {
                padding: '7px 0',
                width: '100%',

                '& ul': {
                    padding: '4px 0',
                },

                '& div': {
                    width: '100%',
                },
            },
            '& ul': {
                listStyle: 'none',
                paddingLeft: theme.spacing(1.5),
                marginLeft: '16px',
            },
        },
        filtersHorizontal: {
            marginBottom: theme.spacing(2),
        },
        itemHorizontal: {
            paddingBottom: '0!important',
        },
    };
});

export default useStyles;
