import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => {
    return {
        chip: {
            display: 'inline-flex',
            paddingLeft: theme.spacing(1.6),
            paddingRight: theme.spacing(1.6),
            lineHeight: '32px',
            borderRadius: '100px',
            background: theme.palette.pink,
            color: theme.palette.common.white,
        },
    };
});

export default useStyles;
